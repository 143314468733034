import { createContext, ReactElement, useContext, useState } from "react";
import { ItnLanguage } from "../utils/itn";

type Props = {
    children: ReactElement;
};

function StoreProvider({ children }: Props) {
    const [theme, setTheme] = useState<"dark" | "light">("light");
    const [language, setLanguage] = useState<ItnLanguage | undefined>();

    const toggleTheme = () => {
        setTheme((currentTheme) =>
            currentTheme === "light" ? "dark" : "light",
        );
    };

    const changeTheme = (newTheme: "dark" | "light") => {
        setTheme(newTheme);
    };

    const changeLanguage = (newLanguage: ItnLanguage | undefined) => {
        setLanguage(newLanguage);
    };

    const contextValue = {
        theme,
        language,
        toggleTheme,
        changeLanguage,
        changeTheme,
    };

    return (
        <StoreContext.Provider value={contextValue}>
            {children}
        </StoreContext.Provider>
    );
}

const StoreContext = createContext<{
    theme: "light" | "dark";
    language?: ItnLanguage;
    toggleTheme: () => void;
    changeLanguage: (newLanguage?: ItnLanguage) => void;
    changeTheme: (newTheme: "dark" | "light") => void;
}>({
    theme: "light",
    language: undefined,
    toggleTheme: () => {},
    changeLanguage: () => {},
    changeTheme: () => {},
});

const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error("useStore must be used within a StoreProvider");
    }
    return context;
};

export { StoreProvider, useStore };
