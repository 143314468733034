import { ItnLanguage, ItnMessagesType, ItnType } from "../utils/itn";

type Type = {
    support1: string;
    support2: string;
    support3: string;
    privacyPolicy: string;
    legal: string;
    termsOfService: string;
    communityParticipationGuidelines?: string;
    allRightsReserved: string;
};

export const footerItns: ItnType<Type> = {
    ptBr: {
        support1: "Dúvidas? O ",
        support2: "suporte da Studium",
        support3: " pode ajudar.",
        privacyPolicy: "Política de Privacidade",
        termsOfService: "Termos de Serviço",
        legal: "Jurídico",
        communityParticipationGuidelines:
            "Diretrizes de participação na comunidade",
        allRightsReserved: "Todos os direitos reservados",
    },
    ptEu: {
        support1: "Dúvidas? O ",
        support2: "suporte da Studium",
        support3: " pode ajudar.",
        privacyPolicy: "Política de Privacidade",
        termsOfService: "Termos de Serviço",
        legal: "Jurídico",
        communityParticipationGuidelines:
            "Diretrizes de participação na comunidade",
        allRightsReserved: "Todos os direitos reservados",
    },
    en: {
        support1: "Questions? Studium's ",
        support2: "support",
        support3: " can help.",
        privacyPolicy: "Privacy Policy",
        termsOfService: "Terms of Service",
        legal: "Legal",
        communityParticipationGuidelines: "Community Participation Guidelines",
        allRightsReserved: "All rights reserved",
    },
    es: {
        support1: "¿Preguntas? El ",
        support2: "soporte de Studium",
        support3: " puede ayudar.",
        privacyPolicy: "Política de Privacidad",
        termsOfService: "Términos de Servicio",
        legal: "Legal",
        communityParticipationGuidelines:
            "Directrices de participación en la comunidad",
        allRightsReserved: "Todos los derechos reservados",
    },
    fr: {
        support1: "Des questions? Le ",
        support2: "support de Studium",
        support3: " peut aider",
        privacyPolicy: "Politique de confidentialité",
        termsOfService: "Conditions d'utilisation",
        legal: "Mentions légales",
        communityParticipationGuidelines:
            "Directives de participation communautaire",
        allRightsReserved: "Tous droits réservés",
    },
    it: {
        support1: "Domande? Il ",
        support2: "supporto di Studium",
        support3: " può ai",
        privacyPolicy: "Informativa sulla privacy",
        termsOfService: "Termini di servizio",
        legal: "Legale",
        communityParticipationGuidelines:
            "Linee guida per la partecipazione alla comunità",
        allRightsReserved: "Tutti i diritti riservati",
    },
    de: {
        support1: "Fragen? Der ",
        support2: "Studium-Support",
        support3: " kann helfen.",
        privacyPolicy: "Datenschutzbestimmungen",
        termsOfService: "Nutzungsbedingungen",
        legal: "Rechtliches",
        communityParticipationGuidelines:
            "Richtlinien für die Teilnahme an der Community",
        allRightsReserved: "Alle Rechte vorbehalten",
    },
};
