import { createUseStyles, useTheme } from "react-jss";
import { Container, FlexBox } from "./studium_ui";
import * as styles from "../utils/styles";
import { useItn } from "../utils/itn";
import { itns } from "./platform_features.itn";
import Button from "./button/button";
import { ST, StudiumTheme } from "../utils/themes";
import TechIcon from "../assets/images/tech_icon";
import LinkIcon from "../assets/images/link_icon";
import SlidersIcon from "../assets/images/sliders_icon";
import ChartIcon from "../assets/images/chart_icon";
import useWindowSize from "../utils/window_size";

function PlatformFeaturesCard({
    title,
    content,
    icon,
}: {
    title: string;
    content: string;
    icon?: JSX.Element;
}) {
    const theme: StudiumTheme = useTheme();
    const classes = useStyles({ theme });
    return (
        <FlexBox column className={classes.card}>
            <FlexBox className={classes.cardIconBox}>{icon}</FlexBox>
            <h5 className={classes.cardTitle}>{title}</h5>
            <p className={classes.cardContent}>{content}</p>
        </FlexBox>
    );
}

export default function PlatformFeatures() {
    const { itn } = useItn(itns);
    const theme: StudiumTheme = useTheme();
    const classes = useStyles({ theme });
    const [width, height] = useWindowSize();
    return (
        <section className={classes.container}>
            <Container
                style={{
                    display: "flex",
                    // alignItems: "stretch",
                    maxWidth: "1000px",
                }}
            >
                <FlexBox
                    style={{ flex: 1 }}
                    // mainAxisAlignment="stretch"
                    column
                    gap={styles.size(1.5)}
                >
                    <FlexBox className={classes.content}>
                        <FlexBox column className={classes.leftContent}>
                            <p className={classes.supTitle}>
                                {itn.leftContent.supTitle}
                            </p>
                            <h2 className={classes.title}>
                                <span className={classes.titleVariant}>
                                    {itn.leftContent.title[0]}
                                </span>
                                <br />
                                {itn.leftContent.title[1]}
                            </h2>
                            <p className={classes.subtitle}>
                                {itn.leftContent.subtitle}
                            </p>
                            <Button
                                style={{
                                    backgroundColor: "black",
                                    alignSelf: "flex-start",
                                }}
                            >
                                {itn.leftContent.button}
                            </Button>
                        </FlexBox>
                        <FlexBox column className={classes.rightContent}>
                            <FlexBox className={classes.cardsContainer}>
                                <PlatformFeaturesCard
                                    icon={<TechIcon />}
                                    title={itn.rightContent.card1.title}
                                    content={itn.rightContent.card1.content}
                                />
                                <PlatformFeaturesCard
                                    icon={<LinkIcon />}
                                    title={itn.rightContent.card2.title}
                                    content={itn.rightContent.card2.content}
                                />
                                {/* </FlexBox>
                            <FlexBox gap={styles.size(1)}> */}
                                <PlatformFeaturesCard
                                    icon={<SlidersIcon />}
                                    title={itn.rightContent.card3.title}
                                    content={itn.rightContent.card3.content}
                                />
                                <PlatformFeaturesCard
                                    icon={<ChartIcon />}
                                    title={itn.rightContent.card4.title}
                                    content={itn.rightContent.card4.content}
                                />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </Container>
        </section>
    );
}

// region styles
const useStyles = createUseStyles({
    container: {
        backgroundColor: ({ theme }: ST) => theme.elementBlue,
        paddingTop: styles.size(8),
        paddingBottom: styles.size(8),
    },
    content: {
        flex: 1,
        justifyContent: "center",
        gap: styles.size(3),
    },
    leftContent: {
        flex: 2,
        justifyContent: "center",
    },
    rightContent: {
        flex: 3,
        gap: styles.size(1),
    },
    supTitle: {
        fontSize: styles.size(1.25),
        fontWeight: "bold",
        color: ({ theme }: ST) => theme.background,
        marginTop: 0,
        marginBottom: styles.size(1.25),
    },
    title: {
        color: ({ theme }: ST) => theme.background,
        fontSize: "34px",
        fontStyle: "normal",
        fontWeight: 700,
        margin: 0,
    },
    titleVariant: {
        color: ({ theme }: ST) => theme.foreground,
    },
    subtitle: {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        color: ({ theme }: ST) => theme.background,
        opacity: 0.7,
    },
    cardsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: styles.size(1),
    },
    card: {
        backgroundColor: ({ theme }: ST) => theme.background + "22",
        borderRadius: styles.size(1),
        flex: `calc(50% - ${styles.size(0.5)})`,
        padding: styles.size(3),
    },
    cardTitle: {
        margin: 0,
        color: "white",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        marginBottom: styles.size(1),
    },
    cardContent: {
        margin: 0,
        color: "white",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
    },
    cardIconBox: {
        height: styles.size(4),
        width: styles.size(4),
        backgroundColor: ({ theme }: ST) => theme.background + "77",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        marginBottom: styles.size(1),
        borderRadius: styles.size(0.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "@media (max-width: 768px)": {
        card: {
            flex: "100%",
        },
        content: {
            flexDirection: "column !important",
        },
    },
});
// endregion styles
