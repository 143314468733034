import { ItnLanguage, ItnMessagesType, ItnType } from "../utils/itn";

type Type = {
    leftContent: {
        supTitle: string;
        title: [string, string];
        subtitle: string;
        button: string;
    };
    rightContent: {
        card1: {
            title: string;
            content: string;
        };
        card2: {
            title: string;
            content: string;
        };
        card3: {
            title: string;
            content: string;
        };
        card4: {
            title: string;
            content: string;
        };
    };
};

export const itns: ItnType<Type> = {
    ptBr: {
        leftContent: {
            supTitle: "Tecnologia Líder da Studium",
            title: ["Plataforma LMS", "para Instituições, Educadores e Alunos"],
            subtitle:
                "Libere o potencial de sua instituição com a plataforma de aprendizado e crescimento da Studium.",
            button: "Comece hoje mesmo",
        },
        rightContent: {
            card1: {
                title: "Tecnologia E-Learning Avançada",
                content:
                    "Aplique as melhores práticas de sucesso do ensino online, unindo psicologia comportamental com design intuitivo para transformar observadores em aprendizes engajados.",
            },
            card2: {
                title: "Sistema de Feedback Integrado",
                content:
                    "Converta as opiniões dos usuários em melhorias contínuas ao exibir avaliações coletadas de múltiplas plataformas no ponto exato de impacto.",
            },
            card3: {
                title: "Interface Administrativa Simplificada",
                content:
                    "Otimize a gestão do seu LMS com uma interface elegante e simplificada, projetada para uma experiência livre de complicações.",
            },
            card4: {
                title: "Produtos de Crescimento para Educação",
                content:
                    "Produtos e serviços criados especificamente para ajudar no crescimento e expansão de sua instituição educacional da forma mais eficaz possível.",
            },
        },
    },
    ptEu: {
        leftContent: {
            supTitle: "Tecnologia de Ponta da Studium",
            title: ["Plataforma LMS", "para Instituições, Educadores e Alunos"],
            subtitle:
                "Desbloqueie o potencial da sua instituição com a plataforma de ensino e desenvolvimento da Studium.",
            button: "Inicie agora",
        },
        rightContent: {
            card1: {
                title: "Tecnologia Avançada de E-Learning",
                content:
                    "Utilize práticas de sucesso do ensino à distância, aliando psicologia comportamental a um design que converte curiosos em alunos ativos.",
            },
            card2: {
                title: "Sistema Integrado de Avaliações",
                content:
                    "Transforme opiniões de estudantes e docentes em ação ao exibir avaliações de múltiplas fontes, onde mais importa.",
            },
            card3: {
                title: "Interface Admin Intuitiva",
                content:
                    "Simplifique o gerenciamento do seu LMS com uma interface clara e intuitiva, projetada para uso simples e sem dores de cabeça.",
            },
            card4: {
                title: "Produtos para Crescimento Educacional",
                content:
                    "Produtos e serviços desenhados especificamente para auxiliar no crescimento efetivo da sua entidade educacional.",
            },
        },
    },
    en: {
        leftContent: {
            supTitle: "Studium's Leading Technology",
            title: [
                "LMS Platform",
                "for Institutions, Educators, and Students",
            ],
            subtitle:
                "Unlock your institution's potential with Studium's learning and growth platform.",
            button: "Get started today",
        },
        rightContent: {
            card1: {
                title: "Advanced E-Learning Technology",
                content:
                    "Leverage e-learning best practices, blending behavioral psychology with intuitive design to transform viewers into active learners.",
            },
            card2: {
                title: "Integrated Review System",
                content:
                    "Turn user feedback into actionable insights by displaying reviews from multiple platforms at critical touchpoints.",
            },
            card3: {
                title: "Streamlined Admin Interface",
                content:
                    "Simplify your LMS management with a clean and intuitive interface designed for hassle-free operation.",
            },
            card4: {
                title: "Educational Growth Products",
                content:
                    "Discover products and services specifically tailored to help your educational institution grow in the most effective way.",
            },
        },
    },
    es: {
        leftContent: {
            supTitle: "Tecnología Líder de Studium",
            title: [
                "Plataforma LMS",
                "para Instituciones, Educadores y Estudiantes",
            ],
            subtitle:
                "Desbloquee el potencial de su institución con la plataforma de aprendizaje y crecimiento de Studium.",
            button: "Comience hoy",
        },
        rightContent: {
            card1: {
                title: "Tecnología Avanzada de E-Learning",
                content:
                    "Aproveche las mejores prácticas de e-learning, combinando psicología conductual con diseño intuitivo para transformar espectadores en aprendices activos.",
            },
            card2: {
                title: "Sistema Integrado de Reseñas",
                content:
                    "Convierta las opiniones de los usuarios en mejoras tangibles mostrando reseñas recogidas de múltiples plataformas en los puntos críticos.",
            },
            card3: {
                title: "Interfaz Administrativa Simplificada",
                content:
                    "Simplifique la gestión de su LMS con una interfaz limpia e intuitiva diseñada para una operación sin complicaciones.",
            },
            card4: {
                title: "Productos para el Crecimiento Educativo",
                content:
                    "Descubra productos y servicios diseñados específicamente para ayudar a su institución educativa a crecer de la manera más efectiva posible.",
            },
        },
    },
    fr: {
        leftContent: {
            supTitle: "Technologie de Pointe de Studium",
            title: [
                "Plateforme LMS",
                "pour les Institutions, Éducateurs et Étudiants",
            ],
            subtitle:
                "Libérez le potentiel de votre institution avec la plateforme d'apprentissage et de développement de Studium.",
            button: "Commencez aujourd'hui",
        },
        rightContent: {
            card1: {
                title: "Technologie Avancée d'E-Learning",
                content:
                    "Exploitez les meilleures pratiques d'e-learning, combinant psychologie comportementale et conception intuitive pour transformer les spectateurs en apprenants actifs.",
            },
            card2: {
                title: "Système d'Avis Intégré",
                content:
                    "Transformez les retours des utilisateurs en améliorations concrètes en affichant les avis collectés sur plusieurs plateformes là où cela compte vraiment.",
            },
            card3: {
                title: "Interface Admin Épurée",
                content:
                    "Simplifiez la gestion de votre LMS avec une interface nette et intuitive conçue pour une utilisation aisée et sans tracas.",
            },
            card4: {
                title: "Produits pour la Croissance Éducative",
                content:
                    "Découvrez des produits et services spécialement conçus pour aider votre établissement éducatif à se développer de la manière la plus efficace possible.",
            },
        },
    },
    it: {
        leftContent: {
            supTitle: "Tecnologia All'avanguardia di Studium",
            title: ["Piattaforma LMS", "per Istituzioni, Educatori e Studenti"],
            subtitle:
                "Sblocca il potenziale della tua istituzione con la piattaforma di apprendimento e crescita di Studium.",
            button: "Inizia oggi",
        },
        rightContent: {
            card1: {
                title: "Tecnologia E-Learning Avanzata",
                content:
                    "Sfrutta le migliori pratiche di e-learning, combinando psicologia comportamentale e design intuitivo per trasformare gli osservatori in studenti attivi.",
            },
            card2: {
                title: "Sistema di Recensioni Integrato",
                content:
                    "Trasforma le opinioni degli utenti in miglioramenti concreti mostrando recensioni raccolte da molteplici piattaforme nei punti critici.",
            },
            card3: {
                title: "Interfaccia Admin Semplificata",
                content:
                    "Semplifica la gestione del tuo LMS con un'interfaccia pulita e intuitiva, progettata per un funzionamento senza problemi.",
            },
            card4: {
                title: "Prodotti per la Crescita Educativa",
                content:
                    "Scopri prodotti e servizi specificamente progettati per aiutare la tua istituzione educativa a crescere nel modo più efficace possibile.",
            },
        },
    },
    de: {
        leftContent: {
            supTitle: "Führende Technologie von Studium",
            title: [
                "LMS-Plattform",
                "für Institutionen, Pädagogen und Schüler",
            ],
            subtitle:
                "Erschließen Sie das Potenzial Ihrer Institution mit der Lern- und Wachstumsplattform von Studium.",
            button: "Heute starten",
        },
        rightContent: {
            card1: {
                title: "Fortgeschrittene E-Learning-Technologie",
                content:
                    "Nutzen Sie bewährte E-Learning-Methoden, die verhaltenspsychologische Erkenntnisse mit intuitivem Design kombinieren, um Zuschauer in aktive Lerner zu verwandeln.",
            },
            card2: {
                title: "Integriertes Bewertungssystem",
                content:
                    "Verwandeln Sie Nutzerfeedback in konkrete Verbesserungen, indem Sie Bewertungen von mehreren Plattformen an entscheidenden Stellen anzeigen.",
            },
            card3: {
                title: "Vereinfachte Admin-Schnittstelle",
                content:
                    "Vereinfachen Sie das Management Ihres LMS mit einer klaren und intuitiven Schnittstelle, die für eine problemlose Bedienung konzipiert ist.",
            },
            card4: {
                title: "Produkte für Bildungswachstum",
                content:
                    "Entdecken Sie speziell entwickelte Produkte und Dienstleistungen, die Ihrer Bildungseinrichtung ein effektives Wachstum ermöglichen.",
            },
        },
    },
};
