import { ItnLanguage, ItnMessagesType, ItnType } from "../utils/itn";

type Type = {
    signIn: string;
};

export const headerItns: ItnType<Type> = {
    ptBr: {
        signIn: "Entrar",
    },
    ptEu: {
        signIn: "Entrar",
    },
    en: {
        signIn: "Sign In",
    },
    es: {
        signIn: "Iniciar sesión",
    },
    fr: {
        signIn: "Se connecter",
    },
    it: {
        signIn: "Accedi",
    },
    de: {
        signIn: "Anmelden",
    },
};
