import {createUseStyles, useTheme} from "react-jss";
import * as styles from "../../utils/styles";
import {ReactNode, CSSProperties} from "react";
import {className} from "../../utils/className";

type Props = {
    onClick?: () => void,
    children: ReactNode,
    disabled?: boolean,
    style?: CSSProperties,
}

export default function Button(props: Props) {
    const theme = useTheme();
    const classes = useStyles({theme});
    return (
        <button
            className={className([classes.button, props.disabled ? classes.disabled : null])}
            style={props.style}
            onClick={() => !props.disabled && props.onClick !== undefined ? props.onClick() : {}}>
            {props.children}
        </button>
    )
}


const useStyles = createUseStyles({
    button: {
        height: styles.size(3),
        borderRadius: styles.size(0.75),
        fontSize: styles.size(1),
        fontWeight: "bold",
        border: "none",
        backgroundColor: ({theme}: any) => theme.elementBlue,
        boxShadow: ({theme}: any) => `0px 5px 10px 0px ${theme.elementBlue}33`,
        paddingInline: styles.size(1),
        color: "white",
        cursor: "pointer",
    },
    disabled: {
        backgroundColor: ({theme}: any) => theme.backgroundAlt,
        color: ({theme}: any) => `${theme.foregroundSecondary}77`,
        boxShadow: ({theme}: any) => "unset",
        cursor: "not-allowed",
    },
});