import { createUseStyles, useTheme } from "react-jss";
import * as styles from "../utils/styles";
import { Container, FlexBox } from "./studium_ui";
import { useStore } from "../store/store";
import { ItnLanguage, useItn } from "../utils/itn";
import { footerItns } from "./footer.itn";
import { ST } from "../utils/themes";

export default function LanguagesSwitch() {
    const store = useStore();
    const { itn } = useItn(footerItns);
    const theme = useTheme();
    // @ts-ignore
    const classes = useStyles({ theme });

    function changeLanguage(language: ItnLanguage) {
        store.changeLanguage(language);
    }

    return (
        <FlexBox mainAxisAlignment="center">
            <ul className={classes.list}>
                <li
                    onClick={() => changeLanguage("en")}
                    className={classes.item}
                >
                    EN
                </li>
                <li
                    onClick={() => changeLanguage("ptBr")}
                    className={classes.item}
                >
                    PT-BR
                </li>
                <li
                    onClick={() => changeLanguage("ptEu")}
                    className={classes.item}
                >
                    PT-EU
                </li>
                <li
                    onClick={() => changeLanguage("es")}
                    className={classes.item}
                >
                    ES
                </li>
                <li
                    onClick={() => changeLanguage("fr")}
                    className={classes.item}
                >
                    FR
                </li>
                <li
                    onClick={() => changeLanguage("it")}
                    className={classes.item}
                >
                    IT
                </li>
                <li
                    onClick={() => changeLanguage("de")}
                    className={classes.item}
                >
                    DE
                </li>
            </ul>
        </FlexBox>
    );
}
// region styles
const useStyles = createUseStyles({
    list: {
        listStyle: "none",
        display: "flex",
        gap: styles.size(2),
    },
    item: {
        fontSize: styles.size(1),
        opacity: 0.5,
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            color: ({ theme }: ST) => theme.elementBlue,
            fontWeight: "bold",
        },
    },
});
