import { createUseStyles, useTheme } from "react-jss";
import * as styles from "../utils/styles";
import { Container, FlexBox } from "./studium_ui";
import { useStore } from "../store/store";
import Logo from "../assets/images/logo";
import { useItn } from "../utils/itn";
import { footerItns } from "./footer.itn";
import { useNavigate } from "react-router-dom";
import { ST } from "../utils/themes";
import InstagramIcon from "../assets/images/instagram_icon";

export default function Footer() {
    const store = useStore();
    const { itn } = useItn(footerItns);
    const theme = useTheme();
    const navigate = useNavigate();
    // @ts-ignore
    const classes = useStyles({ theme });
    return (
        <section className={classes.container}>
            <Container
                style={{ maxWidth: "1500px", minHeight: styles.size(50) }}
            >
                <FlexBox
                    column
                    gap={styles.size(1)}
                    style={{ minHeight: styles.size(50) }}
                >
                    <p className={classes.helpMessage}>
                        {itn.support1}{" "}
                        <a
                            className={classes.helpMessageLink}
                            href="mailto:team@studium.academy?subject=Support"
                        >
                            {itn.support2}
                        </a>{" "}
                        {itn.support3}
                    </p>
                    <div style={{ flex: 1 }} />
                    <FlexBox
                        crossAxisAlignment="center"
                        mainAxisAlignment="space-between"
                    >
                        <Logo
                            color={store.theme === "dark" ? "white" : "black"}
                            className={classes.studiumLogo}
                        />
                        <a
                            href="https://instagram.com/studium.academy"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "white" }}
                        >
                            <InstagramIcon />
                        </a>
                    </FlexBox>
                    <div className={classes.line} />
                    <div>
                        <ul className={classes.ul}>
                            <li onClick={() => navigate("/privacy")}>
                                <span className={classes.link}>
                                    {itn.privacyPolicy}
                                </span>
                            </li>
                            <li onClick={() => navigate("/terms-of-service")}>
                                <span className={classes.link}>
                                    {itn.termsOfService}
                                </span>
                            </li>

                            {/* <li>
                                <a href="/">Cookies</a>
                            </li> */}
                            {/* <li>
                                <a href="/">{itn.termsOfService}</a>
                            </li> */}
                            {/* <li>
                                <a href="/">
                                    {itn.communityParticipationGuidelines}
                                </a>
                            </li> */}
                            {/* <li>
                                <a href="/">Sobre este site</a>
                            </li> */}
                        </ul>
                        <p className={classes.copyright}>
                            Copyright © 2022-{new Date().getFullYear()} -{" "}
                            {itn.allRightsReserved}. Studium.
                        </p>
                    </div>
                </FlexBox>
            </Container>
        </section>
    );
}

// region styles
const useStyles = createUseStyles({
    container: {
        backgroundColor: ({ theme }: ST) => theme.backgroundAlt,
        paddingBlock: styles.size(1),
        minHeight: styles.size(50),
    },
    helpMessage: {
        fontSize: styles.size(2),
        fontWeight: 700,
        textAlign: "center",
    },
    helpMessageLink: {
        color: ({ theme }: ST) => theme.elementBlue,
        cursor: "pointer",
        textDecoration: "underline",
        // transition: "all .2s ease-in-out",
        // "&:hover": {
        //     color: ({theme}: any) => theme.elementBlueHover,
        // }
    },
    studiumLogo: {
        width: styles.size(12),
    },
    line: {
        backgroundColor: ({ theme }: ST) => theme.foregroundSecondary,
        opacity: 0.2,
        height: "1px",
        width: "100%",
    },
    ul: {
        listStyle: "none",
        padding: 0,
        display: "flex",
        gap: styles.size(2),
        fontSize: styles.size(1),
        "& > li > a": {
            color: ({ theme }: ST) => theme.foregroundSecondary,
            textDecoration: "none",
        },
    },
    link: {
        userSelect: "none",
        cursor: "pointer",
        transition: "all .2s ease-in-out",
        "&:hover": {
            color: ({ theme }: ST) => theme.elementBlue,
        },
    },
    copyright: {
        fontSize: styles.size(1),
        color: ({ theme }: ST) => theme.foregroundSecondary,
        textAlign: "center",
        marginTop: styles.size(5),
        marginBottom: styles.size(10),
    },
});
// endregion
