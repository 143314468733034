import './container.scss';
import {className} from "../../utils/className";
import {CSSProperties, ReactNode} from "react";

class Props {
  children?: ReactNode;
  horizontalPadding?: number;
  style?: CSSProperties;
  className?: string;
}

export default function Container(props: Props) {
  return (
      <div
          className={className(["studium_ui_container", props.className ?? null])}
          style={{
              paddingInline: props.horizontalPadding !== undefined ? `${props.horizontalPadding}px` : undefined,
              ...props.style,
          }}>
          {props.children}
      </div>
  );
}
