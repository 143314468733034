import Markdown from "react-markdown";
import { Container, FlexBox } from "../components/studium_ui";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "../utils/styles";
import { useStore } from "../store/store";
import Logo from "../assets/images/logo";
import { useItn } from "../utils/itn";
import { itns } from "./privacy_policy.itn";
import { useNavigate } from "react-router-dom";
import { ST } from "../utils/themes";
import Header from "../components/header";
import LanguagesSwitch from "../components/languages_switch";

export default function PrivacyPolicy() {
    const store = useStore();
    const { itn } = useItn(itns);
    const theme = useTheme();
    const navigate = useNavigate();
    // @ts-ignore
    const classes = useStyles({ theme });
    return (
        <FlexBox column>
            <Header />
            <FlexBox column className={classes.titleOutContainer}>
                <LanguagesSwitch />
                <Container className={classes.titleContainer}>
                    <h1>{itn.privacyPolicyTitle}</h1>
                </Container>
            </FlexBox>
            <Container className={classes.container}>
                <Markdown>{itn.privacyPolicy}</Markdown>
            </Container>
        </FlexBox>
    );
}

const useStyles = createUseStyles({
    titleOutContainer: {
        backgroundColor: ({ theme }: ST) => `${theme.foreground}07`,
        alignSelf: "stretch",
        color: ({ theme }: ST) => theme.foreground,
    },
    titleContainer: {
        alignSelf: "stretch",
        color: ({ theme }: ST) => theme.foreground,
        paddingTop: styles.size(8),
        paddingBottom: styles.size(10),
    },
    container: {
        color: ({ theme }: ST) => theme.foreground,
        paddingTop: styles.size(8),
        paddingBottom: styles.size(8),
    },
});
