import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/styles.css";
import App from "./pages/app";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "react-jss";
import { StoreProvider, useStore } from "./store/store";
import { ItnLanguage } from "./utils/itn";
import { darkTheme, lightTheme } from "./utils/themes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "./pages/privacy_policy";
import TermsOfService from "./pages/terms_of_service";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy />,
    },
    {
        path: "/terms-of-service",
        element: <TermsOfService />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <StoreProvider>
        <StudiumTheme>
            {/* <ThemeProvider theme={lightTheme}> */}
            <RouterProvider router={router} />
            {/* </ThemeProvider> */}
        </StudiumTheme>
    </StoreProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function StudiumTheme({ children }: { children: React.ReactNode }) {
    const store = useStore();

    const themes = {
        light: lightTheme,
        dark: darkTheme,
    };

    const theme = store.theme === "dark" ? themes["dark"] : themes["light"];
    console.log("Store", store);

    const themeName = theme === themes["dark"] ? "dark" : "light";

    const [language, setLanguage] = useState<ItnLanguage | undefined>(
        undefined,
    );
    const [firstRender, setFirstRender] = useState<boolean>(true);

    useEffect(() => {
        // remove scrollbars
        document.documentElement.style.overflow = "hidden";
        // trigger reflow so that overflow style is applied
        document.documentElement.style.backgroundColor = theme.background;
        // document.body.clientWidth;
        // change scheme
        document.documentElement.setAttribute(
            "data-color-scheme",
            store.theme === "dark" ? "dark" : "light",
        );
        // remove overflow style, which will bring back the scrollbar with the correct scheme
        document.documentElement.style.overflow = "";
    }, [store.theme, theme.background]);

    useEffect(() => {
        if (!firstRender) return;
        if (store.language !== undefined) {
            store.changeLanguage(store.language);
        } else if (navigator.language.startsWith("pt-BR")) {
            store.changeLanguage("ptBr");
        } else if (navigator.language.startsWith("es")) {
            store.changeLanguage("es");
        } else if (navigator.language.startsWith("fr")) {
            store.changeLanguage("fr");
        } else if (navigator.language.startsWith("it")) {
            store.changeLanguage("it");
        } else if (navigator.language.startsWith("de")) {
            store.changeLanguage("de");
        } else if (navigator.language.startsWith("pt")) {
            store.changeLanguage("ptEu");
        } else {
            store.changeLanguage("en");
        }
        setFirstRender(false);
    }, [store.language, firstRender, store]);
    return (
        <ThemeProvider theme={theme}>
            <div className={themeName}>{children}</div>
        </ThemeProvider>
    );
}
