import image1 from "../assets/images/comparison-2.webp";
import image2 from "../assets/images/studium_computer.webp";
import image3 from "../assets/images/comparison-1.webp";
import image4 from "../assets/images/comparison-4.webp";
import { Container, FlexBox } from "./studium_ui";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "../utils/styles";
import { useItn } from "../utils/itn";
import { landingItns } from "./landing.itn";
import { ST, StudiumTheme } from "../utils/themes";

function ComparativeItem({
    title,
    description,
    image,
    callToAction,
    reverse,
}: {
    title: string;
    description: string;
    image: string;
    callToAction?: string;
    reverse?: boolean;
}) {
    const theme = useTheme() as StudiumTheme;
    const classes = useStyles({ theme });
    return (
        <FlexBox
            gap={styles.size(3)}
            className={classes.itemsContainer}
            style={reverse ? { flexDirection: "row-reverse" } : undefined}
        >
            <FlexBox
                column
                flex={1}
                mainAxisAlignment="center"
                gap={styles.size(1.5)}
                style={{ marginRight: styles.size(4) }}
            >
                {" "}
                <FlexBox column gap={styles.size(1.5)}>
                    <h1 className={classes.title}>{title}</h1>
                    <p className={classes.subTitle}>{description}</p>
                    {callToAction && (
                        <span className={classes.readMore}>{callToAction}</span>
                    )}
                </FlexBox>
            </FlexBox>
            <FlexBox
                flex={1}
                mainAxisAlignment="center"
                crossAxisAlignment="center"
            >
                <div>
                    <img style={{ width: "100%" }} src={image} alt="hero" />
                </div>
            </FlexBox>
        </FlexBox>
    );
}

export default function ComparativeSession() {
    const { itn } = useItn(landingItns);
    const theme = useTheme() as StudiumTheme;
    const classes = useStyles({ theme });
    return (
        <section className={classes.container}>
            <Container
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "stretch",
                    maxWidth: "1000px",
                }}
            >
                <FlexBox column gap={styles.size(15)}>
                    <ComparativeItem
                        title={itn.comparativeSession.comparison1.title}
                        description={
                            itn.comparativeSession.comparison1.description
                        }
                        callToAction={
                            itn.comparativeSession.comparison1.callToAction
                        }
                        image={image3}
                        reverse={false}
                    />

                    <ComparativeItem
                        title={itn.comparativeSession.comparison2.title}
                        description={
                            itn.comparativeSession.comparison2.description
                        }
                        callToAction={
                            itn.comparativeSession.comparison2.callToAction
                        }
                        image={image1}
                        reverse={true}
                    />

                    <ComparativeItem
                        title={itn.comparativeSession.comparison3.title}
                        description={
                            itn.comparativeSession.comparison3.description
                        }
                        callToAction={
                            itn.comparativeSession.comparison3.callToAction
                        }
                        image={image2}
                        reverse={false}
                    />

                    <ComparativeItem
                        title={itn.comparativeSession.comparison4.title}
                        description={
                            itn.comparativeSession.comparison4.description
                        }
                        callToAction={
                            itn.comparativeSession.comparison4.callToAction
                        }
                        image={image4}
                        reverse={true}
                    />
                </FlexBox>
            </Container>
        </section>
    );
}

// region styles
const useStyles = createUseStyles({
    container: {
        color: ({ theme }: ST) => theme.foreground,
        paddingTop: styles.size(8),
        paddingBottom: styles.size(8),
        minHeight: "780px",
    },
    title: {
        fontSize: styles.size(2.5),
        lineHeight: 1.2,
        margin: 0,
        fontWeight: 700,
    },
    subTitle: {
        fontSize: styles.size(1.25),
        margin: 0,
        lineHeight: 1.7,
        marginBottom: styles.size(2),
    },
    readMore: {
        fontSize: styles.size(1.25),
        color: ({ theme }: ST) => theme.elementBlue,
        textDecoration: "underline",
        cursor: "pointer",
    },
    image: {
        cursor: "pointer",
        transition: "all .2s ease-in-out",
        width: styles.size(16),
        // color: ({theme}: any) => theme.elementBlue,
    },
    itemsContainer: {},
    "@media (max-width: 768px)": {
        itemsContainer: {
            flexDirection: "column !important",
        },
    },
});
// endregion
