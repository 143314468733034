import logo from "../assets/images/studium_logo.svg";
import { createUseStyles, useTheme } from "react-jss";
import { Container, FlexBox } from "./studium_ui";
import * as styles from "../utils/styles";
import Button from "./button/button";
import Logo from "../assets/images/logo";
import { useStore } from "../store/store";
import { headerItns } from "./header.itn";
import { useItn } from "../utils/itn";
import ThemeIcon from "../assets/images/theme_icon";
import { ST } from "../utils/themes";
import { useNavigate } from "react-router-dom";

export default function Header() {
    const store = useStore();
    const { itn } = useItn(headerItns);
    const theme = useTheme();
    const navigate = useNavigate();
    // @ts-ignore
    const classes = useStyles({ theme });
    return (
        <section className={classes.container}>
            <Container style={{ maxWidth: "1080px" }}>
                <FlexBox
                    className={classes.headerContainer}
                    mainAxisAlignment="space-between"
                >
                    {/* <img src={logo} className={classes.image} alt="logo" /> */}
                    <Logo
                        onClick={() => navigate("/")}
                        color={store.theme === "dark" ? "white" : "black"}
                        className={classes.image}
                    />
                    <FlexBox crossAxisAlignment="center" gap={styles.size(2)}>
                        <div
                            onClick={() => store.toggleTheme()}
                            style={{
                                userSelect: "none",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <ThemeIcon
                                color={
                                    store.theme === "dark" ? "white" : "black"
                                }
                            />
                        </div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://app.studium.academy/#/sign-in/escolaamarela"
                        >
                            <Button
                                style={{
                                    paddingInline: styles.size(3),
                                    height: styles.size(4),
                                }}
                            >
                                {itn.signIn}
                            </Button>
                        </a>
                    </FlexBox>
                </FlexBox>
            </Container>
        </section>
    );
}

// region styles
const useStyles = createUseStyles({
    container: {
        backgroundColor: ({ theme }: ST) => theme.backgroundAlt,
        borderBottom: ({ theme }: ST) => `1px solid ${theme.border}`,
    },
    headerContainer: {
        display: "flex",
        height: styles.size(7),
        paddingBlock: styles.size(1),
        alignItems: "center",
    },
    image: {
        cursor: "pointer",
        transition: "all .2s ease-in-out",
        width: styles.size(12),
        // color: ({theme}: any) => theme.elementBlue,
    },
});
// endregion
