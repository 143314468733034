import { ItnLanguage, ItnMessagesType, ItnType } from "../utils/itn";

type Type = {
    privacyPolicyTitle: string;
    privacyPolicy: string;
};

export const itns: ItnType<Type> = {
    ptBr: {
        privacyPolicyTitle: "Política de Privacidade",
        privacyPolicy: `
### 1. VISÃO GERAL

A Studium ("Studium", "nós" ou "nosso") compromete-se em proteger a privacidade de seus usuários ("você" ou "usuário") que acessam nossas plataformas educacionais e aplicações por meio de https://studium.academy e serviços relacionados (coletivamente, a "Plataforma").

Este Aviso de Privacidade descreve como coletamos, utilizamos e protegemos suas informações pessoais enquanto você interage com nossa Plataforma. Nós aderimos às leis de proteção de dados aplicáveis nas regiões em que operamos, incluindo a conformidade com as leis federais e estaduais dos Estados Unidos.

### 2. COLETA E USO DE INFORMAÇÕES PESSOAIS

Coletamos informações pessoais diretamente de nossos usuários quando interagem com nossa Plataforma, incluindo quando se registram, participam de nossos cursos educacionais ou se comunicam conosco através de formulários e e-mails. Essas informações podem incluir seu nome, endereço de e-mail, formação educacional e quaisquer outros detalhes que você optar por compartilhar.

Além disso, coletamos automaticamente certos dados do seu dispositivo quando você usa nossa Plataforma, como endereços IP, tipo de navegador, sistema operacional e atividades de navegação por meio de cookies. Essas informações nos ajudam a melhorar e personalizar nossos serviços, tornando a Plataforma mais amigável e adaptada às suas necessidades educacionais.

### 3. POLÍTICA DE COOKIES

A Studium utiliza cookies para melhorar sua experiência em nossa Plataforma. Cookies são pequenos arquivos de dados colocados em seu dispositivo que nos ajudam a lembrar suas preferências e coletar informações sobre suas interações com nossa Plataforma. Isso inclui informações sobre seus padrões e preferências de uso.

Você tem a opção de desativar os cookies através das configurações do seu navegador, mas esteja ciente de que algumas partes da Plataforma podem não funcionar otimamente sem eles.

### 4. COMPARTILHAMENTO E DIVULGAÇÃO DE INFORMAÇÕES

Não vendemos nem alugamos informações pessoais para terceiros. Podemos compartilhar suas informações com prestadores de serviços que nos ajudam a operar a Plataforma, como hospedagem, análises e atendimento ao cliente, sob estritos acordos de confidencialidade. Esses prestadores são obrigados a proteger suas informações e não têm permissão para usá-las para outros fins.

### 5. SEUS DIREITOS DE PRIVACIDADE

Dependendo de sua localização, você pode ter direitos específicos sobre suas informações pessoais. Estes incluem o direito de acessar, corrigir ou excluir suas informações pessoais, bem como o direito de se opor ou restringir certas atividades de processamento. Se desejar exercer algum desses direitos, entre em contato conosco em team@studium.academy.

### 6. PROTEÇÃO DE DADOS – RESIDENTES DA UE

Se você reside na União Europeia (UE), Espaço Econômico Europeu (EEE) ou Reino Unido (UK), você tem direitos adicionais sob o Regulamento Geral de Proteção de Dados (GDPR). Estes incluem o direito de solicitar acesso, retificação ou exclusão de seus dados pessoais, ou transferir seus dados para outro provedor de serviço.

### 7. RESIDENTES DA CALIFÓRNIA

Sob a Lei de Privacidade do Consumidor da Califórnia (CCPA), os residentes da Califórnia têm o direito de solicitar informações sobre a coleta, uso e compartilhamento de seus dados pessoais. A Studium não vende informações pessoais para terceiros.

### 8. SEGURANÇA DOS DADOS

Implementamos medidas de segurança robustas para proteger suas informações pessoais de acesso não autorizado, alteração ou destruição. No entanto, nenhum serviço baseado na internet pode garantir segurança completa, por isso incentivamos os usuários a tomar precauções para proteger seus dados pessoais.

### 9. ALTERAÇÕES NESTE AVISO DE PRIVACIDADE

A Studium reserva-se o direito de modificar este Aviso de Privacidade a qualquer momento. Publicaremos quaisquer mudanças em nossa Plataforma, e encorajamos você a revisar nosso Aviso de Privacidade periodicamente.

### 10. CONTATE-NOS

Se você tiver quaisquer dúvidas ou preocupações sobre nosso Aviso de Privacidade ou nossas práticas de dados, por favor, contate-nos em team@studium.academy.
`,
    },
    ptEu: {
        privacyPolicyTitle: "Política de Privacidade",
        privacyPolicy: `
### 1. VISÃO GERAL

A Studium ("Studium", "nós" ou "nosso") compromete-se em proteger a privacidade dos seus utilizadores ("você" ou "utilizador") que acedem às nossas plataformas educacionais e aplicações através de https://studium.academy e serviços relacionados (coletivamente, a "Plataforma").

Esta Notificação de Privacidade explica como recolhemos, utilizamos e protegemos as suas informações pessoais enquanto interage com a nossa Plataforma. Cumprimos as leis de proteção de dados aplicáveis nas regiões em que operamos, incluindo a conformidade com as leis federais e estaduais dos Estados Unidos.

### 2. RECOLHA E UTILIZAÇÃO DE INFORMAÇÕES PESSOAIS

Recolhemos informações pessoais diretamente dos nossos utilizadores quando interagem com a nossa Plataforma, incluindo quando se registam, participam nos nossos cursos educativos ou comunicam connosco através de formulários e e-mails. Estas informações podem incluir o seu nome, endereço de e-mail, formação educativa e quaisquer outros detalhes que opte por partilhar.

Adicionalmente, recolhemos automaticamente certos dados do seu dispositivo quando utiliza a nossa Plataforma, como endereços IP, tipo de navegador, sistema operativo e atividades de navegação através de cookies. Estas informações ajudam-nos a melhorar e personalizar os nossos serviços, tornando a Plataforma mais amigável e adaptada às suas necessidades educativas.

### 3. POLÍTICA DE COOKIES

A Studium utiliza cookies para melhorar a sua experiência na nossa Plataforma. Os cookies são pequenos ficheiros de dados colocados no seu dispositivo que nos ajudam a lembrar as suas preferências e recolher informações sobre as suas interações com a nossa Plataforma. Isto inclui informações sobre os seus padrões e preferências de utilização.

Tem a opção de desativar os cookies através das configurações do seu navegador, mas esteja ciente de que algumas partes da Plataforma podem não funcionar de forma otimizada sem eles.

### 4. PARTILHA E DIVULGAÇÃO DE INFORMAÇÕES

Não vendemos nem alugamos informações pessoais a terceiros. Podemos partilhar as suas informações com prestadores de serviços que nos ajudam a operar a Plataforma, como alojamento, análises e serviço ao cliente, sob acordos estritos de confidencialidade. Estes prestadores são obrigados a proteger as suas informações e não têm permissão para usá-las para outros fins.

### 5. OS SEUS DIREITOS DE PRIVACIDADE

Dependendo da sua localização, pode ter direitos específicos sobre as suas informações pessoais. Estes incluem o direito de aceder, corrigir ou apagar as suas informações pessoais, bem como o direito de se opor ou restringir certas atividades de processamento. Se desejar exercer algum destes direitos, entre em contacto connosco através de team@studium.academy.

### 6. PROTEÇÃO DE DADOS – RESIDENTES DA UE

Se reside na União Europeia (UE), Espaço Económico Europeu (EEE) ou Reino Unido (UK), possui direitos adicionais sob o Regulamento Geral de Proteção de Dados (GDPR). Estes incluem o direito de solicitar acesso, retificação ou eliminação dos seus dados pessoais, ou transferir os seus dados para outro fornecedor de serviço.

### 7. RESIDENTES DA CALIFÓRNIA

Ao abrigo da Lei de Privacidade do Consumidor da Califórnia (CCPA), os residentes da Califórnia têm o direito de solicitar informações sobre a recolha, uso e partilha dos seus dados pessoais. A Studium não vende informações pessoais a terceiros.

### 8. SEGURANÇA DOS DADOS

Implementamos medidas de segurança robustas para proteger as suas informações pessoais de acesso não autorizado, alteração ou destruição. No entanto, nenhum serviço baseado na internet pode garantir segurança completa, por isso incentivamos os utilizadores a tomar precauções para proteger os seus dados pessoais.

### 9. ALTERAÇÕES A ESTA NOTIFICAÇÃO DE PRIVACIDADE

A Studium reserva-se o direito de modificar esta Notificação de Privacidade a qualquer momento. Publicaremos quaisquer alterações na nossa Plataforma, e encorajamos a revisão periódica desta Notificação de Privacidade.

### 10. CONTACTE-NOS

Se tiver quaisquer questões ou preocupações sobre a nossa Notificação de Privacidade ou as nossas práticas de dados, por favor contacte-nos através de team@studium.academy.
`,
    },
    en: {
        privacyPolicyTitle: "Privacy Policy",
        privacyPolicy: `
### 1. OVERVIEW

Studium ("Studium," "we," or "our") is committed to protecting the privacy of its users ("you" or "user") who access our educational platforms and applications through https://studium.academy and related services (collectively, the "Platform").

This Privacy Notice outlines how we collect, use, and safeguard your personal information as you interact with our Platform. We adhere to applicable data protection laws in the regions we operate, including compliance with the United States federal and state laws.

### 2. COLLECTION AND USE OF PERSONAL INFORMATION

We collect personal information directly from our users when they interact with our Platform, including when they register, participate in our educational courses, or communicate with us via forms and emails. This information can include your name, email address, educational background, and any other details you choose to share.

Additionally, we automatically gather certain data from your device when you use our Platform, such as IP addresses, browser type, operating system, and browsing activities through cookies. This information helps us improve and personalize our services, making the Platform more user-friendly and tailored to your educational needs.

### 3. COOKIE POLICY

Studium utilizes cookies to enhance your experience on our Platform. Cookies are small data files placed on your device that assist us in remembering your preferences and collecting information about your interactions with our Platform. This includes information about your usage patterns and preferences.

You have the option to disable cookies through your browser settings, but be aware that some parts of the Platform may not function optimally without them.

### 4. INFORMATION SHARING AND DISCLOSURE

We do not sell or rent personal information to third parties. We may share your information with service providers who assist us in operating the Platform, such as hosting, analytics, and customer service, under strict confidentiality agreements. These providers are obligated to protect your information and are not permitted to use it for any other purposes.

### 5. YOUR PRIVACY RIGHTS

Depending on your location, you may have specific rights regarding your personal information. These include the right to access, correct, or delete your personal information, as well as the right to object to or restrict certain processing activities. If you wish to exercise any of these rights, please contact us at team@studium.academy.

### 6. DATA PROTECTION – EU RESIDENTS

If you reside in the European Union (EU), European Economic Area (EEA), or the United Kingdom (UK), you have additional rights under the General Data Protection Regulation (GDPR). These include the right to request access to, rectification of, or erasure of your personal data, or to transfer your data to another service provider.

### 7. CALIFORNIA RESIDENTS

Under the California Consumer Privacy Act (CCPA), California residents have the right to request information about the collection, use, and sharing of their personal data. Studium does not sell personal information to third parties.

### 8. DATA SECURITY

We implement robust security measures to protect your personal information from unauthorized access, alteration, or destruction. However, no internet-based service can guarantee complete security, so we encourage users to take precautions to protect their personal data.

### 9. CHANGES TO THIS PRIVACY NOTICE

Studium reserves the right to modify this Privacy Notice at any time. We will post any changes on our Platform, and we encourage you to review our Privacy Notice periodically.

### 10. CONTACT US

If you have any questions or concerns about our Privacy Notice or our data practices, please contact us at team@studium.academy.
`,
    },
    es: {
        privacyPolicyTitle: "Política de Privacidad",
        privacyPolicy: `
### 1. VISIÓN GENERAL

Studium ("Studium", "nosotros" o "nuestro") se compromete a proteger la privacidad de sus usuarios ("usted" o "usuario") que acceden a nuestras plataformas educativas y aplicaciones a través de https://studium.academy y servicios relacionados (colectivamente, la "Plataforma").

Esta Notificación de Privacidad explica cómo recopilamos, usamos y protegemos su información personal mientras interactúa con nuestra Plataforma. Cumplimos con las leyes de protección de datos aplicables en las regiones en las que operamos, incluyendo el cumplimiento de las leyes federales y estatales de los Estados Unidos.

### 2. RECOLECCIÓN Y USO DE INFORMACIÓN PERSONAL

Recopilamos información personal directamente de nuestros usuarios cuando interactúan con nuestra Plataforma, incluyendo cuando se registran, participan en nuestros cursos educativos o se comunican con nosotros a través de formularios y correos electrónicos. Esta información puede incluir su nombre, dirección de correo electrónico, antecedentes educativos y cualquier otro detalle que elija compartir.

Además, recopilamos automáticamente ciertos datos de su dispositivo cuando utiliza nuestra Plataforma, como direcciones IP, tipo de navegador, sistema operativo y actividades de navegación a través de cookies. Esta información nos ayuda a mejorar y personalizar nuestros servicios, haciendo que la Plataforma sea más amigable y adaptada a sus necesidades educativas.

### 3. POLÍTICA DE COOKIES

Studium utiliza cookies para mejorar su experiencia en nuestra Plataforma. Las cookies son pequeños archivos de datos que se colocan en su dispositivo y que nos ayudan a recordar sus preferencias y recopilar información sobre sus interacciones con nuestra Plataforma. Esto incluye información sobre sus patrones y preferencias de uso.

Tiene la opción de desactivar las cookies a través de la configuración de su navegador, pero tenga en cuenta que algunas partes de la Plataforma pueden no funcionar de manera óptima sin ellas.

### 4. COMPARTIR Y DIVULGAR INFORMACIÓN

No vendemos ni alquilamos información personal a terceros. Podemos compartir su información con proveedores de servicios que nos ayudan a operar la Plataforma, como alojamiento, análisis y servicio al cliente, bajo estrictos acuerdos de confidencialidad. Estos proveedores están obligados a proteger su información y no están autorizados a usarla para otros fines.

### 5. SUS DERECHOS DE PRIVACIDAD

Dependiendo de su ubicación, puede tener derechos específicos sobre su información personal. Estos incluyen el derecho a acceder, corregir o eliminar su información personal, así como el derecho a oponerse o restringir ciertas actividades de procesamiento. Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros en team@studium.academy.

### 6. PROTECCIÓN DE DATOS – RESIDENTES DE LA UE

Si reside en la Unión Europea (UE), el Espacio Económico Europeo (EEE) o el Reino Unido (UK), tiene derechos adicionales bajo el Reglamento General de Protección de Datos (GDPR). Estos incluyen el derecho a solicitar acceso, rectificación o eliminación de sus datos personales, o a transferir sus datos a otro proveedor de servicios.

### 7. RESIDENTES DE CALIFORNIA

Bajo la Ley de Privacidad del Consumidor de California (CCPA), los residentes de California tienen derecho a solicitar información sobre la recopilación, uso y compartición de sus datos personales. Studium no vende información personal a terceros.

### 8. SEGURIDAD DE LOS DATOS

Implementamos medidas de seguridad robustas para proteger su información personal de acceso no autorizado, alteración o destrucción. Sin embargo, ningún servicio basado en internet puede garantizar seguridad completa, por lo que animamos a los usuarios a tomar precauciones para proteger sus datos personales.

### 9. CAMBIOS EN ESTA NOTIFICACIÓN DE PRIVACIDAD

Studium se reserva el derecho de modificar esta Notificación de Privacidad en cualquier momento. Publicaremos cualquier cambio en nuestra Plataforma, y le animamos a revisar periódicamente esta Notificación de Privacidad.

### 10. CONTÁCTENOS

Si tiene alguna pregunta o preocupación sobre nuestra Notificación de Privacidad o nuestras prácticas de datos, por favor contáctenos en team@studium.academy.
`,
    },
    fr: {
        privacyPolicyTitle: "Politique de Confidentialité",
        privacyPolicy: `
### 1. VUE D'ENSEMBLE

Studium ("Studium", "nous" ou "notre") s'engage à protéger la vie privée de ses utilisateurs ("vous" ou "l'utilisateur") qui accèdent à nos plateformes éducatives et applications via https://studium.academy et les services connexes (collectivement, la "Plateforme").

Cette Notice de Confidentialité explique comment nous collectons, utilisons et protégeons vos informations personnelles lorsque vous interagissez avec notre Plateforme. Nous nous conformons aux lois sur la protection des données applicables dans les régions où nous opérons, y compris les lois fédérales et étatiques des États-Unis.

### 2. COLLECTE ET UTILISATION DES INFORMATIONS PERSONNELLES

Nous recueillons des informations personnelles directement auprès de nos utilisateurs lorsqu'ils interagissent avec notre Plateforme, y compris lorsqu'ils s'inscrivent, participent à nos cours éducatifs ou communiquent avec nous via des formulaires et des e-mails. Ces informations peuvent inclure votre nom, adresse e-mail, parcours éducatif et tout autre détail que vous choisissez de partager.

De plus, nous recueillons automatiquement certaines données de votre appareil lorsque vous utilisez notre Plateforme, telles que les adresses IP, le type de navigateur, le système d'exploitation et les activités de navigation via des cookies. Ces informations nous aident à améliorer et à personnaliser nos services, rendant la Plateforme plus conviviale et adaptée à vos besoins éducatifs.

### 3. POLITIQUE DES COOKIES

Studium utilise des cookies pour améliorer votre expérience sur notre Plateforme. Les cookies sont de petits fichiers de données placés sur votre appareil qui nous aident à mémoriser vos préférences et à collecter des informations sur vos interactions avec notre Plateforme. Cela inclut des informations sur vos modèles et préférences d'utilisation.

Vous avez la possibilité de désactiver les cookies via les paramètres de votre navigateur, mais soyez conscient que certaines parties de la Plateforme peuvent ne pas fonctionner de manière optimale sans eux.

### 4. PARTAGE ET DIVULGATION DES INFORMATIONS

Nous ne vendons ni ne louons les informations personnelles à des tiers. Nous pouvons partager vos informations avec des fournisseurs de services qui nous aident à exploiter la Plateforme, tels que l'hébergement, l'analyse et le service clientèle, sous de stricts accords de confidentialité. Ces fournisseurs sont tenus de protéger vos informations et ne sont pas autorisés à les utiliser à d'autres fins.

### 5. VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ

Selon votre localisation, vous pouvez avoir des droits spécifiques concernant vos informations personnelles. Ceux-ci incluent le droit d'accéder, de corriger ou de supprimer vos informations personnelles, ainsi que le droit de s'opposer ou de restreindre certaines activités de traitement. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter à team@studium.academy.

### 6. PROTECTION DES DONNÉES – RÉSIDENTS DE L'UE

Si vous résidez dans l'Union européenne (UE), l'Espace économique européen (EEE) ou le Royaume-Uni (UK), vous bénéficiez de droits supplémentaires en vertu du Règlement général sur la protection des données (RGPD). Ceux-ci incluent le droit de demander l'accès, la rectification ou la suppression de vos données personnelles, ou de transférer vos données à un autre prestataire de services.

### 7. RÉSIDENTS DE CALIFORNIE

En vertu de la California Consumer Privacy Act (CCPA), les résidents de Californie ont le droit de demander des informations sur la collecte, l'utilisation et le partage de leurs données personnelles. Studium ne vend pas d'informations personnelles à des tiers.

### 8. SÉCURITÉ DES DONNÉES

Nous mettons en œuvre des mesures de sécurité robustes pour protéger vos informations personnelles contre l'accès non autorisé, la modification ou la destruction. Cependant, aucun service basé sur Internet ne peut garantir une sécurité complète, nous encourageons donc les utilisateurs à prendre des précautions pour protéger leurs données personnelles.

### 9. MODIFICATIONS DE CETTE NOTICE DE CONFIDENTIALITÉ

Studium se réserve le droit de modifier cette Notice de Confidentialité à tout moment. Nous publierons toute modification sur notre Plateforme, et nous vous encourageons à revoir périodiquement cette Notice de Confidentialité.

### 10. CONTACTEZ-NOUS

Si vous avez des questions ou des préoccupations concernant notre Notice de Confidentialité ou nos pratiques de données, veuillez nous contacter à team@studium.academy.
`,
    },
    it: {
        privacyPolicyTitle: "Informativa sulla privacy",
        privacyPolicy: `
### 1. PANORAMICA

Studium ("Studium", "noi" o "nostro") si impegna a proteggere la privacy dei suoi utenti ("tu" o "utente") che accedono alle nostre piattaforme educative e applicazioni tramite https://studium.academy e servizi correlati (collettivamente, la "Piattaforma").

Questa Informativa sulla Privacy spiega come raccogliamo, utilizziamo e proteggiamo le tue informazioni personali mentre interagisci con la nostra Piattaforma. Rispettiamo le leggi sulla protezione dei dati applicabili nelle regioni in cui operiamo, inclusa la conformità con le leggi federali e statali degli Stati Uniti.

### 2. RACCOLTA E UTILIZZO DI INFORMAZIONI PERSONALI

Raccogliamo informazioni personali direttamente dai nostri utenti quando interagiscono con la nostra Piattaforma, inclusi la registrazione, la partecipazione ai nostri corsi educativi o la comunicazione con noi tramite moduli e email. Queste informazioni possono includere il tuo nome, indirizzo email, background educativo e qualsiasi altro dettaglio che scegli di condividere.

Inoltre, raccogliamo automaticamente alcuni dati dal tuo dispositivo quando utilizzi la nostra Piattaforma, come indirizzi IP, tipo di browser, sistema operativo e attività di navigazione attraverso i cookie. Queste informazioni ci aiutano a migliorare e personalizzare i nostri servizi, rendendo la Piattaforma più user-friendly e adattata alle tue esigenze educative.

### 3. POLITICA SUI COOKIE

Studium utilizza i cookie per migliorare la tua esperienza sulla nostra Piattaforma. I cookie sono piccoli file di dati che vengono posizionati sul tuo dispositivo e che ci aiutano a ricordare le tue preferenze e a raccogliere informazioni sulle tue interazioni con la nostra Piattaforma. Questo include informazioni sui tuoi modelli e preferenze di utilizzo.

Hai l'opzione di disabilitare i cookie tramite le impostazioni del tuo browser, ma sii consapevole che alcune parti della Piattaforma potrebbero non funzionare ottimalmente senza di essi.

### 4. CONDIVISIONE E DIVULGAZIONE DI INFORMAZIONI

Non vendiamo né affittiamo informazioni personali a terzi. Potremmo condividere le tue informazioni con fornitori di servizi che ci assistono nell'operare la Piattaforma, come hosting, analisi e assistenza clienti, sotto stretti accordi di riservatezza. Questi fornitori sono obbligati a proteggere le tue informazioni e non sono autorizzati a utilizzarle per altri scopi.

### 5. I TUOI DIRITTI SULLA PRIVACY

A seconda della tua posizione, potresti avere diritti specifici riguardo le tue informazioni personali. Questi includono il diritto di accedere, correggere o cancellare le tue informazioni personali, così come il diritto di opporsi o limitare determinate attività di elaborazione. Se desideri esercitare uno di questi diritti, ti preghiamo di contattarci a team@studium.academy.

### 6. PROTEZIONE DEI DATI – RESIDENTI DELL'UE

Se risiedi nell'Unione Europea (UE), nello Spazio Economico Europeo (SEE) o nel Regno Unito (UK), hai diritti aggiuntivi sotto il Regolamento Generale sulla Protezione dei Dati (GDPR). Questi includono il diritto di richiedere l'accesso, la rettifica o la cancellazione dei tuoi dati personali, o di trasferire i tuoi dati a un altro fornitore di servizi.

### 7. RESIDENTI IN CALIFORNIA

Sotto la California Consumer Privacy Act (CCPA), i residenti in California hanno il diritto di richiedere informazioni sulla raccolta, l'uso e la condivisione dei loro dati personali. Studium non vende informazioni personali a terzi.

### 8. SICUREZZA DEI DATI

Implementiamo misure di sicurezza robuste per proteggere le tue informazioni personali da accessi non autorizzati, alterazione o distruzione. Tuttavia, nessun servizio basato su Internet può garantire sicurezza completa, quindi incoraggiamo gli utenti a prendere precauzioni per proteggere i propri dati personali.

### 9. MODIFICHE A QUESTA INFORMATIVA SULLA PRIVACY

Studium si riserva il diritto di modificare questa Informativa sulla Privacy in qualsiasi momento. Pubblicheremo eventuali modifiche sulla nostra Piattaforma, e ti incoraggiamo a rivedere periodicamente questa Informativa sulla Privacy.

### 10. CONTATTACI

Se hai domande o preoccupazioni riguardo la nostra Informativa sulla Privacy o le nostre pratiche di dati, ti preghiamo di contattarci a team@studium.academy.
`,
    },
    de: {
        privacyPolicyTitle: "Datenschutzrichtlinie",
        privacyPolicy: `
### 1. ÜBERSICHT

Studium ("Studium", "wir" oder "uns") verpflichtet sich, die Privatsphäre seiner Nutzer ("Sie" oder "Nutzer"), die auf unsere Bildungsplattformen und Anwendungen über https://studium.academy und verbundene Dienste (zusammengefasst die "Plattform") zugreifen, zu schützen.

Diese Datenschutzerklärung erläutert, wie wir Ihre persönlichen Informationen sammeln, verwenden und schützen, während Sie mit unserer Plattform interagieren. Wir halten uns an die geltenden Datenschutzgesetze in den Regionen, in denen wir tätig sind, einschließlich der Einhaltung der US-Bundes- und Landesgesetze.

### 2. ERHEBUNG UND VERWENDUNG PERSONENBEZOGENER DATEN

Wir sammeln persönliche Informationen direkt von unseren Nutzern, wenn diese mit unserer Plattform interagieren, einschließlich der Registrierung, Teilnahme an unseren Bildungskursen oder Kommunikation mit uns über Formulare und E-Mails. Diese Informationen können Ihren Namen, E-Mail-Adresse, Bildungshintergrund und andere Details, die Sie teilen möchten, umfassen.

Darüber hinaus erfassen wir automatisch bestimmte Daten von Ihrem Gerät, wenn Sie unsere Plattform nutzen, wie IP-Adressen, Browsertyp, Betriebssystem und Navigationsaktivitäten durch Cookies. Diese Informationen helfen uns, unsere Dienste zu verbessern und zu personalisieren, wodurch die Plattform benutzerfreundlicher und besser auf Ihre Bildungsbedürfnisse zugeschnitten wird.

### 3. COOKIE-RICHTLINIE

Studium verwendet Cookies, um Ihre Erfahrung auf unserer Plattform zu verbessern. Cookies sind kleine Datendateien, die auf Ihrem Gerät gespeichert werden und uns helfen, Ihre Präferenzen zu erinnern und Informationen über Ihre Interaktionen mit unserer Plattform zu sammeln. Dies umfasst Informationen über Ihre Nutzungsmuster und Präferenzen.

Sie haben die Möglichkeit, Cookies über die Einstellungen Ihres Browsers zu deaktivieren, aber beachten Sie, dass einige Teile der Plattform möglicherweise nicht optimal funktionieren, ohne sie.

### 4. WEITERGABE UND OFFENLEGUNG VON INFORMATIONEN

Wir verkaufen oder vermieten keine persönlichen Informationen an Dritte. Wir können Ihre Informationen mit Dienstleistern teilen, die uns beim Betrieb der Plattform helfen, wie z.B. Hosting, Analyse und Kundenservice, unter strengen Vertraulichkeitsvereinbarungen. Diese Dienstleister sind verpflichtet, Ihre Informationen zu schützen und dürfen diese nicht für andere Zwecke verwenden.

### 5. IHRE DATENSCHUTZRECHTE

Je nach Ihrem Standort haben Sie spezifische Rechte bezüglich Ihrer persönlichen Informationen. Dazu gehören das Recht auf Zugang, Korrektur oder Löschung Ihrer persönlichen Daten sowie das Recht, bestimmten Verarbeitungsaktivitäten zu widersprechen oder sie einzuschränken. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte unter team@studium.academy.

### 6. DATENSCHUTZ – EU-BÜRGER

Wenn Sie in der Europäischen Union (EU), dem Europäischen Wirtschaftsraum (EWR) oder im Vereinigten Königreich (UK) ansässig sind, haben Sie zusätzliche Rechte unter der Allgemeinen Datenschutzverordnung (GDPR). Dazu gehören das Recht auf Zugang, Berichtigung oder Löschung Ihrer personenbezogenen Daten oder die Übertragung Ihrer Daten an einen anderen Dienstleister.

### 7. KALIFORNIEN-BEWOHNER

Unter dem California Consumer Privacy Act (CCPA) haben Bewohner Kaliforniens das Recht, Informationen über die Sammlung, Verwendung und Weitergabe ihrer persönlichen Daten anzufordern. Studium verkauft keine persönlichen Informationen an Dritte.

### 8. DATENSICHERHEIT

Wir implementieren robuste Sicherheitsmaßnahmen, um Ihre persönlichen Informationen vor unbefugtem Zugriff, Änderung oder Zerstörung zu schützen. Jedoch kann kein internetbasierter Dienst vollständige Sicherheit garantieren, daher ermutigen wir die Nutzer, Vorsichtsmaßnahmen zu treffen, um ihre persönlichen Daten zu schützen.

### 9. ÄNDERUNGEN DIESER DATENSCHUTZERKLÄRUNG

Studium behält sich das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Wir werden jegliche Änderungen auf unserer Plattform veröffentlichen und ermutigen Sie, diese Datenschutzerklärung regelmäßig zu überprüfen.

### 10. KONTAKTIEREN SIE UNS

Wenn Sie Fragen oder Bedenken bezüglich unserer Datenschutzerklärung oder unserer Datenpraktiken haben, kontaktieren Sie uns bitte unter team@studium.academy.
`,
    },
};
