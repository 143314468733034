import { ItnLanguage, ItnMessagesType, ItnType } from "../utils/itn";

type Type = {
    termsOfServiceTitle: string;
    termsOfService: string;
};

export const itns: ItnType<Type> = {
    ptBr: {
        termsOfServiceTitle: "Termos de Serviço",
        termsOfService: `
Este Acordo de Termos de Uso ("Acordo"), juntamente com nossa Política de Privacidade da Empresa ([https://studium.academy/privacy](https://studium.academy/privacy)), constitui um acordo legalmente vinculativo entre você, pessoalmente ou em nome de uma entidade ("usuário" ou "você") e a Studium e suas empresas afiliadas, websites, aplicações e ferramentas (coletivamente, "Studium", "Empresa" ou "nós" ou "nosso"), referente ao seu acesso e uso dos websites [https://studium.academy](https://studium.academy) e quaisquer outras formas de mídia, canais de mídia, websites móveis ou aplicações móveis relacionados ou conectados a estes (coletivamente, os “Sites”). Os Sites oferecem os seguintes serviços: plataformas educacionais e de design, e serviços de hospedagem e marketing ("Serviços da Empresa"). Termos e condições suplementares ou documentos que possam ser publicados nos Sites de tempos em tempos são expressamente incorporados a este Acordo por referência.

A Empresa não garante que os Sites sejam apropriados ou disponíveis em locais diferentes daqueles onde são operados pela Empresa. As informações fornecidas nos Sites não são destinadas à distribuição ou uso por qualquer pessoa ou entidade em qualquer jurisdição ou país onde tal distribuição ou uso seria contrário à lei ou regulamentação ou que sujeitaria a Empresa a qualquer exigência de registro dentro de tal jurisdição ou país. Assim, aquelas pessoas que optam por acessar os Sites de outros locais fazem isso por iniciativa própria e são inteiramente responsáveis pelo cumprimento das leis locais, na medida em que tais leis sejam aplicáveis.

Todos os usuários que são menores de idade na jurisdição em que residem (geralmente menores de 18 anos) têm permissão para se registrar e usar os Sites sob supervisão parental ou do responsável legal. A Empresa incentiva os pais e responsáveis a orientar, supervisionar e discutir o uso dos Serviços da Empresa com seus filhos e pupilos.

VOCÊ ACEITA E CONCORDA EM ESTAR VINCULADO POR ESTE ACORDO AO RECONHECER TAL ACEITAÇÃO DURANTE O PROCESSO DE REGISTRAÇÃO (SE APLICÁVEL) E TAMBÉM AO CONTINUAR A USAR OS SITES. SE VOCÊ NÃO CONCORDA EM CUMPRIR ESTE ACORDO, OU COM AS MODIFICAÇÕES QUE A EMPRESA POSSA FAZER A ESTE ACORDO NO FUTURO, NÃO USE OU ACESSE OU CONTINUE A USAR OU ACESSAR OS SERVIÇOS DA EMPRESA OU OS SITES.

### COMPRAS E PAGAMENTOS

A Studium pode oferecer períodos de avaliação gratuita ou amostras de nossos produtos ou serviços. A duração do período de teste gratuito e todos os outros detalhes da oferta serão publicados em nossos Sites. Se desejar experimentar nossas opções gratuitas, por favor, leia todos os detalhes com atenção antes. A Studium faturará através de Stripe pelos nossos Serviços. Ao utilizar nossas opções pagas, você concorda em pagar à Studium todas as cobranças pelos preços vigentes para os produtos ou serviços que você ou outras pessoas usando sua conta de cobrança possam comprar, e você autoriza a Studium a cobrar de seu provedor de pagamento escolhido por tais compras. Você concorda em fazer o pagamento usando o método de pagamento selecionado. Se você encomendar um produto ou serviço sujeito a cobranças recorrentes, você concorda que podemos cobrar de seu método de pagamento de forma recorrente, sem necessidade de sua aprovação prévia para cada cobrança recorrente, até que você cancele o produto ou serviço aplicável. A Studium reserva-se o direito de corrigir quaisquer erros ou enganos nos preços mesmo que já tenha solicitado ou recebido o pagamento. Impostos sobre vendas serão adicionados ao preço de venda das compras conforme exigido pela Empresa. A Empresa pode alterar os preços a qualquer momento. Todos os pagamentos serão em dólares americanos.

### POLÍTICA DE REEMBOLSO E DEVOLUÇÃO

Studium entende que a educação é um compromisso significativo. Portanto, oferecemos uma política de reembolso limitada para garantir a satisfação e a flexibilidade do usuário. Os reembolsos podem ser solicitados dentro de 14 dias após a compra de qualquer curso ou serviço pago, contanto que o curso não tenha sido substancialmente consumido ou completado. Após este período, todas as vendas são finais e não serão emitidos reembolsos. Esta política é projetada para garantir que os instrutores sejam compensados justamente pelo tempo e recursos dedicados ao preparo dos cursos.

Para solicitar um reembolso, por favor, entre em contato com o suporte ao cliente através de nossa página de contato no site, fornecendo detalhes do produto adquirido e a razão para o pedido de reembolso.

### REPRESENTAÇÕES DO USUÁRIO

#### Sobre o Seu Registro

Ao usar os Serviços da Studium, você declara e garante que:

A. Todas as informações de registro que você fornece são verdadeiras e precisas;

B. Você manterá a precisão de tais informações;

C. Você manterá sua senha confidencial e será responsável por todo o uso de sua senha e conta;

D. Se você é menor de idade na jurisdição em que reside, recebeu permissão dos pais ou responsáveis legais para usar nossos Sites; e

E. Seu uso dos Serviços da Empresa não viola nenhuma lei ou regulamento aplicável.

Você também concorda em: (a) fornecer informações verdadeiras, precisas, atuais e completas sobre si mesmo conforme solicitado pelo formulário de registro dos Sites e (b) manter e atualizar prontamente os dados de registro para mantê-los verdadeiros, precisos, atuais e completos. Se você fornecer informações que são falsas, imprecisas, não atuais ou incompletas, ou se a Empresa tiver motivos razoáveis para suspeitar que tais informações são falsas, imprecisas, não atuais ou incompletas, a Empresa tem o direito de suspender ou encerrar sua conta e recusar qualquer uso atual ou futuro dos Sites (ou qualquer parte deles).

Reservamo-nos o direito de remover, recuperar ou alterar um nome de usuário que você selecionar se determinarmos ser apropriado a nosso critério, como quando o nome de usuário é obsceno ou de outra forma objetável ou quando um proprietário de marca reclama sobre um nome de usuário que não se relaciona de perto com o nome real do usuário.

#### Sobre o Conteúdo que Você Fornece

Podemos convidá-lo a participar de chats, blogs, fóruns de mensagens, fóruns online e outras funcionalidades e podemos fornecer a você a oportunidade de criar, enviar, postar, exibir, transmitir, executar, publicar, distribuir ou transmitir conteúdo e materiais para nossos Sites e/ou por meio de formulários dos Sites, e-mails, agentes de chat, pop-ups, incluindo, sem limitação, texto, escritos, vídeo, áudio, fotografias, gráficos, comentários, sugestões ou informações pessoalmente identificáveis ou outros materiais (coletivamente “Contribuições”). Qualquer Contribuição que você transmita para a Studium será tratada como não-confidencial e não-proprietária. Ao criar ou disponibilizar uma Contribuição, você declara e garante que:

A. A criação, distribuição, transmissão, exibição e execução públicas, acesso, download e cópia de sua Contribuição não infringirão os direitos de propriedade, incluindo, mas não limitado a, direitos autorais, patentes, marcas registradas, segredos comerciais ou direitos morais de terceiros;

B. Você é o criador e proprietário de ou possui as licenças necessárias, direitos, consentimentos, liberações e permissões para usar e autorizar a Studium e os usuários dos Sites a usar suas Contribuições conforme necessário para exercer as licenças concedidas por você sob este Acordo;

C. Você obteve o consentimento por escrito, liberação e/ou permissão de cada pessoa identificável individualmente em sua Contribuição para usar o nome ou a semelhança de cada pessoa identificável individualmente para permitir a inclusão e o uso da Contribuição da maneira contemplada pelos nossos Sites;

D. Sua Contribuição não é obscena, lasciva, suja, violenta, assediadora ou de outra forma objetável (conforme determinado pela Studium), difamatória ou caluniosa, não ridiculariza, zomba, menospreza, intimida ou abusa de ninguém, não defende a derrubada violenta de qualquer governo, não incita, encoraja ou ameaça dano físico contra outro, não viola qualquer lei, regulamento ou regra aplicável e não viola os direitos de privacidade ou publicidade de terceiros;

E. Sua Contribuição não contém material que solicita informações pessoais de qualquer pessoa menor de 18 anos ou explora pessoas menores de 18 anos de maneira sexual ou violenta, e não viola nenhuma lei federal ou estadual relacionada à pornografia infantil ou destinada a proteger a saúde ou o bem-estar de menores;

F. Sua Contribuição não inclui comentários ofensivos que estão relacionados à raça, origem nacional, gênero, preferência sexual ou deficiência física;

G. Sua Contribuição não viola, nem vincula a material que viola, qualquer disposição deste Acordo ou qualquer lei ou regulamento aplicável.

### LICENÇA DE CONTRIBUIÇÃO

Ao postar Contribuições em qualquer parte dos Sites, ou torná-las acessíveis aos Sites vinculando sua conta a qualquer uma de suas contas de redes sociais, você concede automaticamente, e declara e garante que tem o direito de conceder à Studium uma licença mundial, perpétua, não exclusiva, transferível, isenta de royalties e totalmente paga para hospedar, usar, copiar, reproduzir, divulgar, publicar, retransmitir, arquivar, armazenar, exibir publicamente, reformular, traduzir, transmitir e distribuir tais Contribuições para qualquer propósito, comercial, publicitário ou outro, para preparar obras derivadas ou incorporá-las em outras obras. A utilização e distribuição podem ocorrer em qualquer formato de mídia e através de qualquer canal de mídia. Esta licença de uso e distribuição aplicar-se-á a qualquer forma, mídia ou tecnologia, agora conhecida ou posteriormente desenvolvida, e inclui nosso uso de seu nome, nome da empresa, como aplicável, e quaisquer marcas registradas, marcas de serviço, nomes comerciais e logotipos, imagens pessoais e comerciais que você fornecer. A Studium não reivindica propriedade sobre suas Contribuições; em vez disso, entre nós e você, sujeito aos direitos concedidos a nós neste Acordo, você retém a propriedade plena de todas as suas Contribuições e quaisquer direitos de propriedade intelectual ou outros direitos proprietários associados a suas Contribuições. Não utilizaremos suas contribuições de maneira que infrinja seus direitos e sempre processaremos suas informações pessoais de forma legal e com seu consentimento.

A Studium tem o direito, a seu exclusivo e absoluto critério, de (i) editar, redigir ou alterar qualquer Contribuição, (ii) recategorizar qualquer Contribuição para colocá-la em locais mais apropriados ou (iii) pré-examinar ou excluir quaisquer Contribuições que sejam determinadas como inadequadas ou de outra forma em violação deste Acordo.

Ao fazer upload de suas Contribuições para os Sites, você autoriza a Studium a conceder a cada usuário final uma licença pessoal, limitada, intransferível, perpétua, não exclusiva, isenta de royalties e totalmente paga para acessar, baixar, imprimir e usar suas Contribuições para seus fins internos e não para distribuição, transferência, venda ou exploração comercial de qualquer tipo.

### DIRETRIZES PARA AVALIAÇÕES

A Studium pode aceitar, rejeitar ou remover avaliações a seu exclusivo critério. A Studium não tem absolutamente nenhuma obrigação de filtrar avaliações ou de deletá-las, mesmo que alguém considere as avaliações objetáveis ou imprecisas. As pessoas que postam avaliações devem cumprir os seguintes critérios: (1) os avaliadores devem ter experiência direta com a pessoa/entidade sendo avaliada; (2) as avaliações não devem conter: linguagem ofensiva, profanidade ou linguagem abusiva, racista ou de ódio; referências discriminatórias baseadas em religião, raça, gênero, origem nacional, idade, estado civil, orientação sexual ou deficiência; ou referências a atividades ilegais; (3) os avaliadores não devem estar afiliados a concorrentes se postarem avaliações negativas; (4) os avaliadores não devem tirar conclusões sobre a legalidade da conduta; e (5) os avaliadores não podem postar quaisquer declarações falsas ou organizar uma campanha encorajando outros a postar avaliações, sejam positivas ou negativas. As avaliações não são endossadas pela Studium e não representam as visões da Studium ou de qualquer afiliado ou parceiro da empresa. A Studium não assume responsabilidade por qualquer avaliação ou por quaisquer reivindicações, responsabilidades ou perdas resultantes de qualquer avaliação. Ao postar uma avaliação, o avaliador concede à Studium uma licença perpétua, não exclusiva, mundial, isenta de royalties, totalmente paga, atribuível e sublicenciável para reproduzir, modificar, traduzir, transmitir por qualquer meio, exibir, executar e/ou distribuir todo o conteúdo relacionado às avaliações.

### SUBMISSÕES

Você reconhece e concorda que quaisquer perguntas, comentários, sugestões, ideias, feedback ou outras informações sobre os Sites ou os Serviços da Studium ("Submissões") fornecidas por você à Studium são não-confidenciais e a Studium (assim como qualquer designado pela Empresa) terá o direito de usar e disseminar essas Submissões para qualquer propósito, comercial ou outro, sem reconhecimento ou compensação para você.

### ATIVIDADES PROIBIDAS

Você não pode acessar ou usar os Sites para qualquer outro propósito além daquele para o qual a Studium os disponibiliza. Os Sites não podem ser usados em conexão com empreendimentos comerciais, exceto aqueles especificamente endossados ou aprovados pela Studium. As atividades proibidas incluem, mas não estão limitadas a:

A. Tentar burlar quaisquer medidas dos Sites projetadas para prevenir ou restringir o acesso aos Sites ou qualquer parte dos Sites.
B. Tentar se passar por outro usuário ou pessoa ou usar o nome de usuário de outro usuário.
C. Atividade criminosa ou ilícita.
D. Decifrar, descompilar, desmontar ou reverter a engenharia de qualquer software que compõe ou de alguma forma faz parte dos Sites.
E. Excluir o aviso de direitos autorais ou outros direitos de propriedade de qualquer conteúdo dos Sites.
F. Envolver-se em qualquer uso automatizado do sistema, como usar ferramentas de mineração de dados, robôs ou ferramentas semelhantes de coleta e extração de dados.
G. Usar ou lançar qualquer sistema automatizado, incluindo, sem limitação, "spiders", "robots" (bots), utilitários de trapaça, scrapers ou leitores offline que acessam os Sites, ou usar ou lançar qualquer script ou software não autorizado.
H. Assediar, incomodar, intimidar ou ameaçar qualquer funcionário ou agente da Studium envolvido na prestação de qualquer parte dos Serviços da Empresa para você.
I. Interferir, interromper ou criar um ônus indevido nos Sites ou nas redes ou serviços conectados aos Sites.
J. Fazer qualquer uso não autorizado dos Serviços da Empresa, incluindo a coleta de nomes de usuários e/ou endereços de e-mail de usuários por meios eletrônicos ou outros para o envio de e-mails não solicitados, ou criar contas de usuário por meios automatizados ou sob pretextos falsos.
K. Vender ou transferir seu perfil.
L. Recuperar sistematicamente dados ou outro conteúdo dos Sites para criar ou compilar, direta ou indiretamente, uma coleção, compilação, banco de dados ou diretório sem permissão escrita da Empresa.
M. Enganar, fraudar ou induzir em erro a Studium e outros usuários, especialmente em qualquer tentativa de aprender informações sensíveis de conta, como senhas.
N. Usar qualquer informação obtida dos Sites para assediar, abusar ou prejudicar outra pessoa.
O. Usar os Serviços da Empresa como parte de qualquer esforço para competir com a Studium ou fornecer serviços como um bureau de serviços.
P. Usar os Sites de maneira inconsistente com todas as leis e regulamentos aplicáveis.

### DIREITOS DE PROPRIEDADE INTELECTUAL

O conteúdo nos Sites ("Conteúdo da Studium") e as marcas comerciais, marcas de serviço e logotipos contidos neles ("Marcas") são de propriedade ou licenciados para a Studium, e estão sujeitos a direitos autorais e outros direitos de propriedade intelectual sob leis dos Estados Unidos e estrangeiras e convenções internacionais. O Conteúdo da Studium inclui, sem limitação, todo o código-fonte, bancos de dados, funcionalidades, software, designs dos Sites, áudio, vídeo, texto, fotografias e gráficos. Todos os gráficos, logos, designs, cabeçalhos de página, ícones de botão, scripts e nomes de serviços da Studium são marcas registradas, marcas comerciais comuns ou imagem comercial da Studium nos Estados Unidos e/ou outros países. As marcas comerciais e a imagem comercial da Studium não podem ser usadas, incluindo como parte de marcas comerciais e/ou como parte de nomes de domínio, em conexão com qualquer produto ou serviço de maneira que possa causar confusão e não podem ser copiadas, imitadas ou usadas, integral ou parcialmente, sem a permissão escrita prévia da Studium.

O Conteúdo da Studium nos Sites é fornecido para você "COMO ESTÁ" para sua informação e uso pessoal apenas e não pode ser usado, copiado, reproduzido, agregado, distribuído, transmitido, exibido, vendido, licenciado ou de outra forma explorado para quaisquer outros fins sem o consentimento prévio por escrito dos respectivos proprietários. Desde que você seja elegível para usar os Sites, você recebe uma licença limitada para acessar e usar os Sites e o Conteúdo da Studium e para baixar ou imprimir uma cópia de qualquer parte do Conteúdo da Studium ao qual você tenha obtido acesso de forma adequada apenas para seu uso pessoal e não comercial. A Studium reserva todos os direitos não expressamente concedidos a você nos e para os Sites e o Conteúdo da Studium e Marcas.

### WEBSITES E CONTEÚDOS DE TERCEIROS

A plataforma Studium pode conter links para sites externos ("Websites de Terceiros") e incluir artigos, fotografias, textos, gráficos, vídeos, informações, aplicações, softwares e outros conteúdos ou itens de terceiros ("Conteúdo de Terceiros"). Estes não são verificados quanto à precisão, adequação ou completude pela Studium, e não somos responsáveis por Websites de Terceiros acessados através da nossa plataforma ou qualquer Conteúdo de Terceiros disponibilizado nela. A inclusão ou disponibilização desses links não implica aprovação pela Studium. O acesso a esses sites é feito por risco próprio do usuário, e a Studium não é responsável por quaisquer informações ou transações feitas nesses sites. Recomendamos que você revise as políticas de privacidade e termos de uso desses sites.

### GESTÃO DA PLATAFORMA

A Studium reserva-se o direito, mas não tem a obrigação de:

A. Monitorar a plataforma para violações deste Acordo;
B. Tomar ações legais apropriadas contra qualquer pessoa que viole este Acordo;
C. Recusar, restringir o acesso a, ou desabilitar qualquer contribuição de usuário que possa violar este Acordo ou políticas da Studium;
D. Gerenciar a plataforma de modo a proteger os direitos e a propriedade da Studium e de outros, e facilitar o funcionamento adequado da plataforma.

### TERMO E RESCISÃO

Este Acordo permanecerá em pleno vigor enquanto você utilizar a plataforma. Você pode terminar seu uso a qualquer momento, por qualquer motivo, seguindo as instruções para terminação de contas nas configurações da sua conta.

A Studium reserva o direito de, a seu exclusivo critério e sem aviso ou responsabilidade, negar o acesso e uso da plataforma a qualquer pessoa por qualquer motivo ou sem motivo algum, incluindo por violação de qualquer representação, garantia ou pacto contido neste Acordo ou de qualquer lei ou regulamento aplicável. A Studium pode também terminar seu uso ou participação na plataforma, excluir seu perfil e qualquer conteúdo ou informação que você tenha postado, a qualquer momento, sem aviso prévio.

Para proteger a integridade da plataforma, a Studium pode, a qualquer momento e a seu exclusivo critério, bloquear certos endereços IP de acessar a plataforma.

Este Acordo e todas as disposições que, para cumprir os propósitos de tais disposições, precisam sobreviver à rescisão ou expiração deste Acordo, serão consideradas como sobreviventes pelo tempo necessário para cumprir tais propósitos.

### DIREITOS DE PROPRIEDADE INTELECTUAL

O conteúdo da plataforma Studium, incluindo, sem limitação, todos os códigos-fonte, bancos de dados, funcionalidades, softwares, designs da plataforma, áudio, vídeo, texto, fotografias e gráficos são propriedade da Studium ou estão licenciados para ela, e estão sujeitos a direitos autorais e outros direitos de propriedade intelectual sob as leis dos Estados Unidos e leis internacionais. Você recebe uma licença limitada para acessar e usar a plataforma e seu conteúdo para fins pessoais, educacionais e não comerciais. A Studium reserva todos os direitos não expressamente concedidos neste Acordo.

### MODIFICAÇÕES

#### No Acordo

A Studium pode modificar este Acordo de tempos em tempos. Todas as mudanças neste Acordo serão publicadas na plataforma e as revisões serão indicadas pela data. Você concorda em estar vinculado a quaisquer mudanças neste Acordo quando utilizar os Serviços da Studium após qualquer modificação tornar-se efetiva. A Studium também pode, a seu critério, optar por alertar todos os usuários para os quais possui informações de e-mail sobre tais modificações por meio de um e-mail enviado ao endereço mais recentemente fornecido pelo usuário. É importante que você revise regularmente este Acordo e mantenha suas informações de contato atualizadas nas configurações da sua conta para garantir que você esteja informado sobre as mudanças.

#### Nos Serviços

A Studium reserva o direito a qualquer momento de modificar ou descontinuar, temporária ou permanentemente, os Serviços da Studium (ou qualquer parte deles) com ou sem aviso prévio. Você concorda que a Studium não será responsável perante você ou terceiros por qualquer modificação, suspensão ou interrupção dos Serviços.

### DISPUTAS

#### Entre Usuários

Se houver uma disputa entre usuários da plataforma, ou entre usuários e terceiros, você entende e concorda que a Studium não é obrigada a se envolver. Caso você tenha uma disputa com um ou mais outros usuários, você libera a Studium, seus oficiais, empregados, agentes e sucessores de direitos de reivindicações, demandas e danos de todo tipo e natureza, conhecidos e desconhecidos, decorrentes de ou de alguma forma relacionados a tais disputas e/ou aos Serviços da Studium.

#### Com a Studium

A. Lei Aplicável; Jurisdição. Este Acordo e todos os aspectos da plataforma e Serviços da Studium serão regidos e interpretados de acordo com as leis internas do Estado de Delaware, sem consideração a conflitos de disposições legais. Quanto a quaisquer disputas ou reivindicações não sujeitas a resolução informal ou arbitragem (como estabelecido abaixo), você concorda em não iniciar ou processar qualquer ação em conexão com isso em outro local que não seja os tribunais estaduais e federais localizados no Condado de New Castle, Estado de Delaware, e por este meio consente e renuncia a todas as defesas de falta de jurisdição pessoal e foro inadequado com respeito a local e jurisdição nesses tribunais estaduais e federais.

B. Resolução Informal. Para acelerar a resolução e controlar os custos de qualquer disputa, controvérsia ou reivindicação relacionada a este Acordo (“Disputa”), você e a Studium concordam em primeiro tentar negociar qualquer Disputa informalmente por pelo menos trinta (30) dias antes de iniciar qualquer procedimento de arbitragem ou judicial. Tais negociações informais começam com notificação escrita de uma parte para a outra.

C. Arbitragem Vinculativa. Se você e a Studium não conseguirem resolver uma Disputa por meio de negociações informais, qualquer uma das partes pode optar por resolver a Disputa final e exclusivamente por arbitragem vinculativa. A arbitragem será conduzida sob as Regras de Arbitragem Comercial da American Arbitration Association ("AAA") e, quando apropriado, os Procedimentos Suplementares da AAA para Disputas Relacionadas ao Consumidor, ambos disponíveis no site da AAA www.adr.org. A arbitragem pode ser conduzida pessoalmente, por meio da submissão de documentos, por telefone ou online.

D. Restrições. Você e a Studium concordam que qualquer arbitragem será limitada à Disputa entre a Studium e você individualmente. Na medida máxima permitida por lei, (1) nenhuma arbitragem será unida a outra; (2) não há direito ou autoridade para que qualquer Disputa seja arbitrada em uma base coletiva ou para utilizar procedimentos coletivos; e (3) não há direito ou autoridade para que qualquer Disputa seja proposta em uma capacidade representativa em nome do público geral ou quaisquer outras pessoas.

### DISPUTAS

#### Entre Usuários

Se ocorrer uma disputa entre usuários da plataforma, ou entre usuários e terceiros, você compreende e concorda que a Studium não é obrigada a se envolver. No caso de você ter uma disputa com um ou mais outros usuários, você por este meio isenta a Studium, seus funcionários, agentes e sucessores de direitos de quaisquer reivindicações, demandas e danos de todo tipo e natureza, conhecidos e desconhecidos, decorrentes de ou de alguma forma relacionados a tais disputas e/ou aos Serviços da Studium.

#### Com a Studium

A. Lei Aplicável; Jurisdição. Este Acordo e todos os aspectos da plataforma e dos Serviços da Studium serão regidos e interpretados de acordo com as leis internas do Estado de Delaware, sem considerar conflitos de disposições legais. Com respeito a quaisquer disputas ou reivindicações não sujeitas a resolução informal ou arbitragem (conforme definido abaixo), você concorda em não iniciar ou processar qualquer ação a não ser nos tribunais estaduais e federais localizados no Condado de New Castle, Estado de Delaware, e por este meio consente e renuncia a todas as defesas de falta de jurisdição pessoal e conveniência do foro com respeito a tais tribunais.

B. Resolução Informal. Para acelerar a resolução e controlar os custos de qualquer disputa relacionada a este Acordo, você e a Studium concordam em primeiro tentar negociar qualquer disputa de forma informal por pelo menos trinta (30) dias antes de iniciar qualquer procedimento de arbitragem ou judicial. As negociações informais começam com uma notificação escrita de uma parte à outra.

C. Arbitragem Vinculante. Se você e a Studium não conseguirem resolver uma disputa através de negociações informais, qualquer das partes pode optar por resolver a disputa final e exclusivamente por arbitragem vinculante. A arbitragem será realizada sob as Regras de Arbitragem Comercial da American Arbitration Association ("AAA") e, quando apropriado, os Procedimentos Suplementares da AAA para Disputas Relacionadas ao Consumidor, ambos disponíveis no site www.adr.org. A arbitragem pode ser conduzida pessoalmente, por meio da apresentação de documentos, por telefone ou online.

D. Restrições. Você e a Studium concordam que qualquer arbitragem será limitada à disputa entre a Studium e você individualmente. Na medida máxima permitida por lei, (1) nenhuma arbitragem será unida com outra; (2) não há direito ou autoridade para que qualquer disputa seja arbitrada em uma base de ação coletiva ou para utilizar procedimentos de ação coletiva; e (3) não há direito ou autoridade para que qualquer disputa seja trazida em uma capacidade representativa em nome do público geral ou de outras pessoas.

### CORREÇÕES

Ocasionalmente, pode haver informações na plataforma que contenham erros tipográficos, imprecisões ou omissões que possam estar relacionadas a descrições de serviços, preços, disponibilidade e outras informações diversas. A Studium reserva o direito de corrigir quaisquer erros, imprecisões ou omissões e de alterar ou atualizar informações a qualquer momento, sem aviso prévio.

### ISENÇÕES DE RESPONSABILIDADE

A Studium não pode controlar a natureza de todo o conteúdo disponibilizado na plataforma. Ao operar a plataforma, a Studium não representa nem implica que endossa quaisquer blogs, contribuições ou outros conteúdos disponíveis na plataforma ou vinculados por ela, incluindo conteúdos hospedados em websites de terceiros ou fornecidos por aplicações de terceiros, nem que acredita que tais contribuições ou conteúdos sejam precisos, úteis ou não prejudiciais. Não controlamos e não somos responsáveis por conteúdos ilegais ou de outra forma objetáveis que você possa encontrar na plataforma ou em conexão com qualquer contribuição. A Studium também não é responsável pelo comportamento, seja online ou offline, de qualquer usuário da plataforma.

VOCÊ CONCORDA QUE O USO DA PLATAFORMA E DOS SERVIÇOS DA STUDIUM SERÁ POR SUA CONTA E RISCO. NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, A STUDIUM, SEUS OFICIAIS, DIRETORES, EMPREGADOS E AGENTES ISENTAM TODAS AS GARANTIAS, EXPRESSAS OU IMPLÍCITAS, EM CONEXÃO COM A PLATAFORMA E O SEU USO, INCLUINDO, SEM LIMITAÇÃO, AS GARANTIAS IMPLÍCITAS DE COMERCIABILIDADE, ADEQUAÇÃO A UM PROPÓSITO ESPECÍFICO E NÃO VIOLAÇÃO. A STUDIUM NÃO FAZ GARANTIAS OU REPRESENTAÇÕES SOBRE A PRECISÃO OU COMPLETUDE DO CONTEÚDO DA PLATAFORMA OU DO CONTEÚDO DE QUAISQUER SITES VINCULADOS À NOSSA PLATAFORMA E NÃO ASSUME NENHUMA RESPONSABILIDADE POR QUALQUER (A) ERROS, EQUIVOCOS OU IMPRECISÕES DE CONTEÚDO E MATERIAIS, (B) DANOS PESSOAIS OU MATERIAIS, DE QUALQUER NATUREZA, RESULTANTES DO SEU ACESSO E USO DE NOSSA PLATAFORMA, (C) QUALQUER ACESSO NÃO AUTORIZADO OU USO DE NOSSOS SERVIDORES SEGUROS E/OU QUALQUER INFORMAÇÃO PESSOAL E/OU FINANCEIRA ARMAZENADA NELES, (D) QUALQUER INTERRUPÇÃO OU CESSAÇÃO DE TRANSMISSÃO PARA OU DA PLATAFORMA, (E) QUALQUER BUGS, VÍRUS, CAVALOS DE TRÓIA OU SIMILARES QUE POSSAM SER TRANSMITIDOS PARA OU ATRAVÉS DE NOSSA PLATAFORMA POR QUALQUER TERCEIRO, E/OU (F) QUALQUER ERRO OU OMISSÃO EM QUALQUER CONTEÚDO E MATERIAIS OU POR QUALQUER PERDA OU DANO DE QUALQUER TIPO INCORRIDO COMO RESULTADO DO USO DE QUALQUER CONTEÚDO POSTADO, TRANSMITIDO OU DE OUTRA FORMA DISPONIBILIZADO ATRAVÉS DA PLATAFORMA.

### LIMITAÇÕES DE RESPONSABILIDADE

EM NENHUM CASO A STUDIUM OU SEUS DIRETORES, EMPREGADOS OU AGENTES SERÃO RESPONSÁVEIS PERANTE VOCÊ OU QUALQUER TERCEIRO POR QUAISQUER DANOS DIRETOS, INDIRETOS, CONSEQUENCIAIS, EXEMPLARES, INCIDENTAIS, ESPECIAIS OU PUNITIVOS, INCLUINDO PERDA DE LUCRO, PERDA DE RECEITA, PERDA DE DADOS OU OUTROS DANOS DECORRENTES DE SEU USO DA PLATAFORMA OU SERVIÇOS DA STUDIUM, MESMO QUE A STUDIUM TENHA SIDO AVISADA DA POSSIBILIDADE DE TAIS DANOS. NÃO OBSTANTE QUALQUER COISA EM CONTRÁRIO CONTIDA AQUI, A RESPONSABILIDADE DA STUDIUM PARA COM VOCÊ POR QUALQUER CAUSA E INDEPENDENTEMENTE DA FORMA DA AÇÃO, SERÁ A TODO TEMPO LIMITADA AO MONTANTE PAGO, SE HOUVER, POR VOCÊ À STUDIUM PELOS SERVIÇOS DA STUDIUM DURANTE O PERÍODO DE TRÊS (3) MESES ANTES DE QUALQUER CAUSA DE AÇÃO SURGIR.

LEIS DE CERTOS ESTADOS NÃO PERMITEM LIMITAÇÕES SOBRE GARANTIAS IMPLÍCITAS OU A EXCLUSÃO OU LIMITAÇÃO DE CERTOS DANOS. SE ESTAS LEIS SE APLICAM A VOCÊ, ALGUMAS OU TODAS AS ISENÇÕES OU LIMITAÇÕES ACIMA PODEM NÃO SE APLICAR A VOCÊ, E VOCÊ PODE TER DIREITOS ADICIONAIS.

SE VOCÊ É RESIDENTE NA CALIFÓRNIA, VOCÊ RENUNCIA AO CÓDIGO CIVIL DA CALIFÓRNIA SEÇÃO 1542, QUE DIZ: “UMA LIBERAÇÃO GERAL NÃO SE ESTENDE A REIVINDICAÇÕES QUE O CREDOR NÃO CONHECE OU SUSPEITA EXISTIR EM SEU FAVOR NO MOMENTO DA EXECUÇÃO DA LIBERAÇÃO, QUE, SE CONHECIDAS POR ELE, DEVEM TER AFETADO MATERIALLY SEU ACORDO COM O DEVEDOR.”

### INDENIZAÇÃO

Você concorda em defender, indenizar e isentar a Studium, suas subsidiárias e afiliadas, e seus respectivos oficiais, agentes, parceiros e empregados, de e contra qualquer perda, dano, responsabilidade, reivindicação ou demanda, incluindo honorários advocatícios e despesas razoáveis, feitos por terceiros devido ou decorrentes do seu conteúdo contribuído, uso dos Serviços da Studium, e/ou decorrentes de uma violação deste Acordo e/ou qualquer violação de suas representações e garantias estabelecidas acima. Não obstante o anterior, a Studium reserva o direito, às suas custas, de assumir a defesa e controle exclusivos de qualquer questão para a qual você é obrigado a indenizar a Studium, e você concorda em cooperar, às suas custas, com a defesa da Studium dessas reivindicações. A Studium fará esforços razoáveis para notificá-lo de qualquer reivindicação, ação ou processo que seja objeto desta indenização assim que tomar conhecimento dele.

### NOTIFICAÇÕES

Exceto conforme explicitamente declarado de outra forma, quaisquer notificações enviadas à Studium devem ser feitas por e-mail para o endereço listado nas informações de contato abaixo. Quaisquer notificações enviadas a você serão dadas ao endereço de e-mail fornecido por você durante o processo de registro, ou a outro endereço que cada parte possa especificar. A notificação será considerada dada vinte e quatro (24) horas após o envio do e-mail, a menos que a parte remetente seja notificada de que o endereço de e-mail é inválido. Também podemos optar por enviar notificações por correio regular.

### DADOS DO USUÁRIO

Nossa plataforma manterá certos dados que você transferir para a plataforma para o propósito de desempenho dos Serviços da Studium, bem como dados relacionados ao seu uso dos Serviços da Studium. Embora realizemos backups regulares de dados, você é principalmente responsável por todos os dados que você transferiu ou que se relacionem a qualquer atividade que você tenha empreendido usando os Serviços da Studium. Você concorda que a Studium não terá nenhuma responsabilidade para com você por qualquer perda ou corrupção de tais dados, e você por este meio renuncia a qualquer direito de ação contra a Studium decorrente de tal perda ou corrupção desses dados.

### CONTRATAÇÃO ELETRÔNICA

Seu uso dos Serviços da Studium inclui a capacidade de entrar em acordos e/ou realizar transações eletronicamente. VOCÊ RECONHECE QUE SUAS SUBMISSÕES ELETRÔNICAS CONSTITUEM SEU ACORDO E INTENÇÃO DE SER VINCULADO POR E PAGAR POR TAIS ACORDOS E TRANSAÇÕES. SEU ACORDO E INTENÇÃO DE SER VINCULADO POR SUBMISSÕES ELETRÔNICAS APLICAM-SE A TODOS OS REGISTROS RELACIONADOS A TODAS AS TRANSAÇÕES QUE VOCÊ REALIZAR RELATIVAS AOS SERVIÇOS DA STUDIUM, INCLUINDO NOTIFICAÇÕES DE CANCELAMENTO, POLÍTICAS, CONTRATOS E APLICAÇÕES. Para acessar e reter seus registros eletrônicos, você pode ser obrigado a ter determinado hardware e software, que são de sua única responsabilidade.

### DIVERSOS

Este Acordo constitui o acordo integral entre você e a Studium em relação ao uso dos Serviços da Studium. A falha da Studium em exercer ou impor qualquer direito ou disposição deste Acordo não funcionará como uma renúncia de tal direito ou disposição. Os títulos de seção neste Acordo são apenas para conveniência e não têm efeito legal ou contratual. Este Acordo opera na máxima extensão permitida por lei. Você não pode ceder este Acordo e sua conta sem o consentimento expresso por escrito da Studium. A Studium pode ceder quaisquer ou todos os seus direitos e obrigações para outros a qualquer momento. A Studium não será responsável por qualquer perda, dano, atraso ou falha em agir causado por qualquer motivo além do controle razoável da Studium. Se qualquer disposição ou parte de uma disposição deste Acordo for considerada ilegal, nula ou inexequível, essa disposição ou parte da disposição é considerada separável deste Acordo e não afeta a validade e exequibilidade das demais disposições. Nenhuma joint venture, parceria, relação de emprego ou agência é criada entre você e a Studium como resultado deste Acordo ou uso dos Serviços da Studium. A pedido da Studium, você fornecerá à Studium qualquer documentação, comprovação ou liberações necessárias para verificar sua conformidade com este Acordo. Você concorda que este Acordo não será interpretado contra a Studium por ter sido a parte que o redigiu. Você renuncia a todas e quaisquer defesas que possa ter com base na forma eletrônica deste Acordo e na falta de assinatura das partes para executar este Acordo.

### CONTATO

Para resolver uma reclamação sobre os Serviços da Studium ou para receber mais informações sobre o uso dos Serviços da Studium, entre em contato com a Studium conforme definido abaixo, ou, se alguma reclamação conosco não for resolvida satisfatoriamente e você for residente na Califórnia, você pode contatar a Unidade de Assistência de Reclamações da Divisão de Serviços ao Consumidor do Departamento de Assuntos do Consumidor por escrito no endereço 400 “R” Street, Sacramento, Califórnia 95814 ou pelo telefone 1-916-445-1254.

Studium

Email: [team@studium.academy](mailto:team@studium.academy?subject=Terms%20of%20Service)
`,
    },
    ptEu: {
        termsOfServiceTitle: "Termos de Serviço",
        termsOfService: `
Este Acordo de Termos de Utilização ("Acordo"), juntamente com a nossa Política de Privacidade da Empresa ([https://studium.academy/privacy](https://studium.academy/privacy)), constitui um acordo legalmente vinculativo entre si, pessoalmente ou em nome de uma entidade ("utilizador" ou "você") e a Studium e as suas empresas afiliadas, websites, aplicações e ferramentas (coletivamente, "Studium", "Empresa" ou "nós" ou "nosso"), referente ao seu acesso e uso dos websites [https://studium.academy](https://studium.academy) e quaisquer outras formas de média, canais de média, websites móveis ou aplicações móveis relacionados ou ligados a estes (coletivamente, os "Sites"). Os Sites oferecem os seguintes serviços: plataformas educacionais e de design, e serviços de hospedagem e marketing ("Serviços da Empresa"). Termos e condições suplementares ou documentos que possam ser publicados nos Sites de tempos a tempos são expressamente incorporados neste Acordo por referência.

A Empresa não garante que os Sites sejam apropriados ou disponíveis em locais diferentes daqueles onde são operados pela Empresa. As informações fornecidas nos Sites não são destinadas à distribuição ou uso por qualquer pessoa ou entidade em qualquer jurisdição ou país onde tal distribuição ou uso seria contrário à lei ou regulamentação ou que sujeitaria a Empresa a qualquer exigência de registo dentro de tal jurisdição ou país. Assim, aquelas pessoas que optam por aceder aos Sites de outros locais fazem-no por iniciativa própria e são inteiramente responsáveis pelo cumprimento das leis locais, na medida em que tais leis sejam aplicáveis.

Todos os utilizadores que são menores de idade na jurisdição em que residem (geralmente menores de 18 anos) têm permissão para se registar e usar os Sites sob supervisão parental ou do responsável legal. A Empresa incentiva os pais e responsáveis a orientar, supervisionar e discutir o uso dos Serviços da Empresa com os seus filhos e pupilos.

VOCÊ ACEITA E CONCORDA EM ESTAR VINCULADO POR ESTE ACORDO AO RECONHECER TAL ACEITAÇÃO DURANTE O PROCESSO DE REGISTO (SE APLICÁVEL) E TAMBÉM AO CONTINUAR A USAR OS SITES. SE NÃO CONCORDA EM CUMPRIR ESTE ACORDO, OU COM AS MODIFICAÇÕES QUE A EMPRESA POSSA FAZER A ESTE ACORDO NO FUTURO, NÃO USE OU ACEDA OU CONTINUE A USAR OU ACEDER AOS SERVIÇOS DA EMPRESA OU AOS SITES.

### COMPRAS E PAGAMENTOS

A Studium pode oferecer períodos de avaliação gratuita ou amostras dos nossos produtos ou serviços. A duração do período de teste gratuito e todos os outros detalhes da oferta serão publicados nos nossos Sites. Se desejar experimentar as nossas opções gratuitas, por favor, leia todos os detalhes com atenção antes. A Studium faturará através de Stripe pelos nossos Serviços. Ao utilizar as nossas opções pagas, você concorda em pagar à Studium todas as cobranças pelos preços então em vigor para os produtos ou serviços que você ou outras pessoas usando a sua conta de cobrança possam comprar, e você autoriza a Studium a cobrar do seu fornecedor de pagamento escolhido por tais compras. Você concorda em fazer o pagamento usando o método de pagamento selecionado. Se encomendar um produto ou serviço sujeito a cobranças recorrentes, você concorda que podemos cobrar do seu método de pagamento de forma recorrente, sem necessidade de sua aprovação prévia para cada cobrança recorrente, até que cancele o produto ou serviço aplicável. A Studium reserva-se o direito de corrigir quaisquer erros ou enganos nos preços mesmo que já tenha solicitado ou recebido o pagamento. Impostos sobre vendas serão adicionados ao preço de venda das compras conforme exigido pela Empresa. A Empresa pode alterar os preços a qualquer momento. Todos os pagamentos serão em dólares americanos.

### POLÍTICA DE REEMBOLSO E DEVOLUÇÃO

A Studium entende que a educação é um compromisso

 significativo. Portanto, oferecemos uma política de reembolso limitada para garantir a satisfação e a flexibilidade do utilizador. Os reembolsos podem ser solicitados dentro de 14 dias após a compra de qualquer curso ou serviço pago, contanto que o curso não tenha sido substancialmente consumido ou completado. Após este período, todas as vendas são finais e não serão emitidos reembolsos. Esta política é projetada para garantir que os instrutores sejam compensados justamente pelo tempo e recursos dedicados à preparação dos cursos.

Para solicitar um reembolso, por favor, entre em contacto com o suporte ao cliente através da nossa página de contacto no site, fornecendo detalhes do produto adquirido e a razão para o pedido de reembolso.

### REPRESENTAÇÕES DO UTILIZADOR

#### Sobre o Seu Registo

Ao usar os Serviços da Studium, você declara e garante que:

A. Todas as informações de registo que você fornece são verdadeiras e precisas;

B. Você manterá a precisão de tais informações;

C. Você manterá a sua senha confidencial e será responsável por todo o uso da sua senha e conta;

D. Se você é menor de idade na jurisdição em que reside, recebeu permissão dos pais ou responsáveis legais para usar os nossos Sites; e

E. Seu uso dos Serviços da Empresa não viola nenhuma lei ou regulamento aplicável.

Você também concorda em: (a) fornecer informações verdadeiras, precisas, atuais e completas sobre si mesmo conforme solicitado pelo formulário de registo dos Sites e (b) manter e atualizar prontamente os dados de registo para mantê-los verdadeiros, precisos, atuais e completos. Se fornecer informações que são falsas, imprecisas, não atuais ou incompletas, ou se a Empresa tiver motivos razoáveis para suspeitar que tais informações são falsas, imprecisas, não atuais ou incompletas, a Empresa tem o direito de suspender ou encerrar a sua conta e recusar qualquer uso atual ou futuro dos Sites (ou qualquer parte deles).

Reservamo-nos o direito de remover, recuperar ou alterar um nome de utilizador que você selecionar se determinarmos ser apropriado ao nosso critério, como quando o nome de utilizador é obsceno ou de outra forma objetável ou quando um proprietário de marca reclama sobre um nome de utilizador que não se relaciona de perto com o nome real do utilizador.

#### Sobre o Conteúdo que Você Fornece

Podemos convidá-lo a participar em chats, blogs, fóruns de mensagens, fóruns online e outras funcionalidades e podemos fornecer-lhe a oportunidade de criar, submeter, postar, exibir, transmitir, executar, publicar, distribuir ou transmitir conteúdo e materiais para os nossos Sites e/ou por meio de formulários dos Sites, e-mails, agentes de chat, pop-ups, incluindo, sem limitação, texto, escritos, vídeo, áudio, fotografias, gráficos, comentários, sugestões ou informações pessoalmente identificáveis ou outros materiais (coletivamente “Contribuições”). Qualquer Contribuição que você transmita para a Studium será tratada como não-confidencial e não-proprietária. Ao criar ou disponibilizar uma Contribuição, você declara e garante que:

A. A criação, distribuição, transmissão, exibição e execução públicas, acesso, download e cópia da sua Contribuição não infringirão os direitos de propriedade, incluindo, mas não limitado a, direitos autorais, patentes, marcas registadas, segredos comerciais ou direitos morais de terceiros;

B. Você é o criador e proprietário de ou possui as licenças necessárias, direitos, consentimentos, liberações e permissões para usar e autorizar a Studium e os utilizadores dos Sites a usar as suas Contribuições conforme necessário para exercer as licenças concedidas por você sob este Acordo;

C. Você obteve o consentimento por escrito, liberação e/ou permissão de cada pessoa identificável individualmente na sua Contribuição para usar o nome ou a semelhança de cada pessoa identificável individualmente para permitir a inclusão e o uso da Contribuição da maneira contemplada pelos nossos Sites;

D

. Sua Contribuição não é obscena, lasciva, suja, violenta, assediadora ou de outra forma objetável (conforme determinado pela Studium), difamatória ou caluniosa, não ridiculariza, zomba, menospreza, intimida ou abusa de ninguém, não defende a derrubada violenta de qualquer governo, não incita, encoraja ou ameaça dano físico contra outro, não viola qualquer lei, regulamento ou regra aplicável e não viola os direitos de privacidade ou publicidade de terceiros;

E. Sua Contribuição não contém material que solicita informações pessoais de qualquer pessoa menor de 18 anos ou explora pessoas menores de 18 anos de maneira sexual ou violenta, e não viola nenhuma lei federal ou estadual relacionada à pornografia infantil ou destinada a proteger a saúde ou o bem-estar de menores;

F. Sua Contribuição não inclui comentários ofensivos que estão relacionados à raça, origem nacional, género, preferência sexual ou deficiência física;

G. Sua Contribuição não viola, nem vincula a material que viola, qualquer disposição deste Acordo ou qualquer lei ou regulamento aplicável.

### LICENÇA DE CONTRIBUIÇÃO

Ao postar Contribuições em qualquer parte dos Sites, ou torná-las acessíveis aos Sites vinculando a sua conta a qualquer uma das suas contas de redes sociais, você concede automaticamente, e declara e garante que tem o direito de conceder, à Studium uma licença mundial, perpétua, não exclusiva, transferível, isenta de royalties e totalmente paga para hospedar, usar, copiar, reproduzir, divulgar, publicar, retransmitir, arquivar, armazenar, exibir publicamente, reformular, traduzir, transmitir e distribuir tais Contribuições para qualquer propósito, comercial, publicitário ou outro, para preparar obras derivadas ou incorporá-las em outras obras. A utilização e distribuição podem ocorrer em qualquer formato de média e através de qualquer canal de média. Esta licença de uso e distribuição aplicar-se-á a qualquer forma, média ou tecnologia, agora conhecida ou posteriormente desenvolvida, e inclui o nosso uso do seu nome, nome da empresa, como aplicável, e quaisquer marcas registadas, marcas de serviço, nomes comerciais e logotipos, imagens pessoais e comerciais que você fornecer. A Studium não reivindica propriedade sobre as suas Contribuições; em vez disso, entre nós e você, sujeito aos direitos concedidos a nós neste Acordo, você retém a propriedade plena de todas as suas Contribuições e quaisquer direitos de propriedade intelectual ou outros direitos proprietários associados às suas Contribuições. Não utilizaremos as suas contribuições de maneira que infrinja os seus direitos e sempre processaremos as suas informações pessoais de forma legal e com o seu consentimento.

A Studium tem o direito, a seu exclusivo e absoluto critério, de (i) editar, redigir ou alterar qualquer Contribuição, (ii) recategorizar qualquer Contribuição para colocá-la em locais mais apropriados ou (iii) pré-examinar ou excluir quaisquer Contribuições que sejam determinadas como inadequadas ou de outra forma em violação deste Acordo.

Ao fazer upload das suas Contribuições para os Sites, você autoriza a Studium a conceder a cada utilizador final uma licença pessoal, limitada, intransferível, perpétua, não exclusiva, isenta de royalties e totalmente paga para acessar, baixar, imprimir e usar as suas Contribuições para os seus fins internos e não para distribuição, transferência, venda ou exploração comercial de qualquer tipo.

### DIRETRIZES PARA AVALIAÇÕES

A Studium pode aceitar, rejeitar ou remover avaliações a seu exclusivo critério. A Studium não tem absolutamente nenhuma obrigação de filtrar avaliações ou de apagá-las, mesmo que alguém considere as avaliações objetáveis ou imprecisas. As pessoas que postam avaliações devem cumprir os seguintes critérios: (1) os avaliadores devem ter experiência direta com a pessoa/entidade sendo avaliada; (2) as avaliações não devem conter: linguagem

 ofensiva, profanidade ou linguagem abusiva, racista ou de ódio; referências discriminatórias baseadas em religião, raça, género, origem nacional, idade, estado civil, orientação sexual ou deficiência; ou referências a atividades ilegais; (3) os avaliadores não devem estar afiliados a concorrentes se postarem avaliações negativas; (4) os avaliadores não devem tirar conclusões sobre a legalidade da conduta; e (5) os avaliadores não podem postar quaisquer declarações falsas ou organizar uma campanha encorajando outros a postar avaliações, sejam positivas ou negativas. As avaliações não são endossadas pela Studium e não representam as visões da Studium ou de qualquer afiliado ou parceiro da empresa. A Studium não assume responsabilidade por qualquer avaliação ou por quaisquer reivindicações, responsabilidades ou perdas resultantes de qualquer avaliação. Ao postar uma avaliação, o avaliador concede à Studium uma licença perpétua, não exclusiva, mundial, isenta de royalties, totalmente paga, atribuível e sublicenciável para reproduzir, modificar, traduzir, transmitir por qualquer meio, exibir, executar e/ou distribuir todo o conteúdo relacionado às avaliações.

### SUBMISSÕES

Você reconhece e concorda que quaisquer perguntas, comentários, sugestões, ideias, feedback ou outras informações sobre os Sites ou os Serviços da Studium ("Submissões") fornecidas por você à Studium são não-confidenciais e a Studium (assim como qualquer designado pela Empresa) terá o direito de usar e disseminar essas Submissões para qualquer propósito, comercial ou outro, sem reconhecimento ou compensação para você.

### ATIVIDADES PROIBIDAS

Você não pode acessar ou usar os Sites para qualquer outro propósito além daquele para o qual a Studium os disponibiliza. Os Sites não podem ser usados em conexão com empreendimentos comerciais, exceto aqueles especificamente endossados ou aprovados pela Studium. As atividades proibidas incluem, mas não estão limitadas a:

A. Tentar contornar quaisquer medidas dos Sites projetadas para prevenir ou restringir o acesso aos Sites ou qualquer parte dos Sites.
B. Tentar se passar por outro utilizador ou pessoa ou usar o nome de utilizador de outro utilizador.
C. Atividade criminosa ou ilícita.
D. Decifrar, descompilar, desmontar ou reverter a engenharia de qualquer software que compõe ou de alguma forma faz parte dos Sites.
E. Excluir o aviso de direitos autorais ou outros direitos de propriedade de qualquer conteúdo dos Sites.
F. Envolver-se em qualquer uso automatizado do sistema, como usar ferramentas de mineração de dados, robôs ou ferramentas semelhantes de coleta e extração de dados.
G. Usar ou lançar qualquer sistema automatizado, incluindo, sem limitação, "spiders", "robots" (bots), utilitários de trapaça, scrapers ou leitores offline que acessam os Sites, ou usar ou lançar qualquer script ou software não autorizado.
H. Assediar, incomodar, intimidar ou ameaçar qualquer funcionário ou agente da Studium envolvido na prestação de qualquer parte dos Serviços da Empresa para você.
I. Interferir, interromper ou criar um ônus indevido nos Sites ou nas redes ou serviços conectados aos Sites.
J. Fazer qualquer uso não autorizado dos Serviços da Empresa, incluindo a coleta de nomes de utilizadores e/ou endereços de e-mail de utilizadores por meios eletrónicos ou outros para o envio de e-mails não solicitados, ou criar contas de utilizador por meios automatizados ou sob pretextos falsos.
K. Vender ou transferir o seu perfil.
L. Recuperar sistematicamente dados ou outro conteúdo dos Sites para criar ou compilar, direta ou indiretamente, uma coleção, compilação, banco de dados ou diretório sem permissão escrita da Empresa.
M. Enganar, fraudar ou induzir em erro a Studium e outros utilizadores, especialmente em qualquer tentativa de aprender informações sensíveis de conta, como senhas.
N. Usar qualquer informação obtida dos

 Sites para assediar, abusar ou prejudicar outra pessoa.
O. Usar os Serviços da Empresa como parte de qualquer esforço para competir com a Studium ou fornecer serviços como um bureau de serviços.
P. Usar os Sites de maneira inconsistente com todas as leis e regulamentos aplicáveis.

### DIREITOS DE PROPRIEDADE INTELECTUAL

O conteúdo nos Sites ("Conteúdo da Studium") e as marcas comerciais, marcas de serviço e logotipos contidos neles ("Marcas") são de propriedade ou licenciados para a Studium, e estão sujeitos a direitos autorais e outros direitos de propriedade intelectual sob leis dos Estados Unidos e estrangeiras e convenções internacionais. O Conteúdo da Studium inclui, sem limitação, todo o código-fonte, bancos de dados, funcionalidades, software, designs dos Sites, áudio, vídeo, texto, fotografias e gráficos. Todos os gráficos, logos, designs, cabeçalhos de página, ícones de botão, scripts e nomes de serviços da Studium são marcas registadas, marcas comerciais comuns ou imagem comercial da Studium nos Estados Unidos e/ou outros países. As marcas comerciais e a imagem comercial da Studium não podem ser usadas, incluindo como parte de marcas comerciais e/ou como parte de nomes de domínio, em conexão com qualquer produto ou serviço de maneira que possa causar confusão e não podem ser copiadas, imitadas ou usadas, integral ou parcialmente, sem a permissão escrita prévia da Studium.

O Conteúdo da Studium nos Sites é fornecido para você "COMO ESTÁ" para sua informação e uso pessoal apenas e não pode ser usado, copiado, reproduzido, agregado, distribuído, transmitido, exibido, vendido, licenciado ou de outra forma explorado para quaisquer outros fins sem o consentimento prévio por escrito dos respectivos proprietários. Desde que você seja elegível para usar os Sites, você recebe uma licença limitada para acessar e usar os Sites e o Conteúdo da Studium e para baixar ou imprimir uma cópia de qualquer parte do Conteúdo da Studium ao qual você tenha obtido acesso de forma adequada apenas para seu uso pessoal e não comercial. A Studium reserva todos os direitos não expressamente concedidos a você nos e para os Sites e o Conteúdo da Studium e Marcas.

### WEBSITES E CONTEÚDOS DE TERCEIROS

A plataforma Studium pode conter links para sites externos ("Websites de Terceiros") e incluir artigos, fotografias, textos, gráficos, vídeos, informações, aplicações, softwares e outros conteúdos ou itens de terceiros ("Conteúdo de Terceiros"). Estes não são verificados quanto à precisão, adequação ou completude pela Studium, e não somos responsáveis por Websites de Terceiros acessados através da nossa plataforma ou qualquer Conteúdo de Terceiros disponibilizado nela. A inclusão ou disponibilização desses links não implica aprovação pela Studium. O acesso a esses sites é feito por risco próprio do utilizador, e a Studium não é responsável por quaisquer informações ou transações feitas nesses sites. Recomendamos que você revise as políticas de privacidade e termos de uso desses sites.

### GESTÃO DA PLATAFORMA

A Studium reserva-se o direito, mas não tem a obrigação de:

A. Monitorar a plataforma para violações deste Acordo;
B. Tomar ações legais apropriadas contra qualquer pessoa que viole este Acordo;
C. Recusar, restringir o acesso a, ou desabilitar qualquer contribuição de utilizador que possa violar este Acordo ou políticas da Studium;
D. Gerir a plataforma de modo a proteger os direitos e a propriedade da Studium e de outros, e facilitar o funcionamento adequado da plataforma.

### TERMO E RESCISÃO

Este Acordo permanecerá em pleno vigor enquanto você utilizar a plataforma. Você pode terminar o seu uso a qualquer momento, por qualquer motivo, seguindo as instruções para terminação de contas nas configurações da sua conta.

A Studium reserva o direito de, a seu exclusivo critério e sem aviso ou responsabilidade, negar o acesso e uso da plataforma a qualquer pessoa por qualquer motivo

 ou sem motivo algum, incluindo por violação de qualquer representação, garantia ou pacto contido neste Acordo ou de qualquer lei ou regulamento aplicável. A Studium pode também terminar o seu uso ou participação na plataforma, excluir o seu perfil e qualquer conteúdo ou informação que você tenha postado, a qualquer momento, sem aviso prévio.

Para proteger a integridade da plataforma, a Studium pode, a qualquer momento e a seu exclusivo critério, bloquear certos endereços IP de acessar a plataforma.

Este Acordo e todas as disposições que, para cumprir os propósitos de tais disposições, precisam sobreviver à rescisão ou expiração deste Acordo, serão consideradas como sobreviventes pelo tempo necessário para cumprir tais propósitos.

### DIREITOS DE PROPRIEDADE INTELECTUAL

O conteúdo da plataforma Studium, incluindo, sem limitação, todos os códigos-fonte, bancos de dados, funcionalidades, softwares, designs da plataforma, áudio, vídeo, texto, fotografias e gráficos são propriedade da Studium ou estão licenciados para ela, e estão sujeitos a direitos autorais e outros direitos de propriedade intelectual sob as leis dos Estados Unidos e leis internacionais. Você recebe uma licença limitada para acessar e usar a plataforma e seu conteúdo para fins pessoais, educacionais e não comerciais. A Studium reserva todos os direitos não expressamente concedidos neste Acordo.

### MODIFICAÇÕES

#### No Acordo

A Studium pode modificar este Acordo de tempos em tempos. Todas as mudanças neste Acordo serão publicadas na plataforma e as revisões serão indicadas pela data. Você concorda em estar vinculado a quaisquer mudanças neste Acordo quando utilizar os Serviços da Studium após qualquer modificação tornar-se efetiva. A Studium também pode, a seu critério, optar por alertar todos os utilizadores para os quais possui informações de e-mail sobre tais modificações por meio de um e-mail enviado ao endereço mais recentemente fornecido pelo utilizador. É importante que você revise regularmente este Acordo e mantenha as suas informações de contacto atualizadas nas configurações da sua conta para garantir que você esteja informado sobre as mudanças.

#### Nos Serviços

A Studium reserva o direito a qualquer momento de modificar ou descontinuar, temporária ou permanentemente, os Serviços da Studium (ou qualquer parte deles) com ou sem aviso prévio. Você concorda que a Studium não será responsável perante você ou terceiros por qualquer modificação, suspensão ou interrupção dos Serviços.

### DISPUTAS

#### Entre Utilizadores

Se houver uma disputa entre utilizadores da plataforma, ou entre utilizadores e terceiros, você entende e concorda que a Studium não é obrigada a se envolver. Caso você tenha uma disputa com um ou mais outros utilizadores, você libera a Studium, seus oficiais, empregados, agentes e sucessores de direitos de reivindicações, demandas e danos de todo tipo e natureza, conhecidos e desconhecidos, decorrentes de ou de alguma forma relacionados a tais disputas e/ou aos Serviços da Studium.

#### Com a Studium

A. Lei Aplicável; Jurisdição. Este Acordo e todos os aspectos da plataforma e Serviços da Studium serão regidos e interpretados de acordo com as leis internas do Estado de Delaware, sem consideração a conflitos de disposições legais. Quanto a quaisquer disputas ou reivindicações não sujeitas a resolução informal ou arbitragem (como estabelecido abaixo), você concorda em não iniciar ou processar qualquer ação em conexão com isso em outro local que não seja os tribunais estaduais e federais localizados no Condado de New Castle, Estado de Delaware, e por este meio consente e renuncia a todas as defesas de falta de jurisdição pessoal e foro inadequado com respeito a local e jurisdição nesses tribunais estaduais e federais.

B. Resolução Informal. Para acelerar a resolução e controlar os custos de qualquer disputa, controvérsia ou reivindicação relacionada a este Acordo (“Disputa”), você e a Studium concordam

 em primeiro tentar negociar qualquer Disputa informalmente por pelo menos trinta (30) dias antes de iniciar qualquer procedimento de arbitragem ou judicial. Tais negociações informais começam com notificação escrita de uma parte para a outra.

C. Arbitragem Vinculativa. Se você e a Studium não conseguirem resolver uma Disputa por meio de negociações informais, qualquer uma das partes pode optar por resolver a Disputa final e exclusivamente por arbitragem vinculativa. A arbitragem será conduzida sob as Regras de Arbitragem Comercial da American Arbitration Association ("AAA") e, quando apropriado, os Procedimentos Suplementares da AAA para Disputas Relacionadas ao Consumidor, ambos disponíveis no site da AAA www.adr.org. A arbitragem pode ser conduzida pessoalmente, por meio da submissão de documentos, por telefone ou online.

D. Restrições. Você e a Studium concordam que qualquer arbitragem será limitada à Disputa entre a Studium e você individualmente. Na medida máxima permitida por lei, (1) nenhuma arbitragem será unida a outra; (2) não há direito ou autoridade para que qualquer Disputa seja arbitrada em uma base coletiva ou para utilizar procedimentos coletivos; e (3) não há direito ou autoridade para que qualquer Disputa seja proposta em uma capacidade representativa em nome do público geral ou quaisquer outras pessoas.

### DISPUTAS

#### Entre Utilizadores

Se ocorrer uma disputa entre utilizadores da plataforma, ou entre utilizadores e terceiros, você compreende e concorda que a Studium não é obrigada a se envolver. No caso de você ter uma disputa com um ou mais outros utilizadores, você por este meio isenta a Studium, seus funcionários, agentes e sucessores de direitos de quaisquer reivindicações, demandas e danos de todo tipo e natureza, conhecidos e desconhecidos, decorrentes de ou de alguma forma relacionados a tais disputas e/ou aos Serviços da Studium.

#### Com a Studium

A. Lei Aplicável; Jurisdição. Este Acordo e todos os aspectos da plataforma e dos Serviços da Studium serão regidos e interpretados de acordo com as leis internas do Estado de Delaware, sem considerar conflitos de disposições legais. Com respeito a quaisquer disputas ou reivindicações não sujeitas a resolução informal ou arbitragem (conforme definido abaixo), você concorda em não iniciar ou processar qualquer ação a não ser nos tribunais estaduais e federais localizados no Condado de New Castle, Estado de Delaware, e por este meio consente e renuncia a todas as defesas de falta de jurisdição pessoal e conveniência do foro com respeito a tais tribunais.

B. Resolução Informal. Para acelerar a resolução e controlar os custos de qualquer disputa relacionada a este Acordo, você e a Studium concordam em primeiro tentar negociar qualquer disputa de forma informal por pelo menos trinta (30) dias antes de iniciar qualquer procedimento de arbitragem ou judicial. As negociações informais começam com uma notificação escrita de uma parte à outra.

C. Arbitragem Vinculante. Se você e a Studium não conseguirem resolver uma disputa através de negociações informais, qualquer das partes pode optar por resolver a disputa final e exclusivamente por arbitragem vinculativa. A arbitragem será realizada sob as Regras de Arbitragem Comercial da American Arbitration Association ("AAA") e, quando apropriado, os Procedimentos Suplementares da AAA para Disputas Relacionadas ao Consumidor, ambos disponíveis no site www.adr.org. A arbitragem pode ser conduzida pessoalmente, por meio da apresentação de documentos, por telefone ou online.

D. Restrições. Você e a Studium concordam que qualquer arbitragem será limitada à disputa entre a Studium e você individualmente. Na medida máxima permitida por lei, (1) nenhuma arbitragem será unida com outra; (2) não há direito ou autoridade para que qualquer disputa seja arbitrada em uma base de ação coletiva ou para utilizar procedimentos de ação coletiva; e (3) não há direito ou autoridade para que qualquer disputa seja trazida em

 uma capacidade representativa em nome do público geral ou de outras pessoas.

### CORREÇÕES

Ocasionalmente, pode haver informações na plataforma que contenham erros tipográficos, imprecisões ou omissões que possam estar relacionadas a descrições de serviços, preços, disponibilidade e outras informações diversas. A Studium reserva o direito de corrigir quaisquer erros, imprecisões ou omissões e de alterar ou atualizar informações a qualquer momento, sem aviso prévio.

### ISENÇÕES DE RESPONSABILIDADE

A Studium não pode controlar a natureza de todo o conteúdo disponibilizado na plataforma. Ao operar a plataforma, a Studium não representa nem implica que endossa quaisquer blogs, contribuições ou outros conteúdos disponíveis na plataforma ou vinculados por ela, incluindo conteúdos hospedados em websites de terceiros ou fornecidos por aplicações de terceiros, nem que acredita que tais contribuições ou conteúdos sejam precisos, úteis ou não prejudiciais. Não controlamos e não somos responsáveis por conteúdos ilegais ou de outra forma objetáveis que você possa encontrar na plataforma ou em conexão com qualquer contribuição. A Studium também não é responsável pelo comportamento, seja online ou offline, de qualquer utilizador da plataforma.

VOCÊ CONCORDA QUE O USO DA PLATAFORMA E DOS SERVIÇOS DA STUDIUM SERÁ POR SUA CONTA E RISCO. NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, A STUDIUM, SEUS OFICIAIS, DIRETORES, EMPREGADOS E AGENTES ISENTAM TODAS AS GARANTIAS, EXPRESSAS OU IMPLÍCITAS, EM CONEXÃO COM A PLATAFORMA E O SEU USO, INCLUINDO, SEM LIMITAÇÃO, AS GARANTIAS IMPLÍCITAS DE COMERCIABILIDADE, ADEQUAÇÃO A UM PROPÓSITO ESPECÍFICO E NÃO VIOLAÇÃO. A STUDIUM NÃO FAZ GARANTIAS OU REPRESENTAÇÕES SOBRE A PRECISÃO OU COMPLETUDE DO CONTEÚDO DA PLATAFORMA OU DO CONTEÚDO DE QUAISQUER SITES VINCULADOS À NOSSA PLATAFORMA E NÃO ASSUME NENHUMA RESPONSABILIDADE POR QUALQUER (A) ERROS, EQUIVOCOS OU IMPRECISÕES DE CONTEÚDO E MATERIAIS, (B) DANOS PESSOAIS OU MATERIAIS, DE QUALQUER NATUREZA, RESULTANTES DO SEU ACESSO E USO DE NOSSA PLATAFORMA, (C) QUALQUER ACESSO NÃO AUTORIZADO OU USO DE NOSSOS SERVIDORES SEGUROS E/OU QUALQUER INFORMAÇÃO PESSOAL E/OU FINANCEIRA ARMAZENADA NELES, (D) QUALQUER INTERRUPÇÃO OU CESSAÇÃO DE TRANSMISSÃO PARA OU DA PLATAFORMA, (E) QUALQUER BUGS, VÍRUS, CAVALOS DE TRÓIA OU SIMILARES QUE POSSAM SER TRANSMITIDOS PARA OU ATRAVÉS DE NOSSA PLATAFORMA POR QUALQUER TERCEIRO, E/OU (F) QUALQUER ERRO OU OMISSÃO EM QUALQUER CONTEÚDO E MATERIAIS OU POR QUALQUER PERDA OU DANO DE QUALQUER TIPO INCORRIDO COMO RESULTADO DO USO DE QUALQUER CONTEÚDO POSTADO, TRANSMITIDO OU DE OUTRA FORMA DISPONIBILIZADO ATRAVÉS DA PLATAFORMA.

### LIMITAÇÕES DE RESPONSABILIDADE

EM NENHUM CASO A STUDIUM OU SEUS DIRETORES, EMPREGADOS OU AGENTES SERÃO RESPONSÁVEIS PERANTE VOCÊ OU QUALQUER TERCEIRO POR QUAISQUER DANOS DIRETOS, INDIRETOS, CONSEQUENCIAIS, EXEMPLARES, INCIDENTAIS, ESPECIAIS OU PUNITIVOS, INCLUINDO PERDA DE LUCRO, PERDA DE RECEITA, PERDA DE DADOS OU OUTROS DANOS DECORRENTES DE SEU USO DA PLATAFORMA OU SERVIÇOS DA STUDIUM, MESMO QUE A STUDI

UM TENHA SIDO AVISADA DA POSSIBILIDADE DE TAIS DANOS. NÃO OBSTANTE QUALQUER COISA EM CONTRÁRIO CONTIDA AQUI, A RESPONSABILIDADE DA STUDIUM PARA COM VOCÊ POR QUALQUER CAUSA E INDEPENDENTEMENTE DA FORMA DA AÇÃO, SERÁ A TODO TEMPO LIMITADA AO MONTANTE PAGO, SE HOUVER, POR VOCÊ À STUDIUM PELOS SERVIÇOS DA STUDIUM DURANTE O PERÍODO DE TRÊS (3) MESES ANTES DE QUALQUER CAUSA DE AÇÃO SURGIR.

LEIS DE CERTOS ESTADOS NÃO PERMITEM LIMITAÇÕES SOBRE GARANTIAS IMPLÍCITAS OU A EXCLUSÃO OU LIMITAÇÃO DE CERTOS DANOS. SE ESTAS LEIS SE APLICAM A VOCÊ, ALGUMAS OU TODAS AS ISENÇÕES OU LIMITAÇÕES ACIMA PODEM NÃO SE APLICAR A VOCÊ, E VOCÊ PODE TER DIREITOS ADICIONAIS.

SE VOCÊ É RESIDENTE NA CALIFÓRNIA, VOCÊ RENUNCIA AO CÓDIGO CIVIL DA CALIFÓRNIA SEÇÃO 1542, QUE DIZ: “UMA LIBERAÇÃO GERAL NÃO SE ESTENDE A REIVINDICAÇÕES QUE O CREDOR NÃO CONHECE OU SUSPEITA EXISTIR EM SEU FAVOR NO MOMENTO DA EXECUÇÃO DA LIBERAÇÃO, QUE, SE CONHECIDAS POR ELE, DEVEM TER AFETADO MATERIALLY SEU ACORDO COM O DEVEDOR.”

### INDENIZAÇÃO

Você concorda em defender, indenizar e isentar a Studium, suas subsidiárias e afiliadas, e seus respectivos oficiais, agentes, parceiros e empregados, de e contra qualquer perda, dano, responsabilidade, reivindicação ou demanda, incluindo honorários advocatícios e despesas razoáveis, feitos por terceiros devido ou decorrentes do seu conteúdo contribuído, uso dos Serviços da Studium, e/ou decorrentes de uma violação deste Acordo e/ou qualquer violação de suas representações e garantias estabelecidas acima. Não obstante o anterior, a Studium reserva o direito, às suas custas, de assumir a defesa e controlo exclusivos de qualquer questão para a qual você é obrigado a indenizar a Studium, e você concorda em cooperar, às suas custas, com a defesa da Studium dessas reivindicações. A Studium fará esforços razoáveis para notificá-lo de qualquer reivindicação, ação ou processo que seja objeto desta indenização assim que tomar conhecimento dele.

### NOTIFICAÇÕES

Exceto conforme explicitamente declarado de outra forma, quaisquer notificações enviadas à Studium devem ser feitas por e-mail para o endereço listado nas informações de contacto abaixo. Quaisquer notificações enviadas a você serão dadas ao endereço de e-mail fornecido por você durante o processo de registo, ou a outro endereço que cada parte possa especificar. A notificação será considerada dada vinte e quatro (24) horas após o envio do e-mail, a menos que a parte remetente seja notificada de que o endereço de e-mail é inválido. Também podemos optar por enviar notificações por correio regular.

### DADOS DO USUÁRIO

Nossa plataforma manterá certos dados que você transferir para a plataforma para o propósito de desempenho dos Serviços da Studium, bem como dados relacionados ao seu uso dos Serviços da Studium. Embora realizemos backups regulares de dados, você é principalmente responsável por todos os dados que você transferiu ou que se relacionem a qualquer atividade que você tenha empreendido usando os Serviços da Studium. Você concorda que a Studium não terá nenhuma responsabilidade para com você por qualquer perda ou corrupção de tais dados, e você por este meio renuncia a qualquer direito de ação contra a Studium decorrente de tal perda ou corrupção desses dados.

### CONTRATAÇÃO ELETRÓNICA

Seu uso dos Serviços da Studium inclui a capacidade de entrar em acordos e/ou

 realizar transações eletronicamente. VOCÊ RECONHECE QUE SUAS SUBMISSÕES ELETRÓNICAS CONSTITUEM SEU ACORDO E INTENÇÃO DE SER VINCULADO POR E PAGAR POR TAIS ACORDOS E TRANSAÇÕES. SEU ACORDO E INTENÇÃO DE SER VINCULADO POR SUBMISSÕES ELETRÓNICAS APLICAM-SE A TODOS OS REGISTROS RELACIONADOS A TODAS AS TRANSAÇÕES QUE VOCÊ REALIZAR RELATIVAS AOS SERVIÇOS DA STUDIUM, INCLUINDO NOTIFICAÇÕES DE CANCELAMENTO, POLÍTICAS, CONTRATOS E APLICAÇÕES. Para acessar e reter seus registros eletrónicos, você pode ser obrigado a ter determinado hardware e software, que são de sua única responsabilidade.

### DIVERSOS

Este Acordo constitui o acordo integral entre você e a Studium em relação ao uso dos Serviços da Studium. A falha da Studium em exercer ou impor qualquer direito ou disposição deste Acordo não funcionará como uma renúncia de tal direito ou disposição. Os títulos de secção neste Acordo são apenas para conveniência e não têm efeito legal ou contratual. Este Acordo opera na máxima extensão permitida por lei. Você não pode ceder este Acordo e sua conta sem o consentimento expresso por escrito da Studium. A Studium pode ceder quaisquer ou todos os seus direitos e obrigações para outros a qualquer momento. A Studium não será responsável por qualquer perda, dano, atraso ou falha em agir causado por qualquer motivo além do controlo razoável da Studium. Se qualquer disposição ou parte de uma disposição deste Acordo for considerada ilegal, nula ou inexequível, essa disposição ou parte da disposição é considerada separável deste Acordo e não afeta a validade e exequibilidade das demais disposições. Nenhuma joint venture, parceria, relação de emprego ou agência é criada entre você e a Studium como resultado deste Acordo ou uso dos Serviços da Studium. A pedido da Studium, você fornecerá à Studium qualquer documentação, comprovação ou liberações necessárias para verificar sua conformidade com este Acordo. Você concorda que este Acordo não será interpretado contra a Studium por ter sido a parte que o redigiu. Você renuncia a todas e quaisquer defesas que possa ter com base na forma eletrónica deste Acordo e na falta de assinatura das partes para executar este Acordo.

### CONTATO

Para resolver uma reclamação sobre os Serviços da Studium ou para receber mais informações sobre o uso dos Serviços da Studium, entre em contacto com a Studium conforme definido abaixo, ou, se alguma reclamação conosco não for resolvida satisfatoriamente e você for residente na Califórnia, você pode contactar a Unidade de Assistência de Reclamações da Divisão de Serviços ao Consumidor do Departamento de Assuntos do Consumidor por escrito no endereço 400 “R” Street, Sacramento, Califórnia 95814 ou pelo telefone 1-916-445-1254.

Studium

Email: [team@studium.academy](mailto:team@studium.academy?subject=Terms%20of%20Service)
`,
    },
    en: {
        termsOfServiceTitle: "Terms of Service",
        termsOfService: `
This Terms of Use Agreement ("Agreement"), together with our Company Privacy Policy (https://studium.academy/privacy), constitutes a legally binding agreement between you, personally or on behalf of an entity ("User" or "you") and Studium and its affiliated companies, websites, applications and tools (collectively, "Studium", "Company" or "we" or "our"), regarding your access to and use of the websites https://studium.academy and any other related or connected forms of media, mobile websites or mobile applications (collectively, the "Sites"). The Sites offer the following services: educational and design platforms, and hosting and marketing services ("Company Services"). Supplementary terms and conditions or documents that may be posted on the Sites from time to time are expressly incorporated into this Agreement by reference.

The Company does not guarantee that the Sites are appropriate or available for use in locations other than those where it is operated by the Company. The information provided on the Sites is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject the Company to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Sites from other locations do so on their own initiative and are entirely responsible for compliance with local laws, if and to the extent local laws are applicable.

All users who are minors in the jurisdiction in which they reside (generally under the age of 18) are allowed to register for and use the Sites under parental or legal guardian supervision. The Company encourages parents and legal guardians to guide, supervise and discuss the use of the Company Services with their children and wards.

YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE DURING THE REGISTRATION PROCESS (IF APPLICABLE) AND ALSO BY CONTINUING TO USE THE SITES. IF YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT, OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE COMPANY SERVICES OR THE SITES.

### PURCHASES AND PAYMENTS

Studium may offer free trial periods or samples of our products or services. The duration of the free trial and all other offer details will be posted on our Sites. If you wish to try our free options, please read all details carefully beforehand. Studium will bill through Stripe for our Services. By using our paid options, you agree to pay Studium all charges at in effect for products or services you or others using your billing account may purchase, and you authorize Studium to charge your chosen payment provider for any such purchases. You agree to make payment using your selected payment method. If you have ordered a product or service that is subject to recurring charges, you consent to our charging your payment method on a recurring basis, without requiring your prior approval from you for each recurring charge, until you cancel the applicable product or service. Studium reserves the right to correct any errors or mistakes in pricing even if it has already requested or received payment. Sales tax will be added to purchase price as deemed required by Company. The Company may change prices at any time. All payments shall be in U.S. dollars.

### REFUND AND RETURN POLICY

Studium understands that education is a significant commitment. Therefore, we offer a limited refund policy to ensure user satisfaction and flexibility. Refunds may be requested within 14 days of any paid course or service purchase, provided the course has not been substantially consumed or completed. After this period, all sales are final and no refunds will be issued. This policy is designed to ensure instructors are fairly compensated for time and resources invested in preparing courses.

To request a refund, please contact customer support via our contact page on the site, providing details of product purchased and reason for refund request.

### USER REPRESENTATIONS

#### On Your Registration

By using Studium's Services, you represent and warrant that:

A. All registration information you submit is truthful and accurate;

B. You will maintain the accuracy of such information;

C. You will keep your password confidential and will be responsible for all use of your password and account;

D. If you are a minor in the jurisdiction in which you reside, you have received parental or legal guardian permission to use our Sites; and

E. Your use of the Company Services does not violate any applicable law or regulation.

You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Sites' registration form and (b) maintain and promptly update registration data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Company has the right to suspend or terminate your account and refuse any current or future use of the Sites (or any portion thereof).

We reserve the right to remove, reclaim or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable or when a trademark owner complains about a username that does not closely relate to a user's actual name.

#### On Content You Provide

We may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to our Sites and/or to or through the Sites, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Any Contribution you transmit to Studium will be treated as non-confidential and non-proprietary. By creating or making available a Contribution, you represent and warrant that:

A. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contribution does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party;

B. You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize Studium and the Sites' users to use your Contributions as necessary to exercise the licenses granted by you under this Agreement;

C. You have the written consent, release, and/or permission of each and every identifiable individual person in your Contribution to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the Contribution in the manner contemplated by Sites;

D. Your Contribution is not obscene, lewd, lascivious, filthy, violent, harassing, or otherwise objectionable (as determined by Studium), libelous or slanderous, does not ridicule, mock, disparage, intimidate, or abuse anyone, does not advocate the violent overthrow of any government, does not incite, encourage, or threaten physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;

E. Your Contribution does not contain material that solicits personal information from anyone under 18 or exploit people under the age of 18 in a sexual or violent manner, and does not violate any federal or state law concerning child pornography or otherwise intended to protect the health or well-being of minors;

F. Your Contribution does not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap;

G. Your Contribution does not otherwise violate, or link to material that violates, any provision of this Agreement, or any applicable law or regulation.

### CONTRIBUTION LICENSE

By posting Contributions to any part of the Sites, or making them accessible to the Sites by linking your account to any of your social network accounts, you automatically grant, and you represent and warrant that you have the right to grant, to Studium a worldwide, perpetual, non-exclusive, transferable, royalty-free and fully-paid right and license to host, use, copy, reproduce, disclose, publish, retransmit, archive, store, cache, publicly perform, reformat, translate, transmit, and distribute such Contributions for any purpose, commercial, advertising, or otherwise, for preparing derivative works, or incorporating into other works. The use and distribution may occur in any media formats and through any media channels. This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, as applicable, and any of the trademarks, service marks, trade names and logos, personal and commercial images you provide. Studium does not assert any ownership over your Contributions; rather, subject to the rights granted to us in this Agreement, as between us and you, you retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We will not use your contributions in a way that infringes your rights and we will always process your personal information lawfully and with your consent.

Studium has the right, in our sole and absolute discretion, to (i) edit, redact, or otherwise change any Contributions, (ii) re-categorize any Contribution to place them in more appropriate locations, or (iii) pre-screen or delete any Contributions that are determined to be inappropriate or otherwise in violation of this Agreement.

By uploading your Contributions to the Sites, you hereby grant each end-user a personal, limited, non-transferable, perpetual, non-exclusive, royalty-free, fully-paid license to access, download, print, and otherwise use your Contributions for their internal purposes and not for distribution, transfer, sale or commercial exploitation of any kind.

### GUIDELINES FOR REVIEWS

Studium may accept, reject, or remove reviews in its sole discretion. Studium has absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Those persons posting reviews should comply with the following criteria: (1) reviewers should have firsthand experience with the person/entity being reviewed; (2) reviews should not contain: offensive language, profanity, or abusive, racist, or hate language; discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability; or references to illegal activity; (3) reviewers should not be affiliated with competitors if posting negative reviews; (4) reviewers should not make any conclusions as to the legality of conduct; and (5) reviewers may not post any false statements or organize a campaign encouraging others to post reviews, whether positive or negative. Reviews are not endorsed by Studium, and do not represent the views of Studium or of any affiliate or partner of the Company. Studium does not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, the reviewer hereby grants to Studium a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.

### SUBMISSIONS

You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Sites or the Company Services ("Submissions") provided by you to Studium are non-confidential and Studium (as well as any designee of Company) shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.

### PROHIBITED ACTIVITIES

You may not access or use the Sites for any purpose other than that for which Studium makes it available. The Sites may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by Studium. Prohibited activities include, but are not limited to:

A. attempting to bypass any measures of the Sites designed to prevent or restrict access to the Sites, or any portion of the Sites;

B. attempting to impersonate another user or person or using the username of another user;

C. criminal or tortious activity;

D. deciphering, decompiling, disassembling or reverse engineering any of the software comprising or in any way making up a part of the Sites;

E. deleting the copyright or other proprietary rights notice from any Content;

F. engaging in any automated use of the system, such as using any data mining, robots or similar data gathering and extraction tools;

G. using or launching any unauthorized script or other software;

H. harassing, annoying, intimidating or threatening any Company employees or agents engaged in providing any portion of the Company Services to you;

I. interfering with, disrupting, or creating an undue burden on the Sites or the networks or services connected to the Sites;

J. making any unauthorized use of the Company Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses;

K. selling or otherwise transferring your profile;

L. systematic retrieval of data or other content from the Sites to create or compile, directly or indirectly, a collection, compilation, database or directory without written permission from Company;

M. tricking, defrauding or misleading Company and other users, especially in any attempt to learn sensitive account information such as passwords;

N. using any information obtained from the Sites in order to harass, abuse, or harm another person;

O. using the Company Services as part of any effort to compete with Company or to provide services as a service bureau;

P. using the Sites in a manner inconsistent with any and all applicable laws and regulations.

### INTELLECTUAL PROPERTY RIGHTS

The content on the Sites ("Studium Content") and the trademarks, service marks and logos contained therein ("Marks") are owned by or licensed to Studium, and are subject to copyright and other intellectual property rights under United States and foreign laws and international conventions. Studium Content includes without limitation all source code, databases, functionality, software, website designs, audio, video, text, photographs and graphics. All graphics, logos, designs, page headers, button icons, scripts and service names of Studium are registered trademarks, common law trademarks or trade dress of Studium in the United States and/or other countries. Studium's trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of Studium.

Studium Content on the Sites is provided to you "AS IS" for your information and personal use only and may not be used, copied, reproduced, aggregated, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. Provided that you are eligible to use the Sites, you are granted a limited license to access and use the Sites and to download or print a copy of any portion of the Studium Content to which you have properly gained access solely for your personal, non-commercial use. Studium reserves all rights not expressly granted to you in and to the Sites and Studium Content and Marks.

### THIRD PARTY WEBSITES AND CONTENT

The Studium platform may contain links to external websites ("Third Party Websites") as well as articles, photographs, text, graphics, videos, information, applications, software and other content or items belonging to or originating from third parties ("Third Party Content"). These are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Websites accessed through our platform or any Third Party Content posted on it. Inclusion or availability of these links does not imply approval or endorsement by Studium. Accessing them is at your own risk, and Studium is not responsible for any information or transactions made on those sites. We recommend that you review the privacy policies and terms of use of those sites.

### PLATFORM MANAGEMENT

Studium reserves the right, but does not have the obligation, to:

A. monitor the platform for violations of this Agreement;

B. take appropriate legal action against anyone who violates this Agreement;

C. refuse, restrict access to or disable any user contribution that may violate this Agreement or Studium's policies;

D. manage the platform in a manner designed to protect the rights and property of Studium and others and to facilitate the proper functioning of the platform.

### TERM AND TERMINATION

This Agreement shall remain in full force and effect while you use the platform. You may terminate your use at any time, for any reason, by following the instructions for terminating accounts in your account settings.

Studium reserves the right to, in its sole discretion and without notice or liability, deny access to and use of the platform to any person for any reason or for no reason at all, including without limitation for breach of any representation, warranty or covenant contained in this Agreement, or of any applicable law or regulation.  Studium may also terminate your use or participation in the platform, delete your profile and any content or information that you have posted at any time, without warning.

In order to protect the integrity of the platform, Studium reserves the right at any time in its sole discretion to block certain IP addresses from accessing the platform.

This Agreement and any provisions that, to fulfill the purposes of such provisions, need to survive termination or expiration of this Agreement, shall be deemed to survive for as long as necessary to fulfill such purposes.

### INTELLECTUAL PROPERTY RIGHTS

The content on the Studium platform, including without limitation any source code, databases, functionality, software, platform designs, audio, video, text, photographs, and graphics is the property of Studium or licensed to it, and is subject to copyright and other intellectual property rights under United States and international laws. You receive a limited license to access and use the platform and its content for personal, educational, and non-commercial purposes. Studium reserves all rights not expressly granted in this Agreement.

### MODIFICATIONS

#### To the Agreement

Studium may modify this Agreement from time to time. Any and all changes to this Agreement will be posted on the platform and revisions will be indicated by date. You agree to be bound to any changes to this Agreement when you use the Studium Services after any such modification becomes effective. Studium may also, in its discretion, choose to alert all users with whom it maintains email information of such modifications by means of an email to their most recently provided email address. It is therefore important that you regularly review this Agreement and keep your contact information current in your account settings to ensure you are informed of changes.

#### To the Services

Studium reserves the right at any time to modify or discontinue, temporarily or permanently, the Studium Services (or any part thereof) with or without notice. You agree that Studium shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Services.

### DISPUTES

#### Between Users

If there is a dispute between users of the platform, or between users and any third party, you understand and agree that Studium is under no obligation to become involved. In the event that you have a dispute with one or more other users, you hereby release Studium, its officers, employees, agents and successors in rights from claims, demands and damages (actual and consequential) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or the Studium Services.

#### With Studium

A. Governing Law; Jurisdiction. This Agreement and all aspects of the platform and Studium Services shall be governed by and construed in accordance with the internal laws of the State of Delaware, without regard to conflict of law provisions. With respect to any disputes or claims not subject to informal resolution or arbitration (as set forth below), you agree not to commence or prosecute any action in connection therewith other than in the state and federal courts located in New Castle County, State of Delaware, and you hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to, venue and jurisdiction in such state and federal courts.

B. Informal Resolution. To expedite resolution and control the cost of any dispute, controversy or claim related to this Agreement ("Dispute"), you and Studium agree to first attempt to negotiate any Dispute informally for at least thirty (30) days before initiating any arbitration or court proceeding. Such informal negotiations commence upon written notice from one party to the other.

C. Binding Arbitration. If you and Studium are unable to resolve a Dispute through informal negotiations, either party may elect to resolve the Dispute finally and exclusively by binding arbitration. Any arbitration will be conducted by the American Arbitration Association (AAA) under its Commercial Arbitration Rules and, in the case of consumer disputes, the AAA's Supplementary Procedures for Consumer Related Disputes. The arbitration may be conducted in person, through the submission of documents, by phone or online.

D. Restrictions. You and Studium agree that any arbitration shall be limited to the Dispute between Studium and you individually. To the full extent permitted by law, (1) no arbitration shall be joined with any other; (2) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (3) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.

### CORRECTIONS

Occasionally there may be information on the platform that contains typographical errors, inaccuracies or omissions that may relate to service descriptions, pricing, availability, and various other information. Studium reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any time, without prior notice.

### DISCLAIMERS

Studium cannot control the nature of all of the content available on the platform. By operating the platform, Studium does not represent or imply that it endorses any blogs, contributions or other content available on or linked to by the platform, including without limitation content hosted on third party websites or provided by third party applications, or that it believes such contributions or content to be accurate, useful or non-harmful. We do not control and are not responsible for unlawful or otherwise objectionable content you may encounter on the platform or in connection with any contributions. Studium is not responsible for the conduct, whether online or offline, of any user of the platform.

YOU AGREE THAT YOUR USE OF THE PLATFORM AND STUDIUM SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, STUDIUM, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND THE USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. STUDIUM MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE PLATFORM'S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO OUR PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR PLATFORM, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR PLATFORM BY ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM.

### LIMITATIONS OF LIABILITY

IN NO EVENT SHALL STUDIUM, ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA OR OTHER DAMAGES ARISING FROM YOUR USE OF THE PLATFORM OR STUDIUM SERVICES, EVEN IF STUDIUM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, STUDIUM'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO STUDIUM FOR THE STUDIUM SERVICES DURING THE PERIOD OF THREE (3) MONTHS PRIOR TO ANY CAUSE OF ACTION ARISING.

CERTAIN LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.

IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.

### INDEMNITY

You agree to defend, indemnify and hold harmless Studium, its subsidiaries and affiliates, and their respective officers, agents, partners and employees, from and against any loss, damage, liability, claim or demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of your contributed content, use of the Studium Services, and/or arising from a breach of this Agreement and/or any breach of your representations and warranties set forth above. Notwithstanding the foregoing, Studium reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Company, and you agree to cooperate, at your expense, with Company's defense of such claims. Company will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.

### NOTICES

Except as explicitly stated otherwise, any notices shall be given by email to the address listed in the contact information below. Any notices given to you will be delivered to the email address you provided during the registration process, or to another email address that either party may specify. Notice shall be deemed given twenty-four (24) hours after the email is sent, unless the sending party is notified that the email address is invalid. We may also elect to send notices by regular mail.

### USER DATA

Our platform will maintain certain data that you transfer to the platform for the purpose of the performance of the Studium Services, as well as data relating to your use of the Studium Services. Although we perform regular routine backups of data, you are primarily responsible for all data that you have transferred or that relates to any activity you have undertaken using the Studium Services. You agree that Studium shall have no responsibility to you for the loss or corruption of any such data, and you hereby waive any right of action against Studium arising from any such loss or corruption of such data.

### ELECTRONIC CONTRACTING

Your use of the Studium Services includes the ability to enter into agreements and/or to make transactions electronically. YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND BY FOR SUCH AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU ENTER INTO RELATING TO THE STUDIUM SERVICES, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS. In order to access and retain your electronic records, you may be required to have certain hardware and software, which are your sole responsibility.

### MISCELLANEOUS

This Agreement constitutes the entire agreement between you and Studium regarding the use of the Studium Services. The failure of Studium to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. This Agreement operates to the fullest extent permissible by law. You may not assign this Agreement and your account without the express written consent of Studium. Studium may assign any or all of its rights and obligations to others at any time. Studium shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond Company's reasonable control. If any provision or part of a provision of this Agreement is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from this Agreement and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and Studium as a result of this Agreement or use of the Studium Services. Upon Company's request, you will furnish Company any documentation, substantiation or releases necessary to verify your compliance with this Agreement. You agree that this Agreement will not be construed against Studium by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of this Agreement and the lack of signing by the parties hereto to execute this Agreement.

### CONTACT

To resolve a complaint regarding the Studium Services or to receive further information regarding use of the Studium Services, please contact Studium as set forth below or, if any complaint with us is not satisfactorily resolved, and you are a California resident, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the Department of Consumer Affairs in writing at 400 "R" Street, Sacramento, California 95814 or by telephone at 1-916-445-1254.

Studium

Email: [team@studium.academy](mailto:team@studium.academy?subject=Terms%20of%20Service)
`,
    },
    es: {
        termsOfServiceTitle: "Términos de Servicio",
        termsOfService: `
Este Acuerdo de Términos de Uso ("Acuerdo"), junto con nuestra Política de Privacidad de la Empresa ([https://studium.academy/privacy](https://studium.academy/privacy)), constituye un acuerdo legalmente vinculante entre usted, personalmente o en nombre de una entidad ("usuario" o "usted") y Studium y sus empresas afiliadas, sitios web, aplicaciones y herramientas (colectivamente, "Studium", "Empresa" o "nosotros" o "nuestro"), con respecto a su acceso y uso de los sitios web [https://studium.academy](https://studium.academy) y cualquier otra forma de medios, canales de medios, sitios web móviles o aplicaciones móviles relacionados o conectados a estos (colectivamente, los "Sitios"). Los Sitios ofrecen los siguientes servicios: plataformas educativas y de diseño, y servicios de alojamiento y marketing ("Servicios de la Empresa"). Los términos y condiciones suplementarios o documentos que puedan publicarse en los Sitios de vez en cuando están expresamente incorporados a este Acuerdo por referencia.

La Empresa no garantiza que los Sitios sean apropiados o estén disponibles en lugares distintos de donde son operados por la Empresa. La información proporcionada en los Sitios no está destinada a la distribución o uso por ninguna persona o entidad en cualquier jurisdicción o país donde dicha distribución o uso sería contrario a la ley o regulación o que sujetaría a la Empresa a cualquier requisito de registro dentro de dicha jurisdicción o país. Por lo tanto, aquellas personas que opten por acceder a los Sitios desde otros lugares lo hacen por su propia iniciativa y son totalmente responsables de cumplir con las leyes locales, en la medida en que dichas leyes sean aplicables.

Todos los usuarios que son menores de edad en la jurisdicción en la que residen (generalmente menores de 18 años) tienen permiso para registrarse y usar los Sitios bajo supervisión parental o del tutor legal. La Empresa alienta a los padres y tutores a orientar, supervisar y discutir el uso de los Servicios de la Empresa con sus hijos y pupilos.

USTED ACEPTA Y ACUERDA ESTAR VINCULADO POR ESTE ACUERDO AL RECONOCER TAL ACEPTACIÓN DURANTE EL PROCESO DE REGISTRO (SI APLICA) Y TAMBIÉN AL CONTINUAR USANDO LOS SITIOS. SI USTED NO ACEPTA CUMPLIR CON ESTE ACUERDO, O CON LAS MODIFICACIONES QUE LA EMPRESA PUEDA HACER A ESTE ACUERDO EN EL FUTURO, NO USE O ACCEDA O CONTINÚE USANDO O ACCEDIENDO LOS SERVICIOS DE LA EMPRESA O LOS SITIOS.

### COMPRAS Y PAGOS

Studium puede ofrecer períodos de prueba gratuita o muestras de nuestros productos o servicios. La duración del período de prueba gratuita y todos los demás detalles de la oferta se publicarán en nuestros Sitios. Si desea probar nuestras opciones gratuitas, por favor, lea todos los detalles con atención antes. Studium facturará a través de Stripe por nuestros Servicios. Al utilizar nuestras opciones pagas, usted acepta pagar a Studium todos los cargos por los precios vigentes para los productos o servicios que usted u otras personas que usen su cuenta de facturación puedan comprar, y usted autoriza a Studium a cargar a su proveedor de pago elegido por dichas compras. Usted acepta realizar el pago utilizando el método de pago seleccionado. Si ha pedido un producto o servicio sujeto a cargos recurrentes, acepta que podemos cargar su método de pago de manera recurrente, sin necesidad de su aprobación previa para cada cargo recurrente hasta que cancele el producto o servicio aplicable. Studium se reserva el derecho de corregir cualquier error o equivocación en los precios incluso si ya ha solicitado o recibido el pago. Impuestos sobre las ventas se agregarán al precio de venta de las compras según lo requiera la Empresa. La Empresa puede cambiar los precios en cualquier momento. Todos los pagos serán en dólares estadounidenses.

### POLÍTICA DE REEMBOLSO Y DEVOLUCIÓN

Studium entiende que la educación es un compromiso significativo. Por lo tanto, ofrecemos una política de reembolso limitada para aseg

urar la satisfacción y la flexibilidad del usuario. Los reembolsos pueden solicitarse dentro de los 14 días siguientes a la compra de cualquier curso o servicio pagado, siempre que el curso no haya sido sustancialmente consumido o completado. Después de este período, todas las ventas son finales y no se emitirán reembolsos. Esta política está diseñada para asegurar que los instructores sean compensados justamente por el tiempo y recursos dedicados a la preparación de los cursos.

Para solicitar un reembolso, por favor, póngase en contacto con el soporte al cliente a través de nuestra página de contacto en el sitio, proporcionando detalles del producto comprado y la razón para la solicitud de reembolso.

### REPRESENTACIONES DEL USUARIO

#### Sobre su Registro

Al usar los Servicios de Studium, usted declara y garantiza que:

A. Toda la información de registro que proporciona es verdadera y precisa;
B. Mantendrá la precisión de dicha información;
C. Mantendrá su contraseña confidencial y será responsable de todo el uso de su contraseña y cuenta;
D. Si usted es menor de edad en la jurisdicción en la que reside, ha recibido permiso de sus padres o tutores legales para usar nuestros Sitios; y
E. Su uso de los Servicios de la Empresa no viola ninguna ley o regulación aplicable.

Usted también acepta: (a) proporcionar información verdadera, precisa, actual y completa sobre usted mismo según lo solicitado por el formulario de registro de los Sitios y (b) mantener y actualizar rápidamente los datos de registro para mantenerlos verdaderos, precisos, actuales y completos. Si proporciona información que es falsa, inexacta, no actual o incompleta, o si la Empresa tiene motivos razonables para sospechar que tal información es falsa, inexacta, no actual o incompleta, la Empresa tiene el derecho de suspender o terminar su cuenta y rechazar cualquier uso actual o futuro de los Sitios (o cualquier parte de ellos).

Nos reservamos el derecho de remover, recuperar o cambiar un nombre de usuario que seleccione si determinamos que es apropiado a nuestro criterio, como cuando el nombre de usuario es obsceno o de otra manera objetable o cuando un propietario de marca se queja de un nombre de usuario que no se relaciona estrechamente con el nombre real del usuario.

#### Sobre el Contenido que Usted Proporciona

Podemos invitarlo a participar en chats, blogs, foros de mensajes, foros en línea y otras funcionalidades y podemos proporcionarle la oportunidad de crear, enviar, publicar, mostrar, transmitir, realizar, publicar, distribuir o transmitir contenido y materiales a nuestros Sitios y/o a través de formularios de los Sitios, correos electrónicos, agentes de chat, pop-ups, incluyendo, sin limitación, texto, escritos, video, audio, fotografías, gráficos, comentarios, sugerencias o información personalmente identificable u otros materiales (colectivamente "Contribuciones"). Cualquier Contribución que transmita a Studium será tratada como no confidencial y no propietaria. Al crear o disponer una Contribución, usted declara y garantiza que:

A. La creación, distribución, transmisión, exhibición y ejecución públicas, acceso, descarga y copia de su Contribución no infringirán los derechos de propiedad, incluyendo pero no limitado a derechos de autor, patentes, marcas registradas, secretos comerciales o derechos morales de terceros;
B. Usted es el creador y propietario de o tiene las licencias necesarias, derechos, consentimientos, liberaciones y permisos para usar y autorizar a Studium y a los usuarios de los Sitios a usar sus Contribuciones según sea necesario para ejercer las licencias otorgadas por usted bajo este Acuerdo;
C. Usted ha obtenido el consentimiento por escrito, liberación y/o permiso de cada persona identificable individualmente en su Contribución para usar el nombre o la semejanza de cada persona identificable individualmente para permitir la inclusión y el uso de la Contribución en la manera contemplada por nuestros Sitios;
D. Su Contribución no es obscena, lasciva, sucia, violenta, hostigadora o de otra manera objetable (según lo determinado por Studium), difamatoria o calumniosa, no ridiculiza, se burla, denigra, intimida o abusa de nadie,

 no aboga por el derrocamiento violento de ningún gobierno, no incita, anima o amenaza daño físico contra otro, no viola ninguna ley, regulación o regla aplicable y no viola los derechos de privacidad o publicidad de terceros;
E. Su Contribución no contiene material que solicita información personal de cualquier persona menor de 18 años o explota a personas menores de 18 años de manera sexual o violenta, y no viola ninguna ley federal o estatal relativa a la pornografía infantil o destinada a proteger la salud o el bienestar de menores;
F. Su Contribución no incluye comentarios ofensivos que estén relacionados con la raza, origen nacional, género, preferencia sexual o discapacidad física;
G. Su Contribución no viola, ni vincula material que viole, ninguna disposición de este Acuerdo o cualquier ley o regulación aplicable.

### LICENCIA DE CONTRIBUCIÓN

Al publicar Contribuciones en cualquier parte de los Sitios, o al hacerlas accesibles a los Sitios al vincular su cuenta a cualquiera de sus cuentas en redes sociales, usted otorga automáticamente, y declara y garantiza que tiene derecho a otorgar, a Studium una licencia mundial, perpetua, no exclusiva, transferible, libre de regalías y totalmente pagada para hospedar, usar, copiar, reproducir, revelar, vender, revender, publicar, transmitir, retitular, archivar, almacenar, cachear, realizar públicamente, exhibir públicamente, reformatear, traducir, transmitir, extraer (en todo o en parte) y distribuir dichas Contribuciones (incluyendo, sin limitación, su imagen y voz) para cualquier propósito, comercial, publicitario, o de otro tipo, para preparar obras derivadas de, o incorporar en otras obras, dichas Contribuciones, y para conceder y autorizar sublicencias de lo anterior. El uso y distribución pueden ocurrir en cualquier formato de medios y a través de cualquier canal de medios. Tal licencia de uso y distribución se aplicará a cualquier forma, medios o tecnología ahora conocidos o desarrollados posteriormente, e incluye nuestro uso de su nombre, nombre de la empresa, como sea aplicable, y cualquier de las marcas comerciales, marcas de servicio, nombres comerciales y logotipos, imágenes personales y comerciales que usted proporcione. Studium no reclama ninguna propiedad sobre sus Contribuciones; en cambio, entre nosotros y usted, sujeto a los derechos otorgados a nosotros en este Acuerdo, usted retiene la propiedad plena de todas sus Contribuciones y cualquier derecho de propiedad intelectual u otros derechos de propiedad asociados con sus Contribuciones. No utilizaremos sus contribuciones de manera que infrinja sus derechos y siempre procesaremos su información personal de manera legal y con su consentimiento.

Studium tiene el derecho, a su entera y absoluta discreción, de (i) editar, redactar o cambiar de otro modo cualquier Contribución, (ii) recategorizar cualquier Contribución para colocarla en ubicaciones más apropiadas o (iii) pre-examinar o eliminar cualquier Contribución que se determine como inapropiada o de otra manera en violación de este Acuerdo.

Al subir sus Contribuciones a los Sitios, usted autoriza a Studium a otorgar a cada usuario final una licencia personal, limitada, intransferible, perpetua, no exclusiva, libre de regalías y totalmente pagada para acceder, descargar, imprimir y utilizar sus Contribuciones para sus fines internos y no para distribución, transferencia, venta o explotación comercial de cualquier tipo.

### DIRECTRICES PARA REVISIONES

Studium puede aceptar, rechazar o eliminar revisiones a su sola discreción. Studium no tiene absolutamente ninguna obligación de filtrar revisiones o de eliminar revisiones, incluso si alguien considera que las revisiones son objetables o inexactas. Las personas que publican revisiones deben cumplir con los siguientes criterios: (1) los revisores deben tener experiencia directa con la persona/entidad que está siendo revisada; (2) las revisiones no deben contener: lenguaje ofensivo, profanidad o lenguaje abusivo, racista o de odio; referencias discriminatorias basadas en religión, raza, género, origen nacional, edad, estado civil, orientación sexual o discapacidad; o referencias a actividades ilegales; (3) los revisores no deben estar afiliados con competidores si publican

 revisiones negativas; (4) los revisores no deben sacar conclusiones sobre la legalidad de la conducta; y (5) los revisores no pueden publicar ninguna declaración falsa ni organizar una campaña alentando a otros a publicar revisiones, ya sean positivas o negativas. Las revisiones no son endosadas por Studium, y no representan las opiniones de Studium o de cualquier afiliado o socio de la empresa. Studium no asume responsabilidad por ninguna revisión ni por cualquier reclamación, responsabilidad o pérdidas resultantes de cualquier revisión. Al publicar una revisión, el revisor otorga a Studium una licencia perpetua, no exclusiva, mundial, libre de regalías, completamente pagada, asignable y sublicenciable para reproducir, modificar, traducir, transmitir por cualquier medio, mostrar, realizar y/o distribuir todo el contenido relacionado con las revisiones.

### ENVÍOS

Usted reconoce y acepta que cualquier pregunta, comentario, sugerencia, idea, retroalimentación u otra información sobre los Sitios o los Servicios de Studium ("Envíos") proporcionados por usted a Studium son no confidenciales y Studium (así como cualquier designado por la Empresa) tendrán el derecho de usar y diseminar esos Envíos para cualquier propósito, comercial o de otro tipo, sin reconocimiento o compensación para usted.

### ACTIVIDADES PROHIBIDAS

Usted no puede acceder ni usar los Sitios para ningún otro propósito que no sea aquel para el cual Studium los pone a disposición. Los Sitios no pueden ser usados en conexión con emprendimientos comerciales, excepto aquellos específicamente endosados o aprobados por Studium. Las actividades prohibidas incluyen, pero no están limitadas a:

A. Intentar eludir cualquier medida de los Sitios diseñada para prevenir o restringir el acceso a los Sitios, o cualquier parte de los Sitios.
B. Intentar hacerse pasar por otro usuario o persona o usar el nombre de usuario de otro usuario.
C. Actividad delictiva o tortuosa.
D. Descifrar, descompilar, desmontar o revertir la ingeniería de cualquier software que compone o de alguna manera forma parte de los Sitios.
E. Eliminar el aviso de derechos de autor u otros derechos de propiedad de cualquier contenido de los Sitios.
F. Participar en cualquier uso automatizado del sistema, como usar herramientas de minería de datos, robots u otras herramientas similares de recopilación y extracción de datos.
G. Usar o lanzar cualquier sistema automatizado, incluyendo, sin limitación, "spiders", "robots" (bots), utilidades de trampa, raspadores o lectores fuera de línea que accedan a los Sitios, o usar o lanzar cualquier script o software no autorizado.
H. Hostigar, molestar, intimidar o amenazar a cualquier empleado o agente de Studium involucrado en proporcionar cualquier parte de los Servicios de la Empresa a usted.
I. Interferir, interrumpir o crear una carga indebida en los Sitios o en las redes o servicios conectados a los Sitios.
J. Hacer cualquier uso no autorizado de los Servicios de la Empresa, incluyendo la recolección de nombres de usuario y/o direcciones de correo electrónico de usuarios por medios electrónicos u otros para el envío de correo electrónico no solicitado, o crear cuentas de usuario por medios automatizados o bajo pretextos falsos.
K. Vender o transferir su perfil.
L. Recuperar sistemáticamente datos u otro contenido de los Sitios para crear o compilar, directa o indirectamente, una colección, compilación, base de datos o directorio sin permiso escrito de la Empresa.
M. Engañar, defraudar o engañar a Studium y otros usuarios, especialmente en cualquier intento de aprender información sensible de la cuenta, como contraseñas.
N. Usar cualquier información obtenida de los Sitios para acosar, abusar o dañar a otra persona.
O. Usar los Servicios de la Empresa como parte de cualquier esfuerzo por competir con Studium o proporcionar servicios como un buró de servicios.
P. Usar los Sitios de una manera inconsistente con cualquier y todas las leyes y regulaciones aplicables.

### DERECHOS DE PROPIEDAD INTELECTUAL

El contenido en los Sitios ("Contenido de Studium") y las marcas comerciales, marcas de servicio y logotipos contenidos en ellos ("

Marcas") son propiedad de o están licenciados a Studium, y están sujetos a derechos de autor y otros derechos de propiedad intelectual bajo leyes de los Estados Unidos y leyes extranjeras y convenios internacionales. El Contenido de Studium incluye, sin limitación, todo el código fuente, bases de datos, funcionalidad, software, diseños de los Sitios, audio, video, texto, fotografías y gráficos. Todos los gráficos, logotipos, diseños, encabezados de página, íconos de botones, scripts y nombres de servicios de Studium son marcas registradas, marcas comerciales comunes o imagen comercial de Studium en los Estados Unidos y/o otros países. Las marcas comerciales e imagen comercial de Studium no pueden ser usadas, incluyendo como parte de marcas comerciales y/o como parte de nombres de dominio, en conexión con cualquier producto o servicio de manera que pueda causar confusión y no pueden ser copiadas, imitadas o usadas, en su totalidad o en parte, sin el permiso previo por escrito de Studium.

El Contenido de Studium en los Sitios se proporciona a usted "TAL CUAL" para su información y uso personal solamente y no puede ser usado, copiado, reproducido, agregado, distribuido, transmitido, exhibido, vendido, licenciado o de otra manera explotado para cualquier otro propósito sin el consentimiento previo por escrito de los respectivos propietarios. Siempre que sea elegible para usar los Sitios, se le otorga una licencia limitada para acceder y usar los Sitios y el Contenido de Studium y para descargar o imprimir una copia de cualquier parte del Contenido de Studium al que haya accedido adecuadamente solo para su uso personal, no comercial. Studium reserva todos los derechos no expresamente otorgados a usted en y para los Sitios y el Contenido de Studium y Marcas.

### SITIOS WEB Y CONTENIDOS DE TERCEROS

La plataforma Studium puede contener enlaces a sitios web externos ("Sitios Web de Terceros") e incluir artículos, fotografías, textos, gráficos, videos, información, aplicaciones, software y otros contenidos o elementos de terceros ("Contenido de Terceros"). Estos no son verificados en cuanto a precisión, adecuación o completitud por Studium, y no somos responsables por Sitios Web de Terceros accedidos a través de nuestra plataforma o cualquier Contenido de Terceros disponible en ella. La inclusión o disponibilidad de estos enlaces no implica la aprobación por parte de Studium. El acceso a estos sitios se realiza bajo su propio riesgo del usuario, y Studium no es responsable por ninguna información o transacción realizada en esos sitios. Le recomendamos que revise las políticas de privacidad y términos de uso de esos sitios.

### GESTIÓN DE LA PLATAFORMA

Studium se reserva el derecho, pero no tiene la obligación de:

A. Monitorear la plataforma para violaciones de este Acuerdo;
B. Tomar acciones legales apropiadas contra cualquier persona que viole este Acuerdo;
C. Rechazar, restringir el acceso a, o deshabilitar cualquier contribución de usuario que pueda violar este Acuerdo o políticas de Studium;
D. Administrar la plataforma de manera diseñada para proteger los derechos y la propiedad de Studium y otros, y facilitar el funcionamiento adecuado de la plataforma.

### TÉRMINO Y TERMINACIÓN

Este Acuerdo permanecerá en pleno vigor mientras usted utilice la plataforma. Usted puede terminar su uso en cualquier momento, por cualquier razón, siguiendo las instrucciones para terminación de cuentas en las configuraciones de su cuenta.

Studium se reserva el derecho de, a su sola discreción y sin aviso o responsabilidad, negar el acceso y uso de la plataforma a cualquier persona por cualquier razón o sin razón alguna, incluyendo por violación de cualquier representación, garantía o pacto contenido en este Acuerdo o de cualquier ley o regulación aplicable. Studium también puede terminar su uso o participación en la plataforma, eliminar su perfil y cualquier contenido o información que haya publicado, en cualquier momento, sin aviso previo.

Para proteger la integridad de la plataforma, Studium puede, en cualquier momento y a su sola discreción, bloquear ciertas direcciones IP de acceder a la plataforma.

Este Acuerdo y todas las disposiciones que, para cumplir los propósitos

 de tales disposiciones, necesitan sobrevivir a la terminación o expiración de este Acuerdo, se considerarán como sobrevivientes por el tiempo necesario para cumplir tales propósitos.

### DERECHOS DE PROPIEDAD INTELECTUAL

El contenido de la plataforma Studium, incluyendo, sin limitación, todo el código fuente, bases de datos, funcionalidad, software, diseños de la plataforma, audio, video, texto, fotografías y gráficos son propiedad de Studium o están licenciados para ella, y están sujetos a derechos de autor y otros derechos de propiedad intelectual bajo las leyes de Estados Unidos y leyes internacionales. Se le otorga una licencia limitada para acceder y usar la plataforma y su contenido para fines personales, educativos y no comerciales. Studium reserva todos los derechos no expresamente otorgados en este Acuerdo.

### MODIFICACIONES

#### En el Acuerdo

Studium puede modificar este Acuerdo de vez en cuando. Todos los cambios en este Acuerdo serán publicados en la plataforma y las revisiones se indicarán por fecha. Usted acepta estar vinculado a cualquier cambio en este Acuerdo cuando utilice los Servicios de Studium después de que cualquier modificación se haga efectiva. Studium también puede, a su discreción, optar por alertar a todos los usuarios para los cuales tiene información de correo electrónico sobre tales modificaciones mediante un correo electrónico enviado a la dirección más recientemente proporcionada por el usuario. Es importante que revise regularmente este Acuerdo y mantenga su información de contacto actualizada en las configuraciones de su cuenta para asegurarse de que está informado sobre los cambios.

#### En los Servicios

Studium se reserva el derecho en cualquier momento de modificar o discontinuar, temporal o permanentemente, los Servicios de Studium (o cualquier parte de ellos) con o sin aviso previo. Usted acepta que Studium no será responsable ante usted o terceros por cualquier modificación, suspensión o interrupción de los Servicios.

### DISPUTAS

#### Entre Usuarios

Si hay una disputa entre usuarios de la plataforma, o entre usuarios y terceros, usted comprende y acepta que Studium no está obligada a involucrarse. En el caso de que usted tenga una disputa con uno o más otros usuarios, usted por este medio exime a Studium, sus oficiales, empleados, agentes y sucesores en derechos de cualquier reclamación, demanda y daños de toda clase y naturaleza, conocidos y desconocidos, que surjan de o de alguna manera relacionados con tales disputas y/o los Servicios de Studium.

#### Con Studium

A. Ley Aplicable; Jurisdicción. Este Acuerdo y todos los aspectos de la plataforma y los Servicios de Studium serán gobernados e interpretados de acuerdo con las leyes internas del Estado de Delaware, sin considerar conflictos de disposiciones legales. Con respecto a cualquier disputa o reclamación no sujeta a resolución informal o arbitraje (como se establece a continuación), usted acepta no iniciar o procesar cualquier acción en conexión con eso en otro lugar que no sea los tribunales estatales y federales ubicados en el Condado de New Castle, Estado de Delaware, y por este medio consiente y renuncia a todas las defensas de falta de jurisdicción personal y conveniencia del foro con respecto a dichos tribunales.

B. Resolución Informal. Para acelerar la resolución y controlar los costos de cualquier disputa relacionada con este Acuerdo, usted y Studium acuerdan primero intentar negociar cualquier disputa de forma informal por al menos treinta (30) días antes de iniciar cualquier procedimiento de arbitraje o judicial. Las negociaciones informales comienzan con una notificación escrita de una parte a la otra.

C. Arbitraje Vinculante. Si usted y Studium no pueden resolver una disputa a través de negociaciones informales, cualquiera de las partes puede optar por resolver la disputa final y exclusivamente por arbitraje vinculante. El arbitraje se realizará bajo las Reglas de Arbitraje Comercial de la American Arbitration Association ("AAA") y, cuando sea apropiado, los Procedimientos Suplementarios de la AAA para Disputas Relacionadas al Consumidor, ambos disponibles en el sitio web www.adr.org. El arbitraje puede ser conducido personalmente, a través de la presentación de documentos, por teléfono o en línea.

D. Restricciones. Usted y Studium acuer

dan que cualquier arbitraje será limitado a la disputa entre Studium y usted individualmente. En la medida máxima permitida por ley, (1) ningún arbitraje será unido con otro; (2) no hay derecho ni autoridad para que cualquier disputa sea arbitrada en una base de acción colectiva o para utilizar procedimientos de acción colectiva; y (3) no hay derecho ni autoridad para que cualquier disputa sea traída en una capacidad representativa en nombre del público general o de otras personas.

### DISPUTAS

#### Entre Usuarios

Si ocurre una disputa entre usuarios de la plataforma, o entre usuarios y terceros, usted comprende y acepta que Studium no está obligada a involucrarse. En el caso de que usted tenga una disputa con uno o más otros usuarios, usted por este medio exonera a Studium, sus empleados, agentes y sucesores de derechos de cualquier reclamación, demanda y daños de toda clase y naturaleza, conocidos y desconocidos, que surjan de o de alguna manera relacionados con tales disputas y/o los Servicios de Studium.

#### Con Studium

A. Ley Aplicable; Jurisdicción. Este Acuerdo y todos los aspectos de la plataforma y los Servicios de Studium serán regidos e interpretados de acuerdo con las leyes internas del Estado de Delaware, sin considerar conflictos de disposiciones legales. Con respecto a cualquier disputa o reclamación no sujeta a resolución informal o arbitraje (como se define a continuación), usted acepta no iniciar o procesar cualquier acción excepto en los tribunales estatales y federales ubicados en el Condado de New Castle, Estado de Delaware, y por este medio consiente y renuncia a todas las defensas de falta de jurisdicción personal y conveniencia del foro con respecto a dichos tribunales.

B. Resolución Informal. Para acelerar la resolución y controlar los costos de cualquier disputa relacionada con este Acuerdo, usted y Studium acuerdan primero intentar negociar cualquier disputa de manera informal por al menos treinta (30) días antes de iniciar cualquier procedimiento de arbitraje o judicial. Las negociaciones informales comienzan con una notificación escrita de una parte a la otra.

C. Arbitraje Vinculante. Si usted y Studium no pueden resolver una disputa a través de negociaciones informales, cualquiera de las partes puede optar por resolver la disputa final y exclusivamente por arbitraje vinculante. La arbitraje será realizado bajo las Reglas de Arbitraje Comercial de la American Arbitration Association ("AAA") y, cuando sea apropiado, los Procedimientos Suplementarios de la AAA para Disputas Relacionadas al Consumidor, ambos disponibles en el sitio web www.adr.org. El arbitraje puede ser conducido personalmente, a través de la presentación de documentos, por teléfono o en línea.

D. Restricciones. Usted y Studium acuerdan que cualquier arbitraje será limitado a la disputa entre Studium y usted individualmente. En la medida máxima permitida por ley, (1) ningún arbitraje será unido con otro; (2) no hay derecho ni autoridad para que cualquier disputa sea arbitrada en una base de acción colectiva o para utilizar procedimientos de acción colectiva; y (3) no hay derecho ni autoridad para que cualquier disputa sea traída en una capacidad representativa en nombre del público general o de otras personas.

### CORRECCIONES

Ocasionalmente, puede haber información en la plataforma que contenga errores tipográficos, imprecisiones u omisiones que puedan estar relacionadas con descripciones de servicios, precios, disponibilidad y otras diversas informaciones. Studium se reserva el derecho de corregir cualquier error, imprecisión u omisión y de cambiar o actualizar la información en cualquier momento, sin previo aviso.

### EXENCIONES DE RESPONSABILIDAD

Studium no puede controlar la naturaleza de todo el contenido disponible en la plataforma. Al operar la plataforma, Studium no representa ni implica que respalda ningún blog, contribución u otro contenido disponible en o vinculado por la plataforma, incluyendo sin limitación contenido alojado en sitios web de terceros o provisto por aplicaciones de terceros, ni que cree que tales contribuciones o contenidos sean precisos, útiles o no perjudiciales. No controlamos y no somos responsables por contenidos ilegales o de otra manera objetables que usted pueda encontrar en la plataforma o en conexión con cualquier contribución.

 Studium tampoco es responsable por el comportamiento, ya sea en línea o fuera de línea, de cualquier usuario de la plataforma.

USTED ACEPTA QUE EL USO DE LA PLATAFORMA Y LOS SERVICIOS DE STUDIUM SERÁ BAJO SU PROPIO RIESGO. EN LA MÁXIMA EXTENSIÓN PERMITIDA POR LA LEY, STUDIUM, SUS OFICIALES, DIRECTORES, EMPLEADOS Y AGENTES EXONERAN TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS, EN CONEXIÓN CON LA PLATAFORMA Y SU USO, INCLUYENDO, SIN LIMITACIÓN, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, ADECUACIÓN A UN PROPÓSITO ESPECÍFICO Y NO INFRACCIÓN. STUDIUM NO HACE GARANTÍAS NI REPRESENTACIONES SOBRE LA PRECISIÓN O COMPLETITUD DEL CONTENIDO DE LA PLATAFORMA O DEL CONTENIDO DE CUALQUIER SITIO VINCULADO A NUESTRA PLATAFORMA Y NO ASUME NINGUNA RESPONSABILIDAD POR CUALQUIER (A) ERRORES, EQUIVOCACIONES O IMPRECISIONES DE CONTENIDO Y MATERIALES, (B) DAÑOS PERSONALES O MATERIALES, DE CUALQUIER NATURALEZA, RESULTANTES DE SU ACCESO Y USO DE NUESTRA PLATAFORMA, (C) CUALQUIER ACCESO NO AUTORIZADO O USO DE NUESTROS SERVIDORES SEGUROS Y/O CUALQUIER INFORMACIÓN PERSONAL Y/O FINANCIERA ALMACENADA EN ELLOS, (D) CUALQUIER INTERRUPCIÓN O CESACIÓN DE TRANSMISIÓN HACIA O DESDE LA PLATAFORMA, (E) CUALQUIER BUGS, VIRUS, TROYANOS O SIMILARES QUE PUEDAN SER TRANSMITIDOS A O A TRAVÉS DE NUESTRA PLATAFORMA POR CUALQUIER TERCERO, Y/O (F) CUALQUIER ERROR U OMISIÓN EN CUALQUIER CONTENIDO Y MATERIALES O POR CUALQUIER PÉRDIDA O DAÑO DE CUALQUIER TIPO INCURRIDOS COMO RESULTADO DEL USO DE CUALQUIER CONTENIDO PUBLICADO, TRANSMITIDO O DE OTRA MANERA DISPONIBLE A TRAVÉS DE LA PLATAFORMA.

### LIMITACIONES DE RESPONSABILIDAD

EN NINGÚN CASO STUDIUM O SUS DIRECTORES, EMPLEADOS O AGENTES SERÁN RESPONSABLES ANTE USTED O CUALQUIER TERCERO POR CUALQUIER DAÑO DIRECTO, INDIRECTO, CONSECUENCIAL, EJEMPLAR, INCIDENTAL, ESPECIAL O PUNITIVO, INCLUYENDO PÉRDIDA DE BENEFICIOS, PÉRDIDA DE INGRESOS, PÉRDIDA DE DATOS U OTROS DAÑOS QUE SURJAN DE SU USO DE LA PLATAFORMA O SERVICIOS DE STUDIUM, AUN CUANDO STUDIUM HAYA SIDO AVISADA DE LA POSIBILIDAD DE TALES DAÑOS. NO OBSTANTE CUALQUIER COSA EN CONTRARIO CONTENIDA AQUÍ, LA RESPONSABILIDAD DE STUDIUM PARA CON USTED POR CUALQUIER CAUSA Y SIN IMPORTAR LA FORMA DE LA ACCIÓN, ESTARÁ EN TODO MOMENTO LIMITADA AL MONTO PAGADO, SI LO HUBIERE, POR USTED A STUDIUM POR LOS SERVICIOS DE STUDIUM DURANTE EL PERÍODO DE TRES (3) MESES ANTERIORES A CUALQUIER CAUSA DE ACCIÓN SURGIDA.

LAS LEYES DE CIERTOS ESTADOS NO PERMITEN LIMITACIONES SOBRE GARANTÍAS IMPLÍCITAS NI LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS. SI ESTAS LEYES SE APLICAN A USTED, ALGUNAS O TODAS LAS EXENCIONES O LIMITACIONES ANTERIORES PUEDEN NO APLICARSE A USTED, Y USTED PUEDE TENER DERECHOS ADICIONALES.

SI USTED ES RESIDENTE EN CALIFORNIA, USTED RENUNCIA AL CÓDIGO CIVIL DE CALIFORNIA SE

CCIÓN 1542, QUE DICE: “UNA LIBERACIÓN GENERAL NO SE EXTIENDE A LAS RECLAMACIONES QUE EL ACREEDOR NO CONOCE O SOSPECHA QUE EXISTEN A SU FAVOR EN EL MOMENTO DE EJECUTAR LA LIBERACIÓN, QUE, DE SER CONOCIDAS POR ÉL, DEBEN HABER AFECTADO MATERIALMENTE SU ACUERDO CON EL DEUDOR.”

### INDEMNIZACIÓN

Usted acepta defender, indemnizar y eximir a Studium, sus subsidiarias y afiliadas, y a sus respectivos oficiales, agentes, socios y empleados, de y contra cualquier pérdida, daño, responsabilidad, reclamación o demanda, incluidos honorarios de abogados y gastos razonables, hechos por terceros debido o derivados de su contenido contribuido, uso de los Servicios de Studium, y/o derivados de una violación de este Acuerdo y/o cualquier violación de sus representaciones y garantías establecidas arriba. No obstante lo anterior, Studium se reserva el derecho, a su costo, de asumir la defensa y control exclusivos de cualquier asunto por el cual usted está obligado a indemnizar a Studium, y usted acepta cooperar, a su costo, con la defensa de Studium de tales reclamaciones. Studium hará esfuerzos razonables para notificarle de cualquier reclamación, acción o procedimiento que sea objeto de esta indemnización tan pronto como tenga conocimiento de ello.

### NOTIFICACIONES

Excepto como explícitamente indicado de otra manera, cualquier notificación enviada a Studium debe hacerse por correo electrónico a la dirección listada en la información de contacto abajo. Cualquier notificación enviada a usted se dará al dirección de correo electrónico proporcionada por usted durante el proceso de registro, o a otra dirección que cada parte pueda especificar. La notificación se considerará dada veinticuatro (24) horas después de que el correo electrónico sea enviado, a menos que la parte remitente sea notificada de que la dirección de correo electrónico es inválida. También podemos optar por enviar notificaciones por correo regular.

### DATOS DEL USUARIO

Nuestra plataforma mantendrá ciertos datos que usted transfiera a la plataforma con el propósito de desempeño de los Servicios de Studium, así como datos relacionados con su uso de los Servicios de Studium. Aunque realizamos copias de seguridad regulares de datos, usted es principalmente responsable de todos los datos que usted haya transferido o que se relacionen con cualquier actividad que usted haya emprendido usando los Serviços de Studium. Usted acepta que Studium no tendrá ninguna responsabilidad hacia usted por cualquier pérdida o corrupción de tales datos, y usted por este medio renuncia a cualquier derecho de acción contra Studium que surja de tal pérdida o corrupción de esos datos.

### CONTRATACIÓN ELECTRÓNICA

Su uso de los Servicios de Studium incluye la capacidad de entrar en acuerdos y/o realizar transacciones electrónicamente. USTED RECONOCE QUE SUS ENVÍOS ELECTRÓNICOS CONSTITUYEN SU ACUERDO E INTENCIÓN DE ESTAR VINCULADO POR Y PAGAR POR TALES ACUERDOS Y TRANSACCIONES. SU ACUERDO E INTENCIÓN DE ESTAR VINCULADO POR ENVÍOS ELECTRÓNICOS APLICA A TODOS LOS REGISTROS RELACIONADOS CON TODAS LAS TRANSACCIONES QUE USTED REALICE RELACIONADAS A LOS SERVICIOS DE STUDIUM, INCLUYENDO NOTIFICACIONES DE CANCELACIÓN, POLÍTICAS, CONTRATOS Y APLICACIONES. Para acceder y retener sus registros electrónicos, usted puede requerir cierto hardware y software, que son su responsabilidad única.

### DIVERSOS

Este Acuerdo constituye el acuerdo completo entre usted y Studium con respecto al uso de los Servicios de Studium. El fallo de Studium en ejercer o hacer cumplir cualquier derecho o disposición de este Acuerdo no funcionará como una renuncia de tal derecho o disposición. Los títulos de sección en este Acuerdo son solo para conveniencia y no tienen efecto legal o contractual. Este Acuerdo opera en la máxima extensión permitida por la ley. Este Acuerdo y su cuenta no pueden ser asignados por usted sin el consentimiento expreso por escrito de Studium. Studium puede asignar cual

quiera o todos sus derechos y obligaciones a otros en cualquier momento. Studium no será responsable por cualquier pérdida, daño, retraso o falta de actuar causado por cualquier causa más allá del control razonable de Studium. Si alguna disposición o parte de una disposición de este Acuerdo es ilegal, nula o inaplicable, esa disposición o parte de la disposición se considera separable de este Acuerdo y no afecta la validez y aplicabilidad de las disposiciones restantes. No se crea ninguna relación de empresa conjunta, asociación, empleo o agencia entre usted y Studium como resultado de este Acuerdo o uso de los Sitios y Servicios de Studium. A solicitud de Studium, usted proporcionará a Studium cualquier documentación, justificación o liberaciones necesarias para verificar su cumplimiento con este Acuerdo. Usted acepta que este Acuerdo no será interpretado en contra de Studium por haber sido la parte que lo redactó. Usted renuncia a todas y cualquier defensa que pueda tener basada en la forma electrónica de este Acuerdo y la falta de firma de las partes para ejecutar este Acuerdo.

### CONTACTO

Para resolver una queja sobre los Servicios de Studium o para recibir más información sobre el uso de los Servicios de Studium, póngase en contacto con Studium según lo definido a continuación, o, si alguna queja con nosotros no se resuelve satisfactoriamente y usted es residente en California, puede contactar a la Unidad de Asistencia de Quejas de la División de Servicios al Consumidor del Departamento de Asuntos del Consumidor por escrito en la dirección 400 “R” Street, Sacramento, California 95814 o por teléfono al 1-916-445-1254.

Studium

Correo electrónico: [team@studium.academy](mailto:team@studium.academy?subject=Terms%20of%20Service)
`,
    },
    fr: {
        termsOfServiceTitle: "Conditions d'utilisation",
        termsOfService: `
Cet Accord de Conditions d'Utilisation ("Accord"), conjointement avec notre Politique de Confidentialité de l'Entreprise ([https://studium.academy/privacy](https://studium.academy/privacy)), constitue un accord légalement contraignant entre vous, personnellement ou au nom d'une entité ("utilisateur" ou "vous") et Studium et ses entreprises affiliées, sites web, applications et outils (collectivement, "Studium", "Entreprise" ou "nous" ou "notre"), concernant votre accès et utilisation des sites web [https://studium.academy](https://studium.academy) et toute autre forme de média, canal médiatique, site web mobile ou application mobile liée ou connectée à ceux-ci (collectivement, les "Sites"). Les Sites offrent les services suivants : plateformes éducatives et de design, et services d'hébergement et de marketing ("Services de l'Entreprise"). Les termes et conditions supplémentaires ou les documents qui peuvent être publiés sur les Sites de temps à autre sont expressément incorporés à cet Accord par référence.

L'Entreprise ne garantit pas que les Sites soient appropriés ou disponibles dans d'autres lieux que ceux où ils sont exploités par l'Entreprise. Les informations fournies sur les Sites ne sont pas destinées à la distribution ou à l'utilisation par toute personne ou entité dans toute juridiction ou pays où une telle distribution ou utilisation serait contraire à la loi ou à la réglementation ou qui soumettrait l'Entreprise à une obligation d'enregistrement dans une telle juridiction ou pays. Ainsi, les personnes qui choisissent d'accéder aux Sites depuis d'autres lieux le font de leur propre initiative et sont entièrement responsables du respect des lois locales, dans la mesure où ces lois sont applicables.

Tous les utilisateurs qui sont mineurs dans la juridiction dans laquelle ils résident (généralement âgés de moins de 18 ans) ont l'autorisation de s'inscrire et d'utiliser les Sites sous la supervision parentale ou de leur tuteur légal. L'Entreprise encourage les parents et tuteurs à guider, superviser et discuter de l'utilisation des Services de l'Entreprise avec leurs enfants et pupilles.

VOUS ACCEPTEZ ET CONVENEZ D'ÊTRE LIÉ PAR CET ACCORD EN RECONNAISSANT UNE TELLE ACCEPTATION PENDANT LE PROCESSUS D'INSCRIPTION (LE CAS ÉCHÉANT) ET ÉGALEMENT EN CONTINUANT D'UTILISER LES SITES. SI VOUS N'ACCEPTEZ PAS DE RESPECTER CET ACCORD, OU LES MODIFICATIONS QUE L'ENTREPRISE PEUT APPORTER À CET ACCORD À L'AVENIR, NE PAS UTILISER OU ACCÉDER OU CONTINUER À UTILISER OU ACCÉDER AUX SERVICES DE L'ENTREPRISE OU AUX SITES.

### ACHATS ET PAIEMENTS

Studium peut offrir des périodes d'essai gratuites ou des échantillons de nos produits ou services. La durée de la période d'essai gratuite et tous les autres détails de l'offre seront publiés sur nos Sites. Si vous souhaitez essayer nos options gratuites, veuillez lire attentivement tous les détails au préalable. Studium facturera via Stripe pour nos Services. En utilisant nos options payantes, vous acceptez de payer à Studium tous les frais aux prix alors en vigueur pour les produits ou services que vous ou d'autres personnes utilisant votre compte de facturation pouvez acheter, et vous autorisez Studium à facturer votre fournisseur de paiement choisi pour ces achats. Vous acceptez de effectuer le paiement en utilisant la méthode de paiement sélectionnée. Si vous commandez un produit ou un service soumis à des frais récurrents, vous acceptez que nous facturions votre méthode de paiement de manière récurrente, sans nécessiter votre approbation préalable pour chaque frais récurrent jusqu'à ce que vous annuliez le produit ou service applicable. Studium se réserve le droit de corriger toute erreur ou erreur dans les prix même si elle a déjà demandé ou reçu le paiement. Les taxes de vente seront ajoutées au prix de vente des achats comme l'exige l'Entreprise. L'Entreprise peut modifier les prix à tout moment. Tous les paiements seront en dollars américains.

### POLITIQUE DE REMBOURSEMENT ET DE RETOUR

Studium comprend

 que l'éducation est un engagement significatif. Par conséquent, nous offrons une politique de remboursement limitée pour garantir la satisfaction et la flexibilité de l'utilisateur. Les remboursements peuvent être demandés dans les 14 jours suivant l'achat de tout cours ou service payant, à condition que le cours n'ait pas été substantiellement consommé ou complété. Après cette période, toutes les ventes sont finales et aucun remboursement ne sera émis. Cette politique est conçue pour garantir que les instructeurs soient justement compensés pour le temps et les ressources consacrés à la préparation des cours.

Pour demander un remboursement, veuillez contacter le support client via notre page de contact sur le site, en fournissant les détails du produit acheté et la raison de la demande de remboursement.

### REPRÉSENTATIONS DE L'UTILISATEUR

#### Concernant Votre Inscription

En utilisant les Services de Studium, vous déclarez et garantissez que :

A. Toutes les informations d'inscription que vous soumettez sont véridiques et exactes;

B. Vous maintiendrez l'exactitude de telles informations;

C. Vous garderez votre mot de passe confidentiel et serez responsable de toute utilisation de votre mot de passe et de votre compte;

D. Si vous êtes mineur dans la juridiction dans laquelle vous résidez, vous avez reçu la permission des parents ou tuteurs légaux pour utiliser nos Sites; et

E. Votre utilisation des Services de l'Entreprise ne viole aucune loi ou réglementation applicable.

Vous acceptez également de : (a) fournir des informations véridiques, exactes, actuelles et complètes sur vous-même comme le demande le formulaire d'inscription des Sites et (b) maintenir et mettre à jour rapidement les données d'inscription pour les garder véridiques, exactes, actuelles et complètes. Si vous fournissez des informations qui sont fausses, inexactes, pas actuelles ou incomplètes, ou si l'Entreprise a des motifs raisonnables de soupçonner que ces informations sont fausses, inexactes, pas actuelles ou incomplètes, l'Entreprise a le droit de suspendre ou de résilier votre compte et de refuser toute utilisation actuelle ou future des Sites (ou de toute partie de ceux-ci).

Nous nous réservons le droit de retirer, récupérer ou changer un nom d'utilisateur que vous sélectionnez si nous déterminons qu'il est approprié à notre discrétion, comme lorsque le nom d'utilisateur est obscène ou autrement répréhensible ou lorsqu'un propriétaire de marque se plaint d'un nom d'utilisateur qui ne se rapporte pas étroitement au nom réel de l'utilisateur.

#### Concernant le Contenu que Vous Fournissez

Nous pouvons vous inviter à participer à des chats, blogs, forums de messages, forums en ligne et autres fonctionnalités et pouvons vous fournir l'opportunité de créer, soumettre, poster, afficher, transmettre, exécuter, publier, distribuer ou diffuser du contenu et des matériaux sur nos Sites et/ou via des formulaires des Sites, e-mails, agents de chat, pop-ups, y compris, sans limitation, du texte, des écrits, de la vidéo, de l'audio, des photographies, des graphiques, des commentaires, des suggestions ou des informations personnellement identifiables ou d'autres matériaux (collectivement “Contributions”). Toute Contribution que vous transmettez à Studium sera traitée comme non confidentielle et non propriétaire. En créant ou en mettant à disposition une Contribution, vous déclarez et garantissez que :

A. La création, distribution, transmission, affichage public et performance, accès, téléchargement et copie de votre Contribution ne violeront pas les droits de propriété, y compris mais non limité aux droits d'auteur, brevets, marques de commerce, secrets commerciaux ou droits moraux de tiers;

B. Vous êtes le créateur et propriétaire de ou avez les licences nécessaires, droits, consentements, libérations et permissions pour utiliser et autoriser Studium et les utilisateurs des Sites à utiliser vos Contributions comme nécessaire pour exercer les licences accordées par vous sous cet Accord;

C. Vous avez obtenu le consentement écrit, la libération et/ou la permission de chaque personne identifiable individuellement dans votre Contribution pour utiliser le nom ou la ressemblance

 de chaque personne identifiable individuellement pour permettre l'inclusion et l'utilisation de la Contribution de la manière envisagée par nos Sites;

D. Votre Contribution n'est pas obscène, lascive, sale, violente, harcelante ou autrement répréhensible (tel que déterminé par Studium), diffamatoire ou calomnieuse, ne ridiculise pas, ne moque pas, ne dénigre pas, n'intimide ni n'abuse de personne, ne prône pas le renversement violent de tout gouvernement, n'incite pas, n'encourage ou ne menace pas de dommage physique contre un autre, ne viole aucune loi, règlement ou règle applicable, et ne viole pas les droits de vie privée ou de publicité de tiers;

E. Votre Contribution ne contient pas de matériel qui sollicite des informations personnelles de quiconque de moins de 18 ans ou exploite des personnes de moins de 18 ans de manière sexuelle ou violente, et ne viole aucune loi fédérale ou étatique concernant la pornographie juvénile ou destinée à protéger la santé ou le bien-être des mineurs;

F. Votre Contribution n'inclut pas de commentaires offensants qui sont liés à la race, l'origine nationale, le genre, la préférence sexuelle ou un handicap physique;

G. Votre Contribution ne viole pas, ni ne lie à du matériel qui viole, toute disposition de cet Accord ou toute loi ou réglementation applicable.

### LICENCE DE CONTRIBUTION

En postant des Contributions sur n'importe quelle partie des Sites, ou en les rendant accessibles aux Sites en liant votre compte à l'une de vos comptes de réseaux sociaux, vous accordez automatiquement, et vous déclarez et garantissez que vous avez le droit d'accorder, à Studium une licence mondiale, perpétuelle, non exclusive, transférable, exempte de droits d'auteur et entièrement payée pour héberger, utiliser, copier, reproduire, divulguer, vendre, revendre, publier, diffuser, retitrer, archiver, stocker, mettre en cache, exécuter publiquement, afficher publiquement, reformater, traduire, transmettre et distribuer ces Contributions à toute fin, commerciale, publicitaire ou autre, pour préparer des œuvres dérivées ou les incorporer dans d'autres œuvres, et pour accorder et autoriser des sous-licences des précédentes. L'utilisation et la distribution peuvent survenir dans n'importe quel format de média et à travers n'importe quel canal de média. Cette licence d'utilisation et de distribution s'appliquera à toute forme, média ou technologie, maintenant connue ou développée ultérieurement, et inclut notre utilisation de votre nom, nom de l'entreprise, comme applicable, et de toutes les marques de commerce, marques de service, noms commerciaux et logos, images personnelles et commerciales que vous fournissez. Studium ne revendique aucune propriété sur vos Contributions ; au lieu de cela, entre nous et vous, sous réserve des droits accordés à nous dans cet Accord, vous conservez la pleine propriété de toutes vos Contributions et de tous les droits de propriété intellectuelle ou autres droits de propriété associés à vos Contributions. Nous n'utiliserons pas vos contributions d'une manière qui enfreigne vos droits et traiterons toujours vos informations personnelles de manière légale et avec votre consentement.

Studium a le droit, à sa seule et absolue discrétion, de (i) éditer, rédiger ou autrement changer toute Contribution, (ii) recatégoriser toute Contribution pour la placer dans des endroits plus appropriés ou (iii) pré-examiner ou supprimer toute Contribution qui est déterminée comme inappropriée ou autrement en violation de cet Accord.

En téléchargeant vos Contributions sur les Sites, vous autorisez Studium à accorder à chaque utilisateur final une licence personnelle, limitée, non transférable, perpétuelle, non exclusive, exempte de droits d'auteur et entièrement payée pour accéder, télécharger, imprimer et autrement utiliser vos Contributions à leurs fins internes et non pour distribution, transfert, vente ou exploitation commerciale de quelque nature que ce soit.

### DIRECTIVES POUR LES ÉVALUATIONS

Studium peut accepter, rejeter ou retirer des évaluations à sa seule discrétion. Studium n'a absolument

 aucune obligation de filtrer les évaluations ou de les supprimer, même si quelqu'un considère les évaluations comme objettables ou inexactes. Les personnes qui postent des évaluations doivent respecter les critères suivants : (1) les évaluateurs doivent avoir une expérience directe avec la personne / entité évaluée; (2) les évaluations ne doivent pas contenir : des langages offensants, de la profanité, ou un langage abusif, raciste ou de haine; des références discriminatoires basées sur la religion, la race, le genre, l'origine nationale, l'âge, le statut marital, l'orientation sexuelle ou le handicap; ou des références à une activité illégale; (3) les évaluateurs ne doivent pas être affiliés à des concurrents en publiant des évaluations négatives; (4) les évaluateurs ne doivent pas tirer de conclusions sur la légalité de la conduite; et (5) les évaluateurs ne peuvent pas publier de déclarations fausses ou organiser une campagne encourageant d'autres à publier des évaluations, qu'elles soient positives ou négatives. Les évaluations ne sont pas endossées par Studium, et ne représentent pas les vues de Studium ou de tout affilié ou partenaire de l'entreprise. Studium n'assume aucune responsabilité pour toute évaluation ou pour toute revendication, responsabilité ou perte résultant de toute évaluation. En postant une évaluation, l'évaluateur accorde à Studium une licence perpétuelle, non exclusive, mondiale, exempte de droits d'auteur, entièrement payée, attribuable et sublicenciable pour reproduire, modifier, traduire, transmettre par tout moyen, afficher, exécuter et/ou distribuer tout contenu lié aux évaluations.

### SOUMISSIONS

Vous reconnaissez et acceptez que toutes les questions, commentaires, suggestions, idées, retours d'informations ou autres informations concernant les Sites ou les Services de Studium ("Soumissions") fournies par vous à Studium sont non-confidentielles et Studium (ainsi que tout désigné par l'Entreprise) aura le droit d'utiliser et de diffuser ces Soumissions pour toute fin, commerciale ou autre, sans reconnaissance ni compensation pour vous.

### ACTIVITÉS INTERDITES

Vous ne pouvez pas accéder ou utiliser les Sites à d'autres fins que celles pour lesquelles Studium les rend disponibles. Les Sites ne peuvent pas être utilisés en liaison avec des entreprises commerciales, sauf celles spécifiquement endossées ou approuvées par Studium. Les activités interdites incluent, mais ne sont pas limitées à :

A. Tenter de contourner toute mesure des Sites conçue pour prévenir ou restreindre l'accès aux Sites, ou à toute partie des Sites.

B. Tenter de se faire passer pour un autre utilisateur ou personne ou utiliser le nom d'utilisateur d'un autre utilisateur.

C. Activité criminelle ou tortueuse.

D. Décrypter, décompiler, démonter ou inverser l'ingénierie de tout logiciel composant ou de quelque manière que ce soit faisant partie des Sites.

E. Supprimer le droit d'auteur ou autres avis de droits de propriété de tout contenu des Sites.

F. Engager dans toute utilisation automatisée du système, telle que l'utilisation de scripts pour ajouter des amis ou envoyer des commentaires ou des messages, ou utiliser des outils de collecte de données, des robots, ou des méthodes similaires de collecte et d'extraction de données.

G. Utiliser ou lancer, développer ou distribuer tout système automatisé, y compris sans limitation, "robots", "bots", "cheat utility", "scrapers" ou "offline readers" qui accèdent aux Sites, ou utiliser ou lancer tout script ou autre logiciel non autorisé.

H. Harceler, agacer, intimider ou menacer les employés ou les agents de Studium engagés dans la fourniture de toute partie des Services de l'Entreprise pour vous.

I. Interférer avec, perturber ou créer une charge déraisonnable sur les Sites ou les réseaux ou services connectés aux Sites.

J. Faire un usage non autorisé des Services de Studium, y compris la collecte de noms d'utilisateur et/ou d'adresses e-mail d'utilisateurs par des moyens électroniques ou autres pour envoyer

 des e-mails non sollicités, ou créer des comptes d'utilisateur par des moyens automatisés ou sous de faux prétextes.

K. Vendre ou autrement transférer votre profil.

L. Récupération systématique de données ou autre contenu des Sites pour créer ou compiler, directement ou indirectement, une collection, compilation, base de données ou annuaire sans permission écrite de la Société.

M. Tromper, frauder ou induire en erreur Studium et d'autres utilisateurs, notamment dans toute tentative d'apprendre des informations de compte sensibles telles que les mots de passe.

N. Utiliser toute information obtenue des Sites pour harceler, abuser ou nuire à une autre personne.

O. Utiliser les Services de l'Entreprise dans le cadre de tout effort pour concurrencer Studium ou pour fournir des services en tant que bureau de service.

P. Utiliser les Sites d'une manière incompatible avec toutes les lois et réglementations applicables.

### DROITS DE PROPRIÉTÉ INTELLECTUELLE

Le contenu sur les Sites ("Contenu de Studium") et les marques commerciales, marques de service et logos contenus sur ceux-ci ("Marques") sont la propriété de ou licenciés à Studium, et sont sujets aux droits d'auteur et autres droits de propriété intellectuelle sous les lois des États-Unis et des lois étrangères et conventions internationales. Le Contenu de Studium inclut, sans limitation, tout le code source, bases de données, fonctionnalités, logiciels, designs des Sites, audio, vidéo, texte, photographies et graphiques. Tous les graphiques, logos, designs, en-têtes de page, icônes de boutons, scripts et noms de services de Studium sont des marques déposées, des marques de commerce ou une image commerciale de Studium aux États-Unis et/ou dans d'autres pays. Les marques commerciales et l'image commerciale de Studium ne peuvent pas être utilisées, y compris dans le cadre de marques commerciales et/ou dans le cadre de noms de domaine, en connexion avec n'importe quel produit ou service d'une manière qui est susceptible de causer de la confusion et ne peuvent pas être copiées, imitées, ou utilisées, en tout ou partie, sans l'autorisation écrite préalable de Studium.

Le Contenu de Studium sur les Sites est fourni à vous "TEL QUEL" pour votre information et utilisation personnelle seulement et ne peut être utilisé, copié, reproduit, agrégé, distribué, transmis, exposé, vendu, licencié ou autrement exploité pour tout autre but sans le consentement préalable écrit des propriétaires respectifs. Tant que vous êtes éligible à utiliser les Sites, vous êtes accordé une licence limitée pour accéder et utiliser les Sites et le Contenu de Studium et télécharger ou imprimer une copie de toute portion du Contenu de Studium à laquelle vous avez correctement accès uniquement pour votre usage personnel, non commercial. Studium réserve tous les droits non expressément accordés à vous dans et aux Sites et au Contenu de Studium et Marques.

### SITES WEB ET CONTENU DE TIERS

La plateforme Studium peut contenir des liens vers des sites externes ("Sites Web de Tiers") et inclure des articles, des photographies, du texte, des graphiques, des vidéos, des informations, des applications, des logiciels et d'autres contenus ou articles appartenant à ou provenant de tiers ("Contenu de Tiers"). Ceux-ci ne sont pas vérifiés pour leur exactitude, adéquation ou exhaustivité par Studium, et nous ne sommes pas responsables des Sites Web de Tiers accessibles par notre plateforme ni de tout Contenu de Tiers disponible sur celle-ci. L'inclusion ou la disponibilité de ces liens n'implique pas l'approbation par Studium. L'accès à ces sites se fait au risque propre de l'utilisateur, et Studium n'est pas responsable des informations ou des transactions effectuées sur ces sites. Nous vous recommandons de revoir les politiques de confidentialité et les conditions d'utilisation de ces sites.

### GESTION DE LA PLATEFORME

Studium se réserve le droit, mais n'a pas l'obligation de :

A. Surveiller la plateforme pour des violations de cet Accord;

B. Prendre des mesures légales appropriées contre toute personne qui viole cet Accord;

C. Refuser, restreindre

 l'accès à, ou désactiver toute contribution d'utilisateur qui peut violer cet Accord ou les politiques de Studium;

D. Gérer la plateforme de manière à protéger les droits et la propriété de Studium et d'autres, et à faciliter le fonctionnement approprié de la plateforme.

### TERME ET RÉSILIATION

Cet Accord restera en vigueur tant que vous utiliserez la plateforme. Vous pouvez mettre fin à votre utilisation à tout moment, pour n'importe quelle raison, en suivant les instructions pour la résiliation de comptes dans les paramètres de votre compte.

Studium se réserve le droit de, à sa seule discrétion et sans avis ni responsabilité, refuser l'accès et l'utilisation de la plateforme à toute personne pour n'importe quelle raison ou sans aucune raison du tout, y compris pour violation de toute représentation, garantie ou pacte contenue dans cet Accord ou de toute loi ou réglementation applicable. Studium peut également mettre fin à votre utilisation ou participation dans la plateforme, supprimer votre profil et tout contenu ou information que vous avez publiés, à tout moment, sans préavis.

Pour protéger l'intégrité de la plateforme, Studium peut, à tout moment et à sa seule discrétion, bloquer certains adresses IP d'accéder à la plateforme.

Cet Accord et toutes les dispositions qui, afin de remplir les buts de telles dispositions, doivent survivre à la résiliation ou l'expiration de cet Accord, seront considérées comme survivantes pour aussi longtemps que nécessaire pour remplir ces buts.

### DROITS DE PROPRIÉTÉ INTELLECTUELLE

Le contenu de la plateforme Studium, y compris, sans limitation, tous les codes sources, bases de données, fonctionnalités, logiciels, designs de la plateforme, audio, vidéo, texte, photographies et graphiques sont la propriété de Studium ou sont licenciés à elle, et sont sujets aux droits d'auteur et autres droits de propriété intellectuelle sous les lois des États-Unis et des lois internationales. Vous recevez une licence limitée pour accéder et utiliser la plateforme et son contenu pour des fins personnelles, éducatives et non commerciales. Studium réserve tous les droits non expressément accordés dans cet Accord.

### MODIFICATIONS

#### À l'Accord

Studium peut modifier cet Accord de temps en temps. Toutes les modifications à cet Accord seront publiées sur la plateforme et les révisions seront indiquées par la date. Vous acceptez d'être lié par toute modification de cet Accord lorsque vous utilisez les Services de Studium après qu'une telle modification devienne effective. Studium peut également, à sa discrétion, choisir d'alerter tous les utilisateurs pour lesquels elle détient des informations par e-mail de telles modifications en envoyant un e-mail à l'adresse la plus récemment fournie par l'utilisateur. Il est important que vous révisiez régulièrement cet Accord et que vous mainteniez vos informations de contact à jour dans les paramètres de votre compte pour vous assurer d'être informé des modifications.

#### Aux Services

Studium se réserve le droit à tout moment de modifier ou de discontinuer, temporairement ou de manière permanente, les Services de Studium (ou une partie de ceux-ci) avec ou sans préavis. Vous convenez que Studium ne sera pas responsable envers vous ou tout tiers pour toute modification, suspension ou interruption des Services.

### DISPUTES

#### Entre Utilisateurs

S'il y a un différend entre les utilisateurs de la plateforme, ou entre les utilisateurs et un tiers, vous comprenez et acceptez que Studium n'est pas obligée de s'impliquer. Dans le cas où vous avez un différend avec un ou plusieurs autres utilisateurs, vous libérez par la présente Studium, ses officiers, employés, agents et successeurs en droits de toute réclamation, demande et dommages de toute sorte et nature, connus et inconnus, découlant de ou de toute façon liés à de tels différends et/ou aux Services de Studium.

#### Avec Studium

A. Loi Applicable; Juridiction. Cet Accord et tous les aspects de la plateforme et des Services de Studium seront régis et interprétés conformément aux lois internes de l'État de Delaware, sans tenir compte des dispositions sur les conflits de lois. En ce qui concerne tout différend ou réclamation non soumis à une résolution informelle ou à l

'arbitrage (tel que défini ci-dessous), vous acceptez de ne pas commencer ou de poursuivre toute action en relation avec cela autrement que dans les tribunaux d'État et fédéraux situés dans le comté de New Castle, État de Delaware, et par les présentes vous consentez et renoncez à toutes les défenses de manque de juridiction personnelle et de forum non conveniens en ce qui concerne le lieu et la juridiction dans ces tribunaux d'État et fédéraux.

B. Résolution Informelle. Pour accélérer la résolution et contrôler les coûts de tout différend, controverse ou réclamation lié à cet Accord (“Dispute”), vous et Studium convenez d'abord d'essayer de négocier tout Dispute de manière informelle pendant au moins trente (30) jours avant d'initier toute procédure d'arbitrage ou judiciaire. Ces négociations informelles commencent avec un avis écrit de la part d'une personne à l'autre.

C. Arbitrage Contraignant. Si vous et Studium ne parvenez pas à résoudre un Dispute par des négociations informelles, soit vous, soit Studium pouvez choisir de résoudre le Dispute (sauf ceux expressément exclus ci-dessous) finalement et exclusivement par arbitrage contraignant. Tout choix d'arbitrer par une partie sera final et contraignant pour l'autre. VOUS COMPRENEZ QUE SANS CETTE DISPOSITION, VOUS AURIEZ LE DROIT DE SUIVRE EN JUSTICE ET D'AVOIR UN PROCÈS DEVANT JURY. L'arbitrage sera commencé et mené sous les Règles d'Arbitrage Commerciale de l'American Arbitration Association (“AAA”) et, là où approprié, les Procédures Supplémentaires de l'AAA pour les Disputes liées aux Consommateurs (“Règles Consommateurs AAA”), toutes deux disponibles sur le site de l'AAA www.adr.org. L'arbitrage peut être mené en personne, par la soumission de documents, par téléphone ou en ligne.

D. Restrictions. Vous et Studium convenez que tout arbitrage sera limité au Dispute entre Studium et vous individuellement. Dans toute la mesure permise par la loi, (1) aucun arbitrage ne sera joint à un autre; (2) il n'y a pas de droit ou d'autorité pour que tout Dispute soit arbitré sur une base d'action de classe ou d'utiliser des procédures d'action de classe; et (3) il n'y a pas de droit ou d'autorité pour que tout Dispute soit porté dans une capacité représentative au nom du grand public ou de toute autre personne.

### DISPUTES

#### Entre Utilisateurs

Si une dispute survient entre les utilisateurs de la plateforme, ou entre les utilisateurs et un tiers, vous comprenez et acceptez que Studium n'est pas obligée de s'impliquer. Dans le cas où vous avez un différend avec un ou plusieurs autres utilisateurs, vous libérez ici Studium, ses employés, agents et successeurs en droits de toute réclamation, demande et dommages de toute sorte et nature, connus et inconnus, découlant de ou de toute façon liés à de tels différends et/ou aux Services de Studium.

#### Avec Studium

A. Loi Applicable; Juridiction. Cet Accord et tous les aspects de la plateforme et des Services de Studium seront régis et interprétés conformément aux lois internes de l'État de Delaware, sans tenir compte des conflits de dispositions légales. En ce qui concerne tout différend ou réclamation non soumis à une résolution informelle ou à l'arbitrage (tel que défini ci-dessous), vous acceptez de ne pas commencer ou poursuivre toute action autre que dans les tribunaux d'État et fédéraux situés dans le comté de New Castle, État de Delaware, et par les présentes vous consentez et renoncez à toutes les défenses de manque de juridiction personnelle et de convenance du forum en ce qui concerne ces tribunaux.

B. Résolution Informelle. Pour accélérer la résolution et contrôler les coûts de toute dispute liée à cet Accord, vous et Studium convenez de d'abord tenter de négocier toute dispute de

 manière informelle pendant au moins trente (30) jours avant de commencer toute procédure d'arbitrage ou judiciaire. Les négociations informelles commencent avec une notification écrite d'une partie à l'autre.

C. Arbitrage Contraignant. Si vous et Studium ne parvenez pas à résoudre une dispute par des négociations informelles, l'une ou l'autre des parties peut opter pour résoudre la dispute de manière finale et exclusive par arbitrage contraignant. L'arbitrage sera mené sous les Règles d'Arbitrage Commerciale de l'American Arbitration Association ("AAA") et, lorsque approprié, les Procédures Supplémentaires de l'AAA pour les Disputes liées aux Consommateurs, toutes deux disponibles sur le site www.adr.org. L'arbitrage peut être mené en personne, par la soumission de documents, par téléphone ou en ligne.

D. Restrictions. Vous et Studium convenez que toute arbitrage sera limité à la dispute entre Studium et vous individuellement. Dans toute la mesure permise par la loi, (1) aucun arbitrage ne sera joint à un autre; (2) il n'y a pas de droit ou autorité pour que toute dispute soit arbitrée sur une base d'action collective ou d'utiliser des procédures d'action collective; et (3) il n'y a pas de droit ou autorité pour que toute dispute soit présentée dans une capacité représentative au nom du public général ou d'autres personnes.

### CORRECTIONS

Occasionnellement, il peut y avoir des informations sur la plateforme qui contiennent des erreurs typographiques, des inexactitudes ou des omissions qui peuvent être liées à des descriptions de services, des prix, la disponibilité et diverses autres informations. Studium se réserve le droit de corriger toute erreur, inexactitude ou omission et de changer ou mettre à jour des informations à tout moment, sans préavis préalable.

### CLAUSES DE NON-RESPONSABILITÉ

Studium ne peut pas contrôler la nature de tout le contenu disponible sur la plateforme. En exploitant la plateforme, Studium ne représente ni n'implique que Studium endosse aucun blog, contributions ou autres contenus disponibles sur ou liés par la plateforme, y compris sans limitation le contenu hébergé sur des sites web tiers ou fourni par des applications tierces, ni que Studium croit que de telles contributions ou contenus soient précis, utiles ou non nuisibles. Nous ne contrôlons pas et ne sommes pas responsables des contenus illégaux ou autrement répréhensibles que vous pouvez rencontrer sur la plateforme ou en connexion avec toute contribution. Studium n'est également pas responsable du comportement, que ce soit en ligne ou hors ligne, de tout utilisateur de la plateforme.

VOUS ACCEPTEZ QUE L'UTILISATION DE LA PLATEFORME ET DES SERVICES DE STUDIUM SERA À VOTRE SEUL RISQUE. DANS LA MESURE MAXIMALE PERMISE PAR LA LOI, STUDIUM, SES OFFICIERS, DIRECTEURS, EMPLOYÉS ET AGENTS EXCLUENT TOUTES GARANTIES, EXPRIMÉES OU IMPLICITES, EN CONNEXION AVEC LA PLATEFORME ET VOTRE UTILISATION DE CELLE-CI, Y COMPRIS, SANS LIMITATION, LES GARANTIES IMPLICITES DE MARCHANDABILITÉ, D'ADAPTATION À UN USAGE PARTICULIER ET DE NON-VIOLATION. STUDIUM NE FAIT AUCUNE GARANTIE OU REPRÉSENTATION CONCERNANT LA PRÉCISION OU L'EXHAUSTIVITÉ DU CONTENU DE LA PLATEFORME OU DU CONTENU DE TOUS SITES LIÉS À NOTRE PLATEFORME ET N'ASSUME AUCUNE RESPONSABILITÉ POUR (A) LES ERREURS, LES ERREURS DE FAIT OU LES IMPRÉCISIONS DE CONTENU ET MATÉRIAUX, (B) LES DOMMAGES PERSONNELS OU MATÉRIELS, DE QUELQUE NATURE QUE CE SOIT, RÉSULTANT DE VOTRE ACCÈS ET UTILISATION DE NOTRE PLATEFORME, (C) TOUT ACCÈS NON AUTORISÉ OU UTILISATION DE NOS SERVEURS SÉCURISÉS ET/OU DE TOUTE INFORMATION PERSONNELLE ET/OU INFORMATION FINANCIÈRE STOCKÉE SUR CEUX-C

I, (D) TOUTE INTERRUPTION OU CESSATION DE TRANSMISSION VERS OU DEPUIS LA PLATEFORME, (E) TOUS LES BUGS, VIRUS, CHEVAUX DE TROIE, OU SIMILAIRES QUI PEUVENT ÊTRE TRANSMIS À OU À TRAVERS NOTRE PLATEFORME PAR TOUT TIERS, ET/OU (F) TOUTES LES ERREURS OU OMISSIONS DANS TOUT CONTENU ET MATÉRIAUX OU POUR TOUTE PERTE OU DOMMAGE DE TOUT TYPE INCURRÉS EN RÉSULTAT DE L'UTILISATION DE TOUT CONTENU PUBLIÉ, TRANSMIS OU AUTREMENT MIS À DISPOSITION VIA LA PLATEFORME.

### LIMITATIONS DE RESPONSABILITÉ

EN AUCUN CAS STUDIUM OU SES DIRECTEURS, EMPLOYÉS OU AGENTS NE SERONT RESPONSABLES ENVERS VOUS OU UN TIERS POUR TOUS DOMMAGES DIRECTS, INDIRECTS, CONSÉQUENTS, EXEMPLAIRES, INCIDENTS, SPÉCIAUX OU PUNITIFS, Y COMPRIS LA PERTE DE PROFITS, PERTE DE REVENU, PERTE DE DONNÉES OU D'AUTRES DOMMAGES DÉCOULANT DE VOTRE UTILISATION DE LA PLATEFORME OU DES SERVICES DE STUDIUM, MÊME SI STUDIUM A ÉTÉ AVISÉE DE LA POSSIBILITÉ DE TELS DOMMAGES. NONOBSTANT TOUTE DISPOSITION CONTRAIRE CONTENUE DANS CET ACCORD, LA RESPONSABILITÉ DE STUDIUM ENVERS VOUS POUR TOUTE CAUSE QUELLE QU'ELLE SOIT ET QUELLE QUE SOIT LA FORME DE L'ACTION, SERA À TOUT MOMENT LIMITÉE AU MONTANT PAYÉ, LE CAS ÉCHÉANT, PAR VOUS À STUDIUM POUR LES SERVICES DE STUDIUM AU COURS DES TROIS (3) MOIS PRÉCÉDANT TOUTE CAUSE D'ACTION SURGIE.

LES LOIS DE CERTAINS ÉTATS NE PERMETTENT PAS LES LIMITATIONS SUR LES GARANTIES IMPLICITES OU L'EXCLUSION OU LA LIMITATION DE CERTAINS DOMMAGES. SI CES LOIS S'APPLIQUENT À VOUS, CERTAINES OU TOUTES LES EXCLUSIONS OU LIMITATIONS CI-DESSUS PEUVENT NE PAS S'APPLIQUER À VOUS, ET VOUS POURRIEZ AVOIR DES DROITS SUPPLÉMENTAIRES.

SI VOUS ÊTES RÉSIDENT DE CALIFORNIE, VOUS RENONCEZ À LA SECTION 1542 DU CODE CIVIL DE CALIFORNIE, QUI DIT : « UNE LIBÉRATION GÉNÉRALE N'ÉTEND PAS AUX RÉCLAMATIONS QUE LE CRÉANCIER NE CONNAÎT PAS OU NE SOUPÇONNE PAS EXISTER EN SA FAVEUR AU MOMENT DE L'EXÉCUTION DE LA LIBÉRATION, QUI, SI CONNUES PAR LUI, DOIVENT AVOIR AFFECTÉ MATÉRIELLEMENT SON ACCORD AVEC LE DÉBITEUR. »

### INDEMNITÉ

Vous acceptez de défendre, d'indemniser et de tenir indemne Studium, ses filiales et affiliés, et leurs officiers respectifs, agents, partenaires et employés, de et contre toute perte, dommage, responsabilité, réclamation ou demande, y compris des honoraires raisonnables d'avocat et des dépenses, faite par tout tiers en raison ou découlant de votre contenu contribué, de l'utilisation des Services de Studium, et/ou découlant d'une violation de cet Accord et/ou de toute violation de vos représentations et garanties énoncées ci-dessus. Nonobstant ce qui précède, Studium se réserve le droit, à vos frais, d'assumer la défense et le contrôle exclusifs de toute affaire pour laquelle vous êtes tenu d'indemniser Studium, et vous acceptez de coopérer, à vos frais, avec la défense de Studium de ces réclamations. Studium fera des efforts raisonnables pour vous notifier de toute réclamation, action ou procédure qui fait l'objet de cette indemnisation dès qu'elle en prendra connaissance.

### NOTIFICATIONS

Sauf indication contraire explicite, toutes les notifications envoyées à

 Studium doivent être envoyées par courriel à l'adresse indiquée dans les informations de contact ci-dessous. Toutes les notifications envoyées à vous seront données à l'adresse courriel que vous avez fournie lors du processus d'inscription, ou à toute autre adresse que chaque partie peut spécifier. La notification sera considérée comme donnée vingt-quatre (24) heures après l'envoi de l'e-mail, à moins que la partie expéditrice ne soit notifiée que l'adresse e-mail est invalide. Nous pouvons également choisir d'envoyer des notifications par courrier régulier.

### DONNÉES DE L'UTILISATEUR

Notre plateforme maintiendra certaines données que vous transférez à la plateforme aux fins de la performance des Services de Studium, ainsi que des données relatives à votre utilisation des Services de Studium. Bien que nous réalisions des sauvegardes régulières de données, vous êtes principalement responsable de toutes les données que vous avez transférées ou qui se rapportent à toute activité que vous avez entreprise en utilisant les Services de Studium. Vous convenez que Studium n'aura aucune responsabilité envers vous pour toute perte ou corruption de telles données, et vous renoncez ici à tout droit d'action contre Studium découlant de toute telle perte ou corruption de ces données.

### CONTRACTUALISATION ÉLECTRONIQUE

Votre utilisation des Services de Studium comprend la capacité d'entrer dans des accords et/ou de réaliser des transactions électroniquement. VOUS RECONNAISSEZ QUE VOS SOUMISSIONS ÉLECTRONIQUES CONSTITUENT VOTRE ACCORD ET INTENTION D'ÊTRE LIÉ PAR ET DE PAYER POUR CES ACCORDS ET TRANSACTIONS. VOTRE ACCORD ET INTENTION D'ÊTRE LIÉ PAR DES SOUMISSIONS ÉLECTRONIQUES S'APPLIQUENT À TOUS LES ENREGISTREMENTS RELATIFS À TOUTES LES TRANSACTIONS QUE VOUS ENTREPRENEZ CONCERNANT LES SERVICES DE STUDIUM, Y COMPRIS LES NOTIFICATIONS D'ANNULATION, LES POLITIQUES, LES CONTRATS ET LES APPLICATIONS. Pour accéder et conserver vos enregistrements électroniques, vous pouvez être tenu d'avoir certains matériels et logiciels, qui sont de votre seule responsabilité.

### DIVERS

Cet Accord constitue l'accord intégral entre vous et Studium concernant l'utilisation des Services de Studium. Le fait que Studium n'exerce ou n'applique aucun droit ou disposition de cet Accord ne fonctionnera pas comme une renonciation à un tel droit ou disposition. Les titres de section dans cet Accord sont pour la commodité seulement et n'ont aucun effet légal ou contractuel. Cet Accord fonctionne dans toute la mesure permise par la loi. Cet Accord et votre compte ne peuvent pas être cédés par vous sans notre consentement écrit exprès. Studium peut céder tous ou une partie de ses droits et obligations à d'autres à tout moment. Studium ne sera pas responsable de toute perte, dommage, retard ou manquement à agir causé par toute cause au-delà du contrôle raisonnable de Studium. Si une disposition ou une partie d'une disposition de cet Accord est jugée illégale, nulle ou inapplicable, cette disposition ou partie de la disposition est réputée séparable de cet Accord et n'affecte pas la validité et l'applicabilité des autres dispositions. Aucune joint venture, partenariat, relation d'emploi ou relation d'agence n'est créée entre vous et Studium en raison de cet Accord ou de l'utilisation des Sites et Services de Studium. À la demande de Studium, vous fournirez à Studium toute documentation, justification ou libérations nécessaires pour vérifier votre conformité avec cet Accord. Vous convenez que cet Accord ne sera pas interprété contre Studium en raison d'avoir rédigé celui-ci. Vous renoncez ici à toutes et à toutes les défenses que vous pourriez avoir basées sur la forme électronique de cet Accord et le manque de signature des parties pour exécuter cet Accord.

### CONTACT

Pour résoudre une plainte concernant les Services de Studium ou pour recevoir davantage d'informations concernant l'utilisation des Services de Studium, veuillez contacter Studium comme défini ci-dessous, ou, si une plainte avec nous n'est pas résolue de manière satisfaisante et que vous êtes rés

ident en Californie, vous pouvez contacter l'Unité d'Assistance aux Plaintes de la Division des Services aux Consommateurs du Département des Affaires Consommateurs par écrit à l'adresse 400 "R" Street, Sacramento, Californie 95814 ou par téléphone au 1-916-445-1254.

Studium

Email : [team@studium.academy](mailto:team@studium.academy?subject=Terms%20of%20Service)
`,
    },
    it: {
        termsOfServiceTitle: "Termini di servizio",
        termsOfService: `
`,
    },
    de: {
        termsOfServiceTitle: "Nutzungsbedingungen",
        termsOfService: `
`,
    },
};
