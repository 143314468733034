import { ItnLanguage, ItnMessagesType, ItnType } from "../utils/itn";

type Type = {
    hero: {
        supTitle: string;
        title: [string, string, string];
        subtitle?: string;
        callToAction?: string;
    };
    comparativeSession: {
        title: string;
        comparison1: {
            title: string;
            description: string;
            callToAction: string;
        };
        comparison2: {
            title: string;
            description: string;
            callToAction?: string;
        };
        comparison3: {
            title: string;
            description: string;
            callToAction?: string;
        };
        comparison4: {
            title: string;
            description: string;
            callToAction?: string;
        };
    };
};

export const landingItns: ItnType<Type> = {
    ptBr: {
        hero: {
            supTitle: "Maximize Resultados Educacionais",
            title: ["A solução moderna", "para instituições de", "ensino"],
            subtitle:
                "Aproveite ao máximo seu tempo e recursos com a nossa solução para instituições de ensino",
            callToAction: "Quero conhecer",
        },
        comparativeSession: {
            title: "Descubra como nossa solução\npode ajudar a sua instituição a crescer.",
            comparison1: {
                title: "Solução interativa para gestão e comunicação",
                description:
                    "Simplifique a gestão escolar e melhore a comunicação com nosso sistema interativo para alunos, pais e professores. Integração com plataformas de ensino online, recursos avançados de segurança e comunicação em tempo real. Descubra como economizar tempo e aumentar a produtividade com nossa solução.",
                callToAction: "Ler mais sobre",
            },
            comparison2: {
                title: "Potencialize o aprendizado com nossa solução",
                description:
                    "Melhore o aprendizado dos seus alunos com nossa solução de ensino online. Professores podem criar aulas interativas, acompanhar o progresso dos alunos e oferecer feedback em tempo real. Personalize a plataforma para atender às necessidades da sua escola e alunos. Economize tempo e aumente a eficiência do ensino com nossa solução.",
            },
            comparison3: {
                title: "Melhore a comunicação escolar com nossa solução integrada",
                description:
                    "Mantenha pais, alunos e professores conectados com nossa solução integrada para comunicação escolar. Envie e receba mensagens em tempo real, compartilhe informações e documentos importantes e ofereça suporte aos pais em questões acadêmicas. Nossa plataforma é fácil de usar e personalizável para atender às necessidades específicas da sua escola. Simplifique a comunicação escolar e aprimore a experiência de ensino com nossa solução integrada.",
            },
            comparison4: {
                title: "Maximize a eficiência administrativa com nossa solução de gestão escolar",
                description:
                    "Otimize a gestão da sua escola com nossa solução de gestão escolar. Acompanhe informações importantes, como matrículas, boletins e relatórios financeiros em um só lugar. Personalize a plataforma para atender às necessidades da sua escola e automatize tarefas repetitivas para economizar tempo. Além disso, nossa solução é fácil de usar e oferece recursos avançados de segurança para garantir a proteção das informações da sua escola. Maximize a eficiência administrativa da sua escola com nossa solução de gestão escolar.",
            },
        },
    },
    ptEu: {
        hero: {
            supTitle: "Maximize Resultados Educativos",
            title: ["A solução moderna", "para instituições de", "ensino"],
            subtitle:
                "Optimize o seu tempo e recursos com a nossa solução para instituições de ensino",
            callToAction: "Quero conhecer",
        },
        comparativeSession: {
            title: "Descubra como nossa solução\npode ajudar a sua instituição a crescer.",
            comparison1: {
                title: "Solução interativa para gestão e comunicação",
                description:
                    "Simplifique a gestão escolar e melhore a comunicação com o nosso sistema interativo para alunos, pais e professores. Integração com plataformas de ensino online, recursos de segurança avançados e comunicação em tempo real. Descubra como poupar tempo e elevar a produtividade com a nossa solução.",
                callToAction: "Ler mais sobre",
            },
            comparison2: {
                title: "Potencialize o aprendizado com nossa solução",
                description:
                    "Aprimore a aprendizagem dos seus alunos com a nossa solução de ensino online. Professores podem desenvolver aulas interativas, monitorizar o progresso dos alunos e oferecer feedback imediato. Adapte a plataforma às necessidades da sua escola e alunos. Poupe tempo e aumente a eficiência do ensino com a nossa solução.",
            },
            comparison3: {
                title: "Melhore a comunicação escolar com nossa solução integrada",
                description:
                    "Mantenha pais, alunos e professores ligados com a nossa solução integrada de comunicação escolar. Envie e receba mensagens instantaneamente, partilhe informações e documentos relevantes, e dê apoio aos pais em questões académicas. A nossa plataforma é fácil de utilizar e configurável para responder às necessidades específicas da sua escola. Simplifique a comunicação escolar e melhore a experiência educativa com a nossa solução.",
            },
            comparison4: {
                title: "Maximize a eficiência administrativa com nossa solução de gestão escolar",
                description:
                    "Optimize a gestão da sua escola com a nossa solução de gestão escolar. Monitorize informações cruciais, como inscrições, avaliações e relatórios financeiros, tudo num único local. Personalize a plataforma para se adequar às necessidades da sua escola e automatize tarefas rotineiras para poupar tempo. A nossa solução é fácil de operar e inclui funcionalidades de segurança avançadas para assegurar a proteção dos dados da sua escola. Eleve a eficiência administrativa da sua escola com a nossa solução.",
            },
        },
    },
    en: {
        hero: {
            supTitle: "Maximize Educational Outcomes",
            title: ["The modern solution", "for educational", "institutions"],
            subtitle:
                "Make the most of your time and resources with our solution for educational institutions",
            callToAction: "I want to know",
        },
        comparativeSession: {
            title: "Discover how our solution\ncan help your institution grow.",
            comparison1: {
                title: "Interactive solution for management and communication",
                description:
                    "Simplify school management and improve communication with our interactive system for students, parents, and teachers. Integration with online teaching platforms, advanced security features, and real-time communication. Find out how to save time and increase productivity with our solution.",
                callToAction: "Read more about",
            },
            comparison2: {
                title: "Empower learning with our solution",
                description:
                    "Enhance your students' learning with our online teaching solution. Teachers can create interactive lessons, track students' progress, and provide real-time feedback. Customize the platform to meet your school's and students' needs. Save time and increase teaching efficiency with our solution.",
            },
            comparison3: {
                title: "Improve school communication with our integrated solution",
                description:
                    "Keep parents, students, and teachers connected with our integrated school communication solution. Send and receive messages in real-time, share important information and documents, and support parents with academic issues. Our platform is easy to use and customizable to meet your school's specific needs. Simplify school communication and enhance the teaching experience with our integrated solution.",
            },
            comparison4: {
                title: "Maximize administrative efficiency with our school management solution",
                description:
                    "Optimize your school's management with our school management solution. Track important information such as enrollments, report cards, and financial reports in one place. Customize the platform to meet your school's needs and automate repetitive tasks to save time. Additionally, our solution is easy to use and offers advanced security features to ensure the protection of your school's information. Maximize your school's administrative efficiency with our school management solution.",
            },
        },
    },
    es: {
        hero: {
            supTitle: "Maximice los Resultados Educativos",
            title: ["La solución moderna", "para instituciones", "educativas"],
            subtitle:
                "Aproveche al máximo su tiempo y recursos con nuestra solución para instituciones educativas",
            callToAction: "Quiero saber",
        },
        comparativeSession: {
            title: "Descubra cómo nuestra solución\npuede ayudar a su institución a crecer.",
            comparison1: {
                title: "Solución interactiva para gestión y comunicación",
                description:
                    "Simplifique la gestión escolar y mejore la comunicación con nuestro sistema interactivo para estudiantes, padres y profesores. Integración con plataformas de enseñanza en línea, funciones avanzadas de seguridad y comunicación en tiempo real. Descubra cómo ahorrar tiempo y aumentar la productividad con nuestra solución.",
                callToAction: "Leer más sobre",
            },
            comparison2: {
                title: "Potencie el aprendizaje con nuestra solución",
                description:
                    "Mejore el aprendizaje de sus alumnos con nuestra solución de enseñanza en línea. Los profesores pueden crear lecciones interactivas, hacer un seguimiento del progreso de los alumnos y ofrecer retroalimentación en tiempo real. Personalice la plataforma para satisfacer las necesidades de su escuela y alumnos. Ahorre tiempo y aumente la eficiencia de la enseñanza con nuestra solución.",
            },
            comparison3: {
                title: "Mejore la comunicación escolar con nuestra solución integrada",
                description:
                    "Mantenga conectados a padres, alumnos y profesores con nuestra solución integrada de comunicación escolar. Envíe y reciba mensajes en tiempo real, comparta información y documentos importantes y brinde apoyo a los padres en cuestiones académicas. Nuestra plataforma es fácil de usar y personalizable para satisfacer las necesidades específicas de su escuela. Simplifique la comunicación escolar y mejore la experiencia de enseñanza con nuestra solución integrada.",
            },
            comparison4: {
                title: "Maximice la eficiencia administrativa con nuestra solución de gestión escolar",
                description:
                    "Optimice la gestión de su escuela con nuestra solución de gestión escolar. Haga un seguimiento de información importante como inscripciones, boletines y reportes financieros en un solo lugar. Personalice la plataforma para satisfacer las necesidades de su escuela y automatice tareas repetitivas para ahorrar tiempo. Además, nuestra solución es fácil de usar y ofrece funciones avanzadas de seguridad para garantizar la protección de la información de su escuela. Maximice la eficiencia administrativa de su escuela con nuestra solución de gestión escolar.",
            },
        },
    },
    fr: {
        hero: {
            supTitle: "Maximisez les Résultats Éducatifs",
            title: [
                "La solution moderne",
                "pour les institutions",
                "éducatives",
            ],
            subtitle:
                "Tirez le meilleur parti de votre temps et de vos ressources avec notre solution pour les institutions éducatives",
            callToAction: "Je veux savoir",
        },
        comparativeSession: {
            title: "Découvrez comment notre solution\npeut aider votre institution à croître",
            comparison1: {
                title: "Solution interactive pour la gestion et la communication",
                description:
                    "Simplifiez la gestion scolaire et améliorez la communication avec notre système interactif pour les étudiants, les parents et les enseignants. Intégration avec des plateformes d'enseignement en ligne, des fonctionnalités de sécurité avancées et une communication en temps réel. Découvrez comment gagner du temps et augmenter la productivité avec notre solution.",
                callToAction: "En savoir plus sur",
            },
            comparison2: {
                title: "Donnez du pouvoir à l'apprentissage avec notre solution",
                description:
                    "Améliorez l'apprentissage de vos élèves avec notre solution d'enseignement en ligne. Les enseignants peuvent créer des leçons interactives, suivre les progrès des élèves et fournir des commentaires en temps réel. Personnalisez la plateforme pour répondre aux besoins de votre école et de vos élèves. Gagnez du temps et augmentez l'efficacité de l'enseignement avec notre solution.",
            },
            comparison3: {
                title: "Améliorez la communication scolaire avec notre solution intégrée",
                description:
                    "Gardez les parents, les élèves et les enseignants connectés avec notre solution de communication scolaire intégrée. Envoyez et recevez des messages en temps réel, partagez des informations et des documents importants et soutenez les parents dans les problèmes académiques. Notre plateforme est facile à utiliser et personnalisable pour répondre aux besoins spécifiques de votre école. Simplifiez la communication scolaire et améliorez l'expérience d'enseignement avec notre solution intégrée.",
            },
            comparison4: {
                title: "Maximisez l'efficacité administrative avec notre solution de gestion scolaire",
                description:
                    "Optimisez la gestion de votre école avec notre solution de gestion scolaire. Suivez des informations importantes telles que les inscriptions, les bulletins et les rapports financiers en un seul endroit. Personnalisez la plateforme pour répondre aux besoins de votre école et automatisez les tâches répétitives pour gagner du temps. De plus, notre solution est facile à utiliser et offre des fonctionnalités de sécurité avancées pour garantir la protection des informations de votre école. Maximisez l'efficacité administrative de votre école avec notre solution de gestion scolaire.",
            },
        },
    },
    it: {
        hero: {
            supTitle: "Massimizza i Risultati Educativi",
            title: ["La soluzione", "moderna per le", "istituzioni educative"],
            subtitle:
                "Sfrutta al massimo il tuo tempo e le tue risorse con la nostra soluzione per le istituzioni educative",
            callToAction: "Voglio sapere",
        },
        comparativeSession: {
            title: "Scopri come la nostra soluzione\npuò aiutare la tua istituzione a crescere.",
            comparison1: {
                title: "Soluzione interattiva per la gestione e la comunicazione",
                description:
                    "Semplifica la gestione scolastica e migliora la comunicazione con il nostro sistema interattivo per studenti, genitori e insegnanti. Integrazione con piattaforme di insegnamento online, funzionalità di sicurezza avanzate e comunicazione in tempo reale. Scopri come risparmiare tempo e aumentare la produttività con la nostra soluzione.",
                callToAction: "Leggi di più su",
            },
            comparison2: {
                title: "Potenzia l'apprendimento con la nostra soluzione",
                description:
                    "Migliora l'apprendimento dei tuoi studenti con la nostra soluzione di insegnamento online. Gli insegnanti possono creare lezioni interattive, monitorare i progressi degli studenti e fornire feedback in tempo reale. Personalizza la piattaforma per soddisfare le esigenze della tua scuola e degli studenti. Risparmia tempo e aumenta l'efficienza dell'insegnamento con la nostra soluzione.",
            },
            comparison3: {
                title: "Migliora la comunicazione scolastica con la nostra soluzione integrata",
                description:
                    "Mantieni connessi genitori, studenti e insegnanti con la nostra soluzione integrata di comunicazione scolastica. Invia e ricevi messaggi in tempo reale, condividi informazioni e documenti importanti e supporta i genitori nelle questioni accademiche. La nostra piattaforma è facile da usare e personalizzabile per soddisfare le esigenze specifiche della tua scuola. Semplifica la comunicazione scolastica e migliora l'esperienza di insegnamento con la nostra soluzione integrata.",
            },
            comparison4: {
                title: "Massimizza l'efficienza amministrativa con la nostra soluzione di gestione scolastica",
                description:
                    "Ottimizza la gestione della tua scuola con la nostra soluzione di gestione scolastica. Tieni traccia di informazioni importanti come iscrizioni, pagelle e report finanziari in un unico luogo. Personalizza la piattaforma per soddisfare le esigenze della tua scuola e automatizza i compiti ripetitivi per risparmiare tempo. Inoltre, la nostra soluzione è facile da usare e offre funzionalità di sicurezza avanzate per garantire la protezione delle informazioni della tua scuola. Massimizza l'efficienza amministrativa della tua scuola con la nostra soluzione di gestione scolastica.",
            },
        },
    },
    de: {
        hero: {
            supTitle: "Maximieren Sie die Bildungsergebnisse",
            title: ["Die moderne und", "innovative", "Bildungslösung"],
            subtitle:
                "Nutzen Sie Ihre Zeit und Ressourcen optimal mit unserer Lösung für Bildungseinrichtungen",
            callToAction: "Ich möchte wissen",
        },
        comparativeSession: {
            title: "Entdecken Sie, wie unsere Lösung\nIhre Einrichtung wachsen lassen kann.",
            comparison1: {
                title: "Interaktive Lösung für Management und Kommunikation",
                description:
                    "Vereinfachen Sie das Schulmanagement und verbessern Sie die Kommunikation mit unserem interaktiven System für Schüler, Eltern und Lehrer. Integration mit Online-Lehrplattformen, erweiterte Sicherheitsfunktionen und Echtzeitkommunikation. Erfahren Sie, wie Sie Zeit sparen und die Produktivität mit unserer Lösung steigern können.",
                callToAction: "Mehr erfahren über",
            },
            comparison2: {
                title: "Fördern Sie das Lernen mit unserer Lösung",
                description:
                    "Verbessern Sie das Lernen Ihrer Schüler mit unserer Online-Unterrichtslösung. Lehrer können interaktive Lektionen erstellen, den Fortschritt der Schüler verfolgen und Echtzeit-Feedback geben. Passen Sie die Plattform an die Bedürfnisse Ihrer Schule und Schüler an. Sparen Sie Zeit und steigern Sie die Effizienz des Unterrichts mit unserer Lösung.",
            },
            comparison3: {
                title: "Verbessern Sie die schulische Kommunikation mit unserer integrierten Lösung",
                description:
                    "Halten Sie Eltern, Schüler und Lehrer mit unserer integrierten schulischen Kommunikationslösung verbunden. Senden und empfangen Sie Nachrichten in Echtzeit, teilen Sie wichtige Informationen und Dokumente und unterstützen Sie Eltern bei akademischen Fragen. Unsere Plattform ist benutzerfreundlich und anpassbar, um den spezifischen Anforderungen Ihrer Schule gerecht zu werden. Vereinfachen Sie die schulische Kommunikation und verbessern Sie die Unterrichtserfahrung mit unserer integrierten Lösung.",
            },
            comparison4: {
                title: "Maximieren Sie die Verwaltungseffizienz mit unserer Schulverwaltungslösung",
                description:
                    "Optimieren Sie das Management Ihrer Schule mit unserer Schulverwaltungslösung. Verfolgen Sie wichtige Informationen wie Anmeldungen, Noten und Finanzberichte an einem Ort. Passen Sie die Plattform an die Bedürfnisse Ihrer Schule an und automatisieren Sie wiederkehrende Aufgaben, um Zeit zu sparen. Darüber hinaus ist unsere Lösung benutzerfreundlich und bietet erweiterte Sicherheitsfunktionen zum Schutz der Informationen Ihrer Schule. Maximieren Sie die Verwaltungseffizienz Ihrer Schule mit unserer Schulverwaltungslösung.",
            },
        },
    },
};
