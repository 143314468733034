export type StudiumTheme = {
    background: string;
    backgroundAlt: string;
    foreground: string;
    foregroundSecondary: string;
    elementBackgroundSecondary: string;
    color: string;
    border: string;
    elementBlue: string;
};

export type ST = { theme: StudiumTheme };

export const lightTheme: StudiumTheme = {
    background: "#FAFBFC",
    backgroundAlt: "#FFFFFF",
    foreground: "#000000",
    foregroundSecondary: "#686869",
    elementBackgroundSecondary: "#F6F7F8",
    color: "#24292e",
    border: "#E7E7E7",
    elementBlue: "#367AF7",
};

export const darkTheme: StudiumTheme = {
    background: "#232B33",
    backgroundAlt: "#333A42",
    foreground: "#FFFFFF",
    foregroundSecondary: "#C2C9CF",
    elementBackgroundSecondary: "#232B33",
    color: "#24292e",
    border: "#E7E7E755",
    elementBlue: "#4781C1",
};
