import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import ComparativeSession from "../components/comparative_session";
import Footer from "../components/footer";
import Header from "../components/header";
import Hero from "../components/hero";
import { landingItns } from "../components/landing.itn";
import LanguagesSwitch from "../components/languages_switch";
import PlatformFeatures from "../components/platform_features";
import { Container } from "../components/studium_ui";
import { useItn } from "../utils/itn";
import * as styles from "../utils/styles";
import { ST } from "../utils/themes";

export default function App() {
    const theme = useTheme();
    const { itn } = useItn(landingItns);

    // @ts-ignore
    const classes = useStyles({ theme });
    return (
        <main className={classes.container}>
            <Header />
            <LanguagesSwitch />
            <Hero />
            <Container
                style={{ marginBlock: styles.size(7), textAlign: "center" }}
            >
                <h2
                    style={{
                        margin: 0,
                        padding: 0,
                        fontSize: styles.size(3.5),
                        lineHeight: 1.2,
                    }}
                >
                    {itn.comparativeSession.title}
                    {/* Descubra como nossa solução
                    <br />
                    pode ajudar a sua instituição a crescer. */}
                </h2>
            </Container>
            <ComparativeSession />
            <PlatformFeatures />
            <Footer />
        </main>
    );
}

// region styles
const useStyles = createUseStyles({
    container: {
        backgroundColor: ({ theme }: ST) => theme.background,
        color: ({ theme }: ST) => theme.foreground,
        minHeight: "100vh",
    },
    headerContainer: {
        display: "flex",
        height: styles.size(5),
        padding: styles.size(1),
    },
    image: {
        cursor: "pointer",
        transition: "all .2s ease-in-out",
        width: styles.size(16),
        // color: ({theme}: any) => theme.elementBlue,
    },
});
// endregion
